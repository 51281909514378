import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ArrowRight, CheckboxInput } from '@nowsta/ux-common-components';

import Drawer from 'features/common/components/Drawer';
import { ControlledTextInput } from 'features/common/components/ControlledTextInput';
import { AutoCompleteInput } from 'features/common/components/AutoCompleteInput';
import { DrawerTitle } from 'features/common/components/Drawer/styled';
import SelectPlaceholder from 'features/common/components/SelectPlaceholder';
import { useCreateInvoice } from 'features/invoices-history/hooks/queries';
import { useFetchCompanies } from 'features/companies/hooks/queries';
import { useDebounceState } from 'features/common/hooks/useDebounceState';
import { InvoicingTerm } from 'features/common/constants';

import {
  IconContainer,
  Form,
  Row,
  SelectInput,
  GroupingOrder,
  MultiSelectHolder,
} from './styled';

interface CreateInvoiceReportDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateInvoiceReportDrawer = ({
  isOpen,
  onClose,
}: CreateInvoiceReportDrawerProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedGroupBy, setSelectedGroupBy] = useState<string[]>([]);
  const debouncedSearch: string = useDebounceState(searchValue, 300);

  const { control, handleSubmit, reset, register } = useForm();

  const handleInputValueChange = (value: string) => setSearchValue(value);

  const { data: companies, isLoading } = useFetchCompanies(
    1,
    10,
    debouncedSearch,
  );
  const handleClose = () => {
    onClose();
    reset();
    setSearchValue('');
  };

  const { mutate } = useCreateInvoice({
    onSuccess: () => {
      handleClose();
    },
  });

  const onSubmit: SubmitHandler<any> = data => {
    // TODO: stop hardcoding data
    mutate({
      ...data,
      startDate: new Date(data.startDate).toISOString().split('T')[0],
      endDate: new Date(data.endDate).toISOString().split('T')[0],
      groupBy: selectedGroupBy,
    });
  };

  const handleChangeGroupBy = (event: any) => {
    const { value } = event.target;
    setSelectedGroupBy(prevState => {
      if (prevState.find(item => value === item)) {
        return prevState.filter(item => value !== item);
      }
      return [...prevState, value];
    });
  };

  return (
    <Drawer
      title="Create a New .CSV"
      onClose={handleClose}
      onBack={handleClose}
      onContinue={handleSubmit(onSubmit)}
      continueText="Generate .CSV"
      isOpen={isOpen}
    >
      <DrawerTitle>New .CSV Details</DrawerTitle>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <AutoCompleteInput
          name="companyId"
          control={control}
          label="Company name"
          inputValue={searchValue}
          onInputValueChange={handleInputValueChange}
          // TODO: create mapperFn utility
          items={
            companies?.items?.map((company: any) => ({
              id: company.id,
              value: company.name,
            })) ?? []
          }
          loading={isLoading}
        />
        <MultiSelectHolder>
          <GroupingOrder>
            <li>
              <CheckboxInput
                uiPresets={{ uiSize: 'default' }}
                type="checkbox"
                checked={selectedGroupBy.includes('department')}
                value="department"
                onChange={handleChangeGroupBy}
              >
                Group By Department(associated with Position, not Event)
              </CheckboxInput>
            </li>
            <li>
              <CheckboxInput
                uiPresets={{ uiSize: 'default' }}
                type="checkbox"
                checked={selectedGroupBy.includes('event')}
                value="event"
                onChange={handleChangeGroupBy}
              >
                Group By Event
              </CheckboxInput>
            </li>
            <li>
              <CheckboxInput
                uiPresets={{ uiSize: 'default' }}
                type="checkbox"
                checked={selectedGroupBy.includes('position')}
                value="position"
                onChange={handleChangeGroupBy}
              >
                Group By Position
              </CheckboxInput>
            </li>
          </GroupingOrder>
        </MultiSelectHolder>
        <Row>
          <Row dateRangeRow>
            <input
              // name="startDate"
              type="date"
              {...register('startDate')}
            />
            {/* <ControlledDatePicker
              label="Start Date"
              placeholder="Start Date"
              includeIcon
              name="startDate"
              rules={{
                validate: {
                  IsEarlerThanEndDate: (value: any) =>
                    new Date(value) <
                      (getValues().endDate && new Date(getValues().endDate)) ||
                    'start date is greater than end date ',
                },
              }}
              control={control}
            /> */}
            <IconContainer>
              <ArrowRight />
            </IconContainer>
            <input
              // name="startDate"
              type="date"
              {...register('endDate')}
            />
            {/* <ControlledDatePicker
              label="End Date"
              placeholder="End Date"
              includeIcon
              name="endDate"
              rules={{
                validate: {
                  IsEarlerThanEndDate: (value: any) =>
                    new Date(value) >
                    (getValues().startDate &&
                      new Date(getValues().startDate)) ||
                    'end date is lower than start date ',
                },
              }}
              control={control}
            /> */}
          </Row>
          <ControlledTextInput
            control={control}
            name="invoiceNumber"
            label="Invoice Number"
            placeholder="e.g 12345"
          />
        </Row>
        <SelectInput
          defaultValue=""
          control={control}
          name="term"
          label="Contract Terms"
        >
          <SelectPlaceholder>e.g Net 30</SelectPlaceholder>
          {Object.values(InvoicingTerm).map(term => (
            <option key={term} value={term}>
              {term}
            </option>
          ))}
        </SelectInput>
      </Form>
    </Drawer>
  );
};

export default CreateInvoiceReportDrawer;
