import { rem } from '@nowsta/ux-common-components';
import styled from 'styled-components/macro';

export const YearMonthPickerWrapper = styled.div`
  position: relative;
`;

export const ClearInput = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: ${rem(14)};

  > span {
    min-height: ${rem(4)};
  }

  svg.icon {
    height: inherit;
  }
`;
