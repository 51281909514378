import { InvoicingTerm } from 'features/common/constants';

export enum InvoiceReportStatus {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
}

export interface InvoiceListItem {
  id: number;
  startDate: string;
  endDate: string;
  term: InvoicingTerm;
  status: InvoiceReportStatus;
  createdByName: string;
  invoiceNumber: string;
  deparments: string;
  companyName: string;
}

export interface CreateInvoiceDTO {
  startDate: string;
  endDate: string;
  companyId: number;
  term: string;
  createdByName: string;
  invoiceNumber: string;
}

export interface WFMDeparmentByCompanyResponse {
  objects: Department[];
}

export interface Department {
  id: number;
  name: string;
}
