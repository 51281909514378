import {
  rem,
  textSize,
  textWeight,
  Button as TempoButton,
  ButtonProps,
} from '@nowsta/ux-common-components';
import styled from 'styled-components/macro';
import { color } from 'features/common/constants';
import { fade } from 'features/common/utils';

interface CustomButtonProps extends ButtonProps {
  isActive?: boolean;
}

export const DatePickerPopupWrapper = styled.div`
  position: absolute;
  background-color: #fff;
  border: 2px solid ${fade(color.black, 20)};
  border-radius: 8px;

  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.03),
    0px 4px 8px -2px rgba(0, 0, 0, 0.04), 0px 16px 32px -8px rgba(0, 0, 0, 0.06),
    0px 32px 64px -8px rgba(0, 0, 0, 0.03);
  display: flex;
  flex-direction: column;
  gap: ${rem(8)};
  margin-top: ${rem(8)};
  padding: ${rem(16)};
  width: 290px;
  z-index: 1;
`;

export const PickerHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  width: 100%;

  > button {
    display: block;
    width: 32px;
    height: 32px;

    svg {
      path {
        fill: ${color.slate} !important;
      }
    }
  }
`;

export const PickerBody = styled.div`
  display: grid;
  gap: ${rem(8)};
  grid-template-columns: repeat(3, 1fr);
`;

export const Year = styled.span`
  ${textSize.f14};
  color: ${color.slateDarker};
  font-weight: ${textWeight.semiBold};
`;

export const MonthButton = styled(TempoButton)<CustomButtonProps>`
  border-radius: 2px;

  > span {
    min-height: 32px;
    width: 81px;
    border: 1px solid ${({ isActive }) => (isActive ? color.teal : '#e1e6e9')};
  }

  span {
    border-radius: 2px;
    color: ${({ isActive }) => (isActive ? color.white : color.slateMid)};
  }
`;
