import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import {
  createTable,
  getCoreRowModelSync,
  useTableInstance,
} from '@tanstack/react-table';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import {
  AutoCompleteInput,
  AutoCompleteItem,
  Paginator,
} from '@nowsta/tempo-ds';

import EmptyState from 'features/common/components/EmptyState';
import { QueryKeys } from 'features/common/constants';
import { fetchCompaniesRequest } from 'features/companies/hooks/queries';
import { useURLQueryParams } from 'features/common/hooks/useURLQueryParams';
import { fetchAllInvoices, fetchPunchcardInvoices } from 'services';

import LoadingState from '../LoadingState';

import { HEADER_COLUMNS, getInvoiceData } from './helpers';
import { InvoicesFilters } from './types';

import * as Presenter from './Presenter';
import EditEntityDrawer from '../EditInvoiceDrawer';

const PunchcardInvoicesListView = (
  props: React.ComponentPropsWithRef<'div'>,
) => {
  const { urlParams, setURLParams } = useURLQueryParams();
  const [editInvoiceId, setEditInvoiceId] = useState<number | null>(null);

  const { clientFilters } = urlParams.all();

  const filters = useMemo(
    () => JSON.parse(clientFilters || '{"page": 1, "limit": 10}'),
    [clientFilters],
  );

  const methods = useForm<InvoicesFilters>({
    defaultValues: {
      companyId: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const formData = useWatch({ control: methods.control });

  useEffect(() => {
    methods.reset({ ...methods.getValues(), ...filters });
  }, [methods.reset, filters]);

  const companiesQuery = useQuery(
    [QueryKeys.companies, { offset: 1, limit: 0, search: '' }],
    () => fetchCompaniesRequest(1, 0, ''),
    {
      select: queryData => queryData?.items,
    },
  );

  const invoicesQuery = useQuery(
    [
      QueryKeys.clientInvoices,
      { companyId: formData.companyId || undefined, ...filters },
    ],
    () => {
      if (!formData.companyId) {
        return fetchAllInvoices({ params: filters });
      }
      return fetchPunchcardInvoices({
        params: { companyId: formData.companyId || undefined, ...filters },
      });
    },
    {
      enabled: methods.formState.isValid,
    },
  );

  const handlePaginationChange = (param: 'limit' | 'page') => (value: number) =>
    setURLParams(prev => ({
      ...prev,
      clientFilters: JSON.stringify({
        ...filters,
        [param]: value,
      }),
    }));

  const handleSelectedCompany =
    (onChange: (event: any) => void) =>
    (selection: AutoCompleteItem<object> | null) => {
      onChange(selection?.id);
      setURLParams(prev => ({
        ...prev,
        clientFilters: JSON.stringify({
          ...filters,
          companyId: selection?.id,
        }),
      }));
    };

  const table = createTable();

  const columnsPlaceholder = useMemo(() => HEADER_COLUMNS, []);

  const invoiceEdit = useMemo(() => {
    if (editInvoiceId) {
      return invoicesQuery.data?.items.find(
        invoice => invoice.id === editInvoiceId,
      );
    }
  }, [editInvoiceId, invoicesQuery.data]);

  const handleCloseEditInvoice = () => setEditInvoiceId(null);
  const handleSuccessEditInvoice = () => {
    invoicesQuery.refetch();
  };

  const data = useMemo(
    () =>
      invoicesQuery.data?.items.map(item =>
        getInvoiceData(item, { onEdit: setEditInvoiceId }),
      ) ?? [],
    [invoicesQuery.data?.items],
  );

  const columns = table.createColumns(columnsPlaceholder);

  const instance = useTableInstance(table, {
    data,
    columns,
    getCoreRowModel: getCoreRowModelSync(),
  });

  const isLoadingInvoices =
    invoicesQuery.isLoading && invoicesQuery.fetchStatus !== 'idle';
  const selectedCompany = useMemo(
    () =>
      companiesQuery.data?.find(
        company => company.companyId === filters.companyId,
      ),
    [filters.companyId, companiesQuery.data],
  );
  const renderContent = () => {
    if (isLoadingInvoices) return <LoadingState />;

    if (!invoicesQuery.data?.items.length) {
      const baseMessage = 'No invoices found for';
      const complement = selectedCompany
        ? `company: ${selectedCompany?.name}.`
        : 'the criteria provided.';

      const message = `${baseMessage} ${complement}`;
      return <EmptyState message={message} />;
    }

    return (
      <Presenter.TableContainer>
        <Presenter.Table {...instance.getTableProps()}>
          <Presenter.TableHeader>
            {instance.getHeaderGroups().map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.renderHeader()}</th>
                ))}
              </tr>
            ))}
          </Presenter.TableHeader>
          <Presenter.TableBody {...instance.getTableBodyProps()}>
            {instance.getRowModel().rows.map(row => (
              <tr {...row.getRowProps()}>
                {row.getVisibleCells().map(cell => (
                  <td role="presentation" {...cell.getCellProps()}>
                    <Presenter.Cell>{cell.renderCell()}</Presenter.Cell>
                  </td>
                ))}
              </tr>
            ))}
          </Presenter.TableBody>
        </Presenter.Table>
      </Presenter.TableContainer>
    );
  };

  return (
    <div {...props}>
      <Presenter.Header>Invoices</Presenter.Header>
      <FormProvider {...methods}>
        <Controller
          name="companyId"
          render={({ field }) => (
            <AutoCompleteInput
              label="Company"
              openOnFocus
              palette="white"
              inputValue={selectedCompany?.name || ''}
              onSelected={handleSelectedCompany(field.onChange)}
              placeholder="Company"
              {...field}
              items={
                companiesQuery.data?.map(company => ({
                  id: company.companyId,
                  displayValue: company.name,
                })) ?? []
              }
            />
          )}
        />
      </FormProvider>
      {renderContent()}

      {!isLoadingInvoices && (
        <Paginator
          palette="white"
          uiSize="small"
          limitOptions={[
            10,
            25,
            50,
            100,
            invoicesQuery.data?.meta?.totalItems ?? 0,
          ]}
          isWorking={isLoadingInvoices}
          spaceUI={{ pT: 12, pB: 16 }}
          pageNumber={filters.page}
          limit={filters.limit}
          onLimitChange={handlePaginationChange('limit')}
          onPageNumberChange={handlePaginationChange('page')}
          totalPages={invoicesQuery?.data?.meta?.totalPages ?? 0}
        />
      )}
      {invoiceEdit && (
        <EditEntityDrawer
          entity={invoiceEdit!}
          visible={Boolean(editInvoiceId)}
          onClose={handleCloseEditInvoice}
          onSuccess={handleSuccessEditInvoice}
        />
      )}
    </div>
  );
};

export default PunchcardInvoicesListView;
