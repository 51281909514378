import styled from 'styled-components/macro';
import { color } from 'features/common/constants';
import { rem } from '@nowsta/ux-common-components';

export const H1 = styled.h1`
  color: ${color.slateDarker};
  font-size: 2rem;
  line-height: 2.5rem;
  margin-block-start: 0;
  margin-block-end: 0;
`;

export const H2 = styled.h2`
  color: ${color.slateDarker};
  font-size: ${rem(18)};
  line-height: 1.75rem;
  margin-block-start: 0;
  margin-block-end: 0;
`;

export const H3 = styled.h3`
  color: ${color.slateDarker};
  font-size: 1.125rem;
  line-height: 1.375rem;
  margin-block-start: 0;
  margin-block-end: 0;
`;
