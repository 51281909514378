import ContentLoader from 'react-content-loader';

import { color } from 'features/common/constants';

export const ListItemSkeleton = () => (
  <ContentLoader
    speed={2}
    width="100%"
    viewBox="0 0 400 160"
    backgroundColor={color.skeletonBg}
    foregroundColor={color.skeletonFg}
  >
    <rect x="0" y="0" rx="3" ry="3" width="50%" height="15" />
    <rect x="0" y="20" rx="3" ry="3" width="100%" height="15" />
    <rect x="0" y="40" rx="0" ry="0" width="100%" height="4" />
    <rect x="0" y="50" rx="3" ry="3" width="100%" height="15" />
    <rect x="0" y="70" rx="0" ry="0" width="100%" height="4" />
    <rect x="0" y="80" rx="3" ry="3" width="100%" height="15" />
  </ContentLoader>
);
