import { useState, useMemo, useCallback, useEffect } from 'react';
import { useSearchParams, createSearchParams } from 'react-router-dom';

export const useURLQueryParams = () => {
  const [routerParams, setRouterParams] = useSearchParams();
  const [params, setParams] = useState<Record<string, string>>({});

  const urlParams = useMemo(
    () => ({
      get(key: string) {
        return params[key] ? decodeURIComponent(params[key]) : undefined;
      },
      all() {
        return Object.fromEntries(
          Object.entries(params).map(([key, value]) => [
            key,
            decodeURIComponent(value),
          ]),
        );
      },
    }),
    [params],
  );

  const setURLParams = useCallback(
    (
      args:
        | Record<string, string>
        | ((paramsState: Record<string, string>) => Record<string, string>),
    ) => {
      if (typeof args === 'function') {
        setParams(prevParams => args(prevParams));
        setRouterParams(createSearchParams(args(params)), {
          replace: true,
        });

        return;
      }
      setParams(prevParams => ({ ...prevParams, ...args }));
      setRouterParams(createSearchParams({ ...params, ...args }), {
        replace: true,
      });
    },
    [params, setRouterParams],
  );

  useEffect(() => {
    const currentParams = Object.fromEntries(routerParams.entries());

    setRouterParams(createSearchParams(currentParams), { replace: true });
    setParams(currentParams);
  }, [routerParams, setRouterParams]);

  return { urlParams, setURLParams };
};
