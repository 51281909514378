import { AxiosRequestConfig } from 'axios';

import { client } from './api';
import { endpoints } from './endpoints';

interface FetchEventDataResponse {
  events: string[];
  department_events: string[];
}

export const fetchEventsData = async (options?: AxiosRequestConfig) => {
  const response = await client.get<FetchEventDataResponse>(
    endpoints.eventsWithDepartments,
    options,
  );

  return response?.data;
};
