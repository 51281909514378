import styled from 'styled-components/macro';
import { H1 } from 'features/common/components/headings';
import { textWeight, rem, textSize } from '@nowsta/ux-common-components';
import { breakpoints, color } from 'features/common/constants';

export const Header = styled(H1)`
  margin-top: 1.3rem;
  margin-bottom: 1.48rem;
`;

export const Table = styled.table`
  margin-top: 40px;
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;

  thead {
    tr {
      background: ${color.slateDarker};
      font-family: 'Silka';
      font-style: normal;
      font-weight: ${textWeight.semiBold};
      font-size: ${rem(14)};
      line-height: ${rem(18)};
      text-align: right;
      color: white;
      height: 48px;

      th {
        padding: 0 24px;

        background: ${color.slateDarker};
        text-align: left;
        max-width: 100px;
        font-style: normal;
        font-weight: ${textWeight.regular};
        font-size: ${rem(12)};
        line-height: ${rem(14)};
        left: 0;
        z-index: 1;
        border-right: 0;
      }
    }
  }

  tbody {
    tr {
      height: 40px;
      background: #ffffff;
      border-bottom: 1px solid ${color.pattensBlue};

      td {
        padding: 0 24px;
        font-family: 'Silka';
        font-style: normal;
        font-weight: ${textWeight.regular};
        font-size: 14px;
        line-height: 18px;
        text-align: right;
        color: ${color.slateDarker};
        text-align: left;

        &:first-child {
          font-weight: ${textWeight.semiBold};
          ${textSize.f14};
          background-color: white;
        }
      }
    }
  }
`;

export const ButtonsHolder = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: 23px 0;
  justify-content: space-between;

  @media (max-width: ${breakpoints.medium}) {
    flex-direction: column;
    gap: 8px;
  }
`;
