import { rem } from '@nowsta/tempo-ds';
import styled from 'styled-components';

export const SkeletonContainer = styled.div`
  background-color: white;
  padding: ${rem(4)};
  border-top-right-radius: ${rem(16)};
  border-top-left-radius: ${rem(16)};

  > span:first-child {
    border-top-right-radius: ${rem(16)};
    border-top-left-radius: ${rem(16)};
  }
`;
