import { useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

import { EditAgencyDTO } from 'features/agencies/dto';
import { useUpdateAgency } from 'features/agencies/hooks/queries';
import Drawer from 'features/common/components/Drawer';
import { DrawerTitle } from 'features/common/components/Drawer/styled';
import { ControlledTextInput } from 'features/common/components/ControlledTextInput';
import { Form } from './styled';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  agency?: EditAgencyDTO;
}

export const EditAgencyDrawer = ({ onClose, isOpen, agency }: Props) => {
  const { handleSubmit, control, reset } = useForm<EditAgencyDTO>();

  const { mutate } = useUpdateAgency({
    onSuccess: () => {
      onClose();
      reset();
    },
  });

  useEffect(() => {
    reset({ name: agency?.name, abbreviation: agency?.abbreviation });
  }, [agency]);

  const onSubmit: SubmitHandler<any> = data => {
    mutate({ id: agency?.id!, ...data });
  };
  const dispatchForm = () => handleSubmit(onSubmit)();

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <Drawer
      title="Edit Agency"
      onClose={handleClose}
      onBack={handleClose}
      onContinue={dispatchForm}
      continueText="Update Agency"
      isOpen={isOpen}
    >
      <DrawerTitle>Edit Agency Details</DrawerTitle>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <ControlledTextInput
          control={control}
          label="Agency name"
          name="name"
          placeholder="e.g ‘Big Agency Inc’"
        />
        <ControlledTextInput
          control={control}
          spaceUI={{ mT: 16 }}
          label="Agency abbreviation"
          name="abbreviation"
          placeholder="e.g ‘BAI’"
        />
      </Form>
    </Drawer>
  );
};
