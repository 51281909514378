import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import {
  Tag,
  Button,
  Plus,
  MagnifyingGlass,
  TextInput,
  BusyIndicator,
} from '@nowsta/ux-common-components';
import { Notifier } from '@nowsta/tempo-ds';

import {
  createTable,
  getCoreRowModelSync,
  useTableInstance,
} from '@tanstack/react-table';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { AutoCompleteInput } from 'features/common/components/AutoCompleteInput';
import { H2 } from 'features/common/components/headings';
import Layout from 'features/common/components/Layout';
import { Pagination } from 'features/common/components/Pagination';
import SelectPlaceholder from 'features/common/components/SelectPlaceholder';
import { YearMonthPicker } from 'features/common/components/YearMonthPicker';
import { useURLQueryParams } from 'features/common/hooks/useURLQueryParams';
import { ApiPaginationMeta } from 'features/common/types';
import { useFetchCompanies } from 'features/companies/hooks/queries';
import { useFetchInvoices } from 'features/invoices-history/hooks/queries';
import { nullifyFalsy } from 'features/common/utils';
import {
  InvoiceListItem,
  InvoiceReportStatus,
} from 'features/invoices-history/types';
import { InvoicingTerm, routes } from 'features/common/constants';
import { getFormattedDate } from 'features/rates/components/RatesListView/utils';
import { fetchInvoiceCSV } from 'services';

import CreateInvoiceReportDrawer from '../CreateInvoiceReportDrawer';
import { InvoiceErrors } from '../InvoiceErrors';
import { InvoiceListEmptyState } from '../InvoiceListEmptyState';

import { ReactComponent as Clock } from './clock.svg';

import {
  ActionsHolder,
  ButtonsHolder,
  Header,
  Row,
  Table,
  NewInvoicePageLink,
  OnQueue,
  GeneratingCSV,
  SelectInput,
  DeparmentsHolder,
} from './styled';

const parseDepartments = (str: string) => {
  try {
    const arrayOfDeparments = JSON.parse(str);
    return (
      <DeparmentsHolder>
        {arrayOfDeparments.map((item: any) => (
          <Tag uiPresets={{ uiSize: 'smaller' }}>{item.label}</Tag>
        ))}
      </DeparmentsHolder>
    );
  } catch (e) {
    return <Tag uiPresets={{ uiSize: 'smaller' }}>All Departments</Tag>;
  }
};

const contentByInvoiceStatus = (
  id: number,
  handleErrorDetails: (id: number) => void,
) => ({
  [InvoiceReportStatus.SUCCESS]: (
    <Button onClick={() => fetchInvoiceCSV(id)} uiPresets={{ uiSize: 'small' }}>
      Download CSV
    </Button>
  ),
  [InvoiceReportStatus.ERROR]: (
    <Button
      actionType="critical"
      uiPresets={{ uiSize: 'small' }}
      onClick={() => handleErrorDetails(id)}
    >
      Error Details
    </Button>
  ),
  [InvoiceReportStatus.IN_PROGRESS]: (
    <GeneratingCSV>
      <BusyIndicator />
      Generating .CSV
    </GeneratingCSV>
  ),
  [InvoiceReportStatus.PENDING]: (
    <OnQueue>
      <Clock />
      On Queue...
    </OnQueue>
  ),
});

interface InvoiceError {
  invoiceId: null | number;
  visible: boolean;
}

const InvoicesListView = () => {
  const [companySearchValue, setCompanySearchValue] = useState('');
  const [selectedCompany, setSelectedCompany] = useState<number>();
  const [date, setDate] = useState<Date | null>(null);
  const [terms, setTerms] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [isDrawerOpen, setIsModalOpen] = useState(false);
  const [viewErrorsData, setViewErrors] = useState<InvoiceError>({
    invoiceId: null,
    visible: false,
  });

  const { useNewInvoice } = useFlags();

  const handleTermsChange = (e: ChangeEvent<HTMLSelectElement>) =>
    setTerms(e.target.value);
  const handleCompanyValueChange = (value: string) =>
    setCompanySearchValue(value);
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearchValue(e.target.value);
  const handleSelectCompany = (companyId: number | string) =>
    setSelectedCompany(+companyId);
  const handleCloseDrawer = () => setIsModalOpen(false);
  const handleOpenDrawer = () => setIsModalOpen(true);

  const { urlParams, setURLParams } = useURLQueryParams();
  const page = urlParams.get('page') ?? '1';
  const limit = urlParams.get('limit') ?? '10';

  const { data: autoCompleteCompanies, isLoading: autocompleteLoading } =
    useFetchCompanies(1, 10, companySearchValue);

  const handleViewErrorsForInvoice = useCallback((invoiceId: number) => {
    setViewErrors({
      invoiceId,
      visible: true,
    });
  }, []);

  const handleCloseErrorsModal = useCallback(
    () =>
      setViewErrors(prevState => ({
        ...prevState,
        visible: false,
      })),
    [],
  );

  const handlePageLimitChange = useCallback(
    (newLimit: number) => {
      setURLParams({ limit: String(newLimit) });
    },
    [setURLParams],
  );

  const handlePageNumberChange = useCallback(
    (newPage: number) => {
      setURLParams({ page: String(newPage) });
    },
    [setURLParams],
  );

  const { data } = useFetchInvoices(
    page,
    limit,
    date,
    nullifyFalsy(terms),
    nullifyFalsy(searchValue),
    selectedCompany,
  );

  const table = createTable<{ Row: { [value: string]: any } }>();
  const columns = useMemo(() => {
    const defaultColumns = [
      table.createDataColumn('requester', {
        id: 'requester',
        cell: info => info.value,
        header: () => 'Invoice Requester',
      }),
      table.createDataColumn('companyName', {
        id: 'companyName',
        cell: info => info.value,
        header: () => "Company's Name",
      }),
      table.createDataColumn('departments', {
        id: 'departments',
        cell: info => info.value,
        header: () => 'Department(s)',
      }),
      table.createDataColumn('dateRange', {
        id: 'dateRange',
        cell: info => info.value,
        header: () => 'Date Range',
      }),
      table.createDataColumn('invoiceNumber', {
        id: 'invoiceNumber',
        cell: info => info.value,
        header: () => 'Invoice #',
      }),
      table.createDataColumn('term', {
        id: 'term',
        cell: info => info.value,
        header: () => 'Term',
      }),
      table.createDataColumn('actions', {
        id: 'actions',
        cell: info => info.value,
        header: () => ' ',
      }),
    ];

    return table.createColumns(defaultColumns);
  }, [data]);

  const tableData = useMemo(() => {
    const toReturn = data?.items?.map((invoice: InvoiceListItem) => ({
      requester: invoice.createdByName,
      companyName: invoice?.companyName,
      dateRange: `${getFormattedDate(
        new Date(invoice.startDate),
      )} - ${getFormattedDate(new Date(invoice.endDate))}`,
      invoiceNumber: invoice.invoiceNumber,
      term: invoice.term,
      departments: parseDepartments(invoice.deparments),
      actions: contentByInvoiceStatus(invoice.id, handleViewErrorsForInvoice)[
        invoice.status
      ],
    }));
    return toReturn;
  }, [data]);

  const instance = useTableInstance(table, {
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModelSync(),
  });

  const showTable = data && data?.items?.length > 0;

  const renderTablePagination = (tableMetadata?: ApiPaginationMeta) => {
    if (tableMetadata && tableMetadata.itemCount) {
      const { itemCount, currentPage, totalPages } = tableMetadata;

      return (
        <Pagination
          pageNumber={currentPage}
          totalPages={Number(totalPages)}
          limit={itemCount}
          onLimitChange={handlePageLimitChange}
          onPageNumberChange={handlePageNumberChange}
        />
      );
    }

    return undefined;
  };

  return (
    <Layout>
      <Header>Labor Market Invoice Sheet</Header>
      {useNewInvoice && (
        <Notifier
          palette="notice"
          title="This invoice page is deprecated as of 11/12/2023"
          description={
            <>
              To create a new invoice, please use the{' '}
              <NewInvoicePageLink to={routes.invoices}>
                new invoice tool
              </NewInvoicePageLink>
              . This page will continue to work as expected until we are ready
              to permanently move to the new tool.
            </>
          }
        />
      )}
      <ButtonsHolder>
        <ActionsHolder>
          <Button
            onClick={handleOpenDrawer}
            sizeUI={{ minW: 150 }}
            iconLeft={<Plus />}
            actionType="primary"
          >
            New .CSV Request
          </Button>
        </ActionsHolder>
        <ActionsHolder>
          <AutoCompleteInput
            inputStyle="uiAlt"
            name="company"
            placeholder="Company"
            onSelected={handleSelectCompany}
            inputValue={companySearchValue}
            onInputValueChange={handleCompanyValueChange}
            items={
              autoCompleteCompanies?.items?.map((company: any) => ({
                id: company.id,
                value: company.name,
              })) ?? []
            }
            loading={autocompleteLoading}
          />
          <YearMonthPicker
            placeholder="Month/Year"
            value={date}
            onChange={setDate}
          />
          <SelectInput
            value={terms}
            onChange={handleTermsChange}
            inputStyle="uiAlt"
            sizeUI={{ w: 160 }}
          >
            <SelectPlaceholder>e.g Net 30</SelectPlaceholder>
            {Object.values(InvoicingTerm).map(term => (
              <option key={term} value={term}>
                {term}
              </option>
            ))}
          </SelectInput>
        </ActionsHolder>
      </ButtonsHolder>

      <Row>
        <H2>
          {showTable
            ? `Showing ${data?.meta.itemCount} - ${data?.meta.totalItems} Reports`
            : 'No Reports'}
        </H2>
        <TextInput
          inputStyle="uiAlt"
          placeholder="Search"
          onChange={handleSearch}
          value={searchValue}
          iconLeft={<MagnifyingGlass />}
        />
      </Row>

      <InvoiceErrors
        invoiceId={viewErrorsData.invoiceId}
        visible={viewErrorsData.visible}
        handleClose={handleCloseErrorsModal}
      />

      {showTable ? (
        <Table {...instance.getTableProps()}>
          <thead>
            {instance.getHeaderGroups().map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(header => (
                  <th {...header.getHeaderProps()}>
                    {header.isPlaceholder ? null : header.renderHeader()}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...instance.getTableBodyProps()}>
            {instance.getRowModel().rows.map(row => (
              <tr {...row.getRowProps()}>
                {row.getVisibleCells().map(cell => (
                  <td role="presentation" {...cell.getCellProps()}>
                    {cell.renderCell()}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <InvoiceListEmptyState handleOpenDrawer={handleOpenDrawer} />
      )}

      {showTable && renderTablePagination(data?.meta)}

      <CreateInvoiceReportDrawer
        isOpen={isDrawerOpen}
        onClose={handleCloseDrawer}
      />
    </Layout>
  );
};

export default InvoicesListView;
