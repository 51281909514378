import { useEffect, useMemo, useState } from 'react';
import {
  createTable,
  getCoreRowModelSync,
  useTableInstance,
} from '@tanstack/react-table';
import {
  Button,
  DotsVertical,
  FloatingMenu,
  FloatingMenuItem,
  Plus,
  rem,
} from '@nowsta/ux-common-components';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosResponse } from 'axios';

import { patchCompany, RateByCompany } from 'services';

import { EditCompanyDTO } from 'features/companies/dto';
import { EditCompanyDrawer } from 'features/companies/components/EditCompanyDrawer';
import { useToast } from 'features/common/contexts/ToastContext';
import { FETCH_RATES_BY_COMPANY_QUERY } from 'features/rates/hooks/queries';

import { Holder, Header } from './styled';

interface Props {
  data: RateByCompany;
  handleOpenDrawer: (companyId: number, name: string) => void;
  onSelectRate: (
    agencyData: { id: number; name: string } | null,
    companyData: { id: number; name: string },
  ) => void;
}
const table = createTable<{ Row: { [value: string]: any } }>();

export const CompanyRatesTable = ({
  data,
  handleOpenDrawer,
  onSelectRate,
}: Props) => {
  const columns = useMemo(() => {
    const { positions } = data;
    const processedAgencyIds: number[] = [];

    const defaultColumns = [
      table.createGroup({
        header: 'Position',
        // eslint-disable-next-line react/prop-types
        footer: props => props.column.id,
        columns: [
          table.createDataColumn('position', {
            cell: info => info.value,
            // eslint-disable-next-line react/prop-types
            footer: props => props.column.id,
            header: 'Position',
          }),
        ],
      }),
      table.createGroup({
        header: ' ',
        // eslint-disable-next-line react/prop-types
        footer: props => props.column.id,
        columns: [
          table.createDataColumn('defaultRate', {
            id: 'defaultRate',
            cell: info => info.value,
            header: () => 'Default Rate',
          }),
        ],
      }),
    ];

    for (
      let positionIndex = 0;
      positions.length > positionIndex;
      ++positionIndex
    ) {
      const currentPosition = positions[positionIndex];

      const { rates } = currentPosition;
      for (let rateIndex = 0; rates.length > rateIndex; ++rateIndex) {
        const currentRate = rates[rateIndex];
        const { agencyId, agencyName } = currentRate;

        if (agencyId && agencyName && !processedAgencyIds.includes(agencyId)) {
          processedAgencyIds.push(agencyId);

          const newHeader = table.createGroup({
            header: agencyName,
            // eslint-disable-next-line react/prop-types
            footer: props => props.column.id,
            columns: [
              table.createDataColumn(`cbr${agencyId}`, {
                cell: info => info.value,
                header: 'CBR',
              }),
              table.createDataColumn(`apr${agencyId}`, {
                cell: info => info.value,
                header: 'APR',
              }),
            ],
          });

          defaultColumns.push(newHeader);
        }
      }
    }

    if (defaultColumns.length < 2) {
      const newGroup = table.createGroup({
        header: 'No Agencies Found',
        // eslint-disable-next-line react/prop-types
        footer: props => props.column.id,
        columns: [
          table.createDataColumn(`cbrnull`, {
            cell: info => info.value,
            header: 'CBR',
          }),
          table.createDataColumn(`aprnull`, {
            cell: info => info.value,
            header: 'APR',
          }),
        ],
      });
      defaultColumns.push(newGroup);
    }

    return table.createColumns(defaultColumns);
  }, [data]);

  const tableData = useMemo(() => {
    const toReturn = data.positions.map(position => {
      const positionObj: Record<string, any> = {
        position: position.positionName || 'No Positions',
      };

      position.rates.forEach(rate => {
        if (rate.agencyId) {
          positionObj[`apr${rate.agencyId}`] = rate.apr ? `$${rate.apr}` : '--';
          positionObj[`cbr${rate.agencyId}`] = rate.cbr ? `$${rate.cbr}` : '--';
        } else {
          positionObj.defaultRate = rate.cbr ? `$${rate.cbr}` : '--';
        }
      });
      return positionObj;
    });

    if (toReturn.length < 2) {
      toReturn[0].aprnull = '--';
      toReturn[0].cbrnull = '--';
    }

    return toReturn;
  }, [data]);

  const instance = useTableInstance(table, {
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModelSync(),
  });

  const handleAddNewRateButton = (companyId: number, name: string) => () =>
    handleOpenDrawer(companyId, name);

  const getAgencyNameAfterId = (id: number) =>
    data.positions[0].rates.find(rate => rate.agencyId === id)?.agencyName;

  const removeAprOrCbr = (value: string) =>
    Number(value.replace('apr', '').replace('cbr', ''));
  const handleEditRate = (agencyId: number) => () => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(agencyId)) {
      onSelectRate(null, { id: data.companyId, name: data.companyName });
    } else {
      onSelectRate(
        { id: agencyId, name: getAgencyNameAfterId(agencyId) || '' },
        { id: data.companyId, name: data.companyName },
      );
    }
  };

  const queryClient = useQueryClient();
  const { addToast } = useToast();
  const [floatingMenuVisible, setFloatingMenuVisible] = useState(false);
  const [editDrawerVisible, setEditDrawerVisible] = useState(false);

  const handleToggleMenu = () => {
    setFloatingMenuVisible(prevVisible => !prevVisible);
  };

  const handleTogglDrawer = () => {
    setEditDrawerVisible(prevVisible => !prevVisible);
  };

  const {
    mutate: companyMutate,
    isSuccess: companySuccess,
    isError: companyIsError,
    error: companyError,
    reset: companyReset,
  } = useMutation(patchCompany);

  const handleEditCompany = (companyData: EditCompanyDTO) => {
    companyMutate(companyData);
  };

  useEffect(() => {
    if (companySuccess) {
      setEditDrawerVisible(false);
      addToast({ message: 'company updated successfully!', theme: 'active' });
      companyReset();
      handleTogglDrawer();

      setTimeout(() => {
        queryClient.invalidateQueries([FETCH_RATES_BY_COMPANY_QUERY]);
      }, 600);
    }

    if (companyIsError) {
      const { response } = companyError as { response: AxiosResponse };
      addToast({ message: response.data.message, theme: 'critical' });
      companyReset();
    }
  }, [
    companySuccess,
    companyError,
    addToast,
    companyIsError,
    companyReset,
    queryClient,
  ]);

  return (
    <Holder>
      <Header>
        <h1>{data.companyName}</h1>
        <div style={{ display: 'flex', gap: rem(8) }}>
          <Button
            uiPresets={{ uiSize: 'small' }}
            iconLeft={<Plus />}
            onClick={handleAddNewRateButton(
              Number(data.companyId),
              data.companyName,
            )}
          >
            Add Rate
          </Button>
          <div style={{ position: 'relative' }}>
            <Button
              uiPresets={{ uiSize: 'small' }}
              iconLeft={<DotsVertical />}
              onClick={handleToggleMenu}
            />
            <FloatingMenu
              onClose={handleToggleMenu}
              visible={floatingMenuVisible}
            >
              <FloatingMenuItem
                label="Edit Company"
                onClick={handleTogglDrawer}
                type="button"
              />
            </FloatingMenu>
          </div>
        </div>
      </Header>
      <table {...instance.getTableProps()}>
        <thead>
          {instance.getHeaderGroups().map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(header => (
                <th {...header.getHeaderProps()}>
                  {header.isPlaceholder ? null : header.renderHeader()}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...instance.getTableBodyProps()}>
          {instance.getRowModel().rows.map(row => (
            <tr {...row.getRowProps()}>
              {row.getVisibleCells().map(cell => (
                <td
                  role="presentation"
                  {...cell.getCellProps()}
                  onClick={handleEditRate(removeAprOrCbr(cell.column.id))}
                >
                  {cell.renderCell()}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <EditCompanyDrawer
        isOpen={editDrawerVisible}
        onClose={handleTogglDrawer}
        onSuccess={handleEditCompany}
        data={{
          id: data.companyId,
          name: data.companyName,
          companyId: data.customCompanyId,
          timeZone: data.timeZone,
        }}
      />
    </Holder>
  );
};

export default CompanyRatesTable;
