import { Button, MediumModal } from '@nowsta/ux-common-components';
import { useFetchPaymentErrors } from 'features/payments/hooks/queries';
import { ErrorLine } from './styled';

interface Props {
  visible: boolean;
  handleClose: () => void;
  paymentId: null | number;
}

export const PaymentErrors = ({ visible, paymentId, handleClose }: Props) => {
  const { data } = useFetchPaymentErrors(paymentId);
  return (
    <MediumModal
      visible={visible}
      onClose={handleClose}
      enableCloseOnOutsideClick
      title="Oops! There seems to be a Data Discrepancy"
      body={
        <div>
          {data?.data?.map((errorLine: { id: number; description: string }) => (
            <ErrorLine key={errorLine.id}>{errorLine.description}</ErrorLine>
          ))}
        </div>
      }
      footer={
        <Button actionType="primary" onClick={handleClose}>
          Close
        </Button>
      }
    />
  );
};
