import { rem, textWeight } from '@nowsta/ux-common-components';
import { breakpoints } from 'features/common/constants';
import styled from 'styled-components/macro';
import { H1, H2 } from '../headings';

// TODO: FIXME: Restore box shadow

export const Holder = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  overflow-y: auto;
  height: 100vh;
  width: 640px;
  background-color: #fff;
  border-radius: 16px 0 0 16px;
  right: ${props => (props.isOpen ? 0 : '-640px')};
  transition: 0.6s;
  z-index: 10;
  display: flex;
  flex-direction: column;
  box-shadow: ${props =>
    props.isOpen
      ? `0 20px 40px -8px rgba(0, 0, 0, 0.08), 0px 40px 80px -24px rgba(0, 0, 0, 0.2), 0 80px 160px -24px rgba(0, 0, 0, 0.2)`
      : '0'};

  @media (max-width: ${breakpoints.small}) {
    width: 100%;
  }
`;

export const DrawerHeader = styled.header`
  min-height: 72px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.08);
  font-family: Silka;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px 0 24px;
`;

export const Title = styled(H1)`
  font-weight: ${textWeight.bold};
  font-size: ${rem(16)};
  line-height: ${rem(24)};
`;
export const DrawerFooter = styled.footer`
  min-height: 72px;
  background: #ffffff;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
`;

export const DrawerContent = styled.section`
  padding: 22px 32px;
  flex: 1;

  @media (max-width: ${breakpoints.small}) {
    padding: 22px 10px;
  }
`;

export const DrawerTitle = styled(H2)`
  font-family: Silka;
  font-style: normal;
  font-weight: bold;
  font-size: ${rem(20)};
  line-height: ${rem(28)};
`;
