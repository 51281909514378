import React, { useCallback, useState, useMemo } from 'react';
import {
  Button,
  ChevronDown,
  ChevronUp,
  Plus,
  SelectInput,
  Tag,
  Trash,
} from '@nowsta/ux-common-components';
import {
  createTable,
  getCoreRowModelSync,
  useTableInstance,
} from '@tanstack/react-table';

import Layout from 'features/common/components/Layout';
import { useURLQueryParams } from 'features/common/hooks/useURLQueryParams';
import { useFetchCompanies } from 'features/companies/hooks/queries';
import { AutoCompleteInput } from 'features/common/components/AutoCompleteInput';
import { Pagination } from 'features/common/components/Pagination';
import { useFetchPositions } from 'features/positions/hooks/queries';
import { PositionDTO } from 'features/positions/dto';
import { useFetchOrderAllocation } from 'features/orderAllocation/hooks/queries';
import { ApiPaginationMeta, Order, SortingState } from 'features/common/types';
import { useDeleteOrderAllocation } from 'features/orderAllocation/hooks/mutations';

import {
  ActionsHolder,
  ButtonsHolder,
  Header,
  Table,
  SortingArea,
  AllocationRow,
  AllocationAgency,
  AllocationAgencyContent,
} from './styled';
import { SetupOrderAllocationDrawer } from '../SetupOrderAllocationDrawer';
import { OrderAllocationListItem } from '../../types';

interface DrawerStates {
  create: boolean;
  edit: boolean;
}

export interface TableData {
  companyName: string;
  positionName: string;
}

export const OrderAllocationListView = () => {
  const [drawerState, setDrawerState] = useState<DrawerStates>({
    create: false,
    edit: false,
  });
  const [sorting, setSorting] = useState<SortingState<TableData>>({
    orderBy: 'companyName',
    order: Order.DESC,
  });
  const [companySearchValue, setCompanySearchValue] = useState('');
  const [selectedCompany, setSelectedCompany] = useState<number>();
  const [selectedCompanyName, setSelectedCompanyName] = useState<string>('');
  const [selectedPosition, setSelectedPosition] = useState<number>();
  const [editCompany, setEditCompany] = useState<number>();
  const [editPosition, setEditPosition] = useState<number>();
  const [editAllRemainingSlots, setEditAllRemainigSlots] = useState<
    number | null
  >(null);

  const { urlParams, setURLParams } = useURLQueryParams();
  const page = urlParams.get('page') ?? '1';
  const limit = urlParams.get('limit') ?? '10';

  const { data: autoCompleteCompanies, isLoading: companyAutocompleteLoading } =
    useFetchCompanies(1, 10, companySearchValue);

  const { mutate } = useDeleteOrderAllocation();

  const { data: { items: positions } = {} } = useFetchPositions({
    page: 1,
    limit: 0,
  });

  const toggleSorting = (fieldName: keyof TableData) => () =>
    setSorting(prevState => {
      if (fieldName !== prevState.orderBy) {
        return {
          orderBy: fieldName,
          order: Order.DESC,
        };
      }
      return {
        ...prevState,
        order: prevState.order === Order.ASC ? Order.DESC : Order.ASC,
      };
    });

  const handlePageLimitChange = useCallback(
    (newLimit: number) => {
      setURLParams({ limit: String(newLimit) });
    },
    [setURLParams],
  );

  const handlePageNumberChange = useCallback(
    (newPage: number) => {
      setURLParams({ page: String(newPage) });
    },
    [setURLParams],
  );

  const handleCompanyValueChange = (value: string) =>
    setCompanySearchValue(value);

  const handleSelectCompany = (companyId: number | string) =>
    setSelectedCompany(+companyId);

  const handleSelectPosition = (e: React.ChangeEvent<HTMLSelectElement>) =>
    Number.isNaN(+e.target.value)
      ? setSelectedPosition(undefined)
      : setSelectedPosition(+e.target.value);

  const handleOpenCreateDrawer = () => {
    setEditCompany(undefined);
    setEditPosition(undefined);
    setSelectedCompanyName('');
    setEditAllRemainigSlots(null);
    setDrawerState({ create: true, edit: false });
  };

  const handleOpenEditDrawer =
    (
      companyId: number,
      positionId: number,
      companyName: string,
      allRemainingSlots: number | null,
    ) =>
    () => {
      setEditCompany(companyId);
      setEditPosition(positionId);
      setEditAllRemainigSlots(allRemainingSlots);
      setSelectedCompanyName(companyName);
      setDrawerState({ create: true, edit: false });
    };

  const handleCloseDrawers = () =>
    setDrawerState({ create: false, edit: false });

  const handleDelete = (id: number) => () => mutate(id);

  const { data } = useFetchOrderAllocation(
    page,
    limit,
    selectedPosition,
    selectedCompany,
    sorting.order,
    sorting.orderBy,
  );

  const table = createTable<{ Row: { [value: string]: any } }>();
  const columns = useMemo(() => {
    const defaultColumns = [
      table.createDataColumn('companyName', {
        id: 'companyName',
        cell: info => info.value,
        header: () => 'Company',
      }),
      table.createDataColumn('positionName', {
        id: 'positionName',
        cell: info => info.value,
        header: () => 'Position',
      }),
      table.createDataColumn('allocation', {
        id: 'allocation',
        cell: info => info.value,
        header: () => 'Allocation Order',
      }),
      table.createDataColumn('actions', {
        id: 'actions',
        cell: info => info.value,
        header: () => ' ',
      }),
    ];

    return table.createColumns(defaultColumns);
  }, [data]);

  const tableData = useMemo(() => {
    const toReturn = data?.items?.map(
      (orderAllocationItem: OrderAllocationListItem) => ({
        allocation: (
          <AllocationRow>
            {orderAllocationItem.allocation.map((allocationItem, index) => (
              <AllocationAgency key={allocationItem.agencyId}>
                <AllocationAgencyContent>
                  <div className="index"># {index + 1}</div>
                  <div className="allocationInfo">
                    <div className="agencyName">
                      {allocationItem.agencyName}
                    </div>
                    <div className="quantity">
                      quantity: {allocationItem.fulfillment_quantity}
                    </div>
                  </div>
                </AllocationAgencyContent>
              </AllocationAgency>
            ))}
            {Boolean(orderAllocationItem.allRemainingSlots) && (
              <AllocationAgency>
                <AllocationAgencyContent>
                  <div className="index">
                    # {orderAllocationItem.allocation.length + 1}
                  </div>
                  <div className="allocationInfo">
                    <div className="agencyName">
                      {orderAllocationItem.allRemainingSlots?.name}
                    </div>
                    <Tag theme="critical" uiPresets={{ uiSize: 'smaller' }}>
                      All Remaining Slots
                    </Tag>
                  </div>
                </AllocationAgencyContent>
              </AllocationAgency>
            )}
          </AllocationRow>
        ),
        companyName: orderAllocationItem.companyName,
        positionName: orderAllocationItem.positionName,
        actions: (
          <ActionsHolder>
            <Button
              onClick={handleOpenEditDrawer(
                orderAllocationItem.companyId,
                orderAllocationItem.positionId,
                orderAllocationItem.companyName,
                Number(orderAllocationItem.allRemainingSlots?.companyId) ||
                  null,
              )}
            >
              Edit
            </Button>
            <Button
              onClick={handleDelete(orderAllocationItem.id)}
              actionType="critical"
            >
              <Trash />
            </Button>
          </ActionsHolder>
        ),
      }),
    );
    return toReturn;
  }, [data]);

  const instance = useTableInstance(table, {
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModelSync(),
  });

  const renderSortIcon = (fieldName: string) => {
    if (fieldName === sorting.orderBy) {
      return sorting.order === Order.DESC ? <ChevronDown /> : <ChevronUp />;
    }
    return null;
  };

  const headersToBeExcludedFromSorting = ['allocation', 'actions'];

  const showTable = data && data?.items?.length > 0;

  const renderTablePagination = (tableMetadata?: ApiPaginationMeta) => {
    if (tableMetadata && tableMetadata.itemCount) {
      const { itemCount, currentPage, totalPages } = tableMetadata;

      return (
        <Pagination
          pageNumber={currentPage}
          totalPages={Number(totalPages)}
          limit={itemCount}
          onLimitChange={handlePageLimitChange}
          onPageNumberChange={handlePageNumberChange}
        />
      );
    }

    return undefined;
  };

  return (
    <Layout>
      <Header>Order Allocation</Header>
      <ButtonsHolder>
        <ActionsHolder>
          <Button
            sizeUI={{ minW: 101 }}
            iconLeft={<Plus />}
            onClick={handleOpenCreateDrawer}
          >
            New Rule
          </Button>
        </ActionsHolder>

        <ActionsHolder>
          <AutoCompleteInput
            inputStyle="uiAlt"
            name="company"
            placeholder="Company"
            onSelected={handleSelectCompany}
            inputValue={companySearchValue}
            onInputValueChange={handleCompanyValueChange}
            items={
              autoCompleteCompanies?.items?.map((company: any) => ({
                id: company.id,
                value: company.name,
              })) ?? []
            }
            loading={companyAutocompleteLoading}
          />
          {/* 
          // @ts-ignore // weird type from tempo i think. i know this works fine */}
          <SelectInput onChange={handleSelectPosition}>
            <option value="all">Show All</option>
            {positions?.map((position: PositionDTO) => (
              <option key={position.id} value={position.id}>
                {position.name}
              </option>
            ))}
          </SelectInput>
        </ActionsHolder>
      </ButtonsHolder>

      {showTable && (
        <Table {...instance.getTableProps()}>
          <thead>
            {instance.getHeaderGroups().map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(header => (
                  <th {...header.getHeaderProps()}>
                    {!(
                      headersToBeExcludedFromSorting.includes(header.id) ||
                      header.isPlaceholder
                    ) ? (
                      <SortingArea
                        onClick={toggleSorting(header.id as keyof TableData)}
                      >
                        {header.isPlaceholder ? null : header.renderHeader()}
                        {renderSortIcon(header.id)}
                      </SortingArea>
                    ) : (
                      header.renderHeader()
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...instance.getTableBodyProps()}>
            {instance.getRowModel().rows.map(row => (
              <tr {...row.getRowProps()}>
                {row.getVisibleCells().map(cell => (
                  <td role="presentation" {...cell.getCellProps()}>
                    {cell.renderCell()}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {showTable && renderTablePagination(data?.meta)}

      <SetupOrderAllocationDrawer
        companyId={editCompany}
        companyName={selectedCompanyName}
        remainingSlotsTo={editAllRemainingSlots}
        positionId={editPosition}
        onClose={handleCloseDrawers}
        isOpen={drawerState.create}
      />
    </Layout>
  );
};
