import {
  useCallback,
  useEffect,
  useState,
  createContext,
  useContext,
} from 'react';
import styled from 'styled-components/macro';
import { Toast as BaseToast } from '@nowsta/ux-common-components';

interface ToastData {
  message: string;
  theme: ToastTheme;
}

type ToastTheme = 'default' | 'active' | 'alt' | 'notice' | 'critical';

export type ToastContextState = {
  addToast: (value: ToastData) => void;
};

interface ProviderProps {
  children: JSX.Element;
}

export const ToastContext = createContext<ToastContextState>(
  {} as ToastContextState,
);

const toastDisplayTime = 3000;

const ToastWrapper = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 12;
`;

const Toast = styled(BaseToast)`
  box-shadow: 9px 6px 35px -12px rgba(0, 0, 0, 0.75);
`;

const ToastHolder = styled.div`
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  z-index: 99999;
`;

export const ToastContextProvider = ({ children }: ProviderProps) => {
  const [toasts, setToasts] = useState<ToastData[]>([]);

  useEffect(() => {
    let timer: NodeJS.Timeout | null;
    if (toasts.length > 0) {
      timer = setTimeout(
        () => setToasts(prevState => prevState.slice(1)),
        toastDisplayTime,
      );
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [toasts]);

  const addToast = useCallback(
    (toast: ToastData) => setToasts(prevState => [...prevState, toast]),
    [setToasts],
  );

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ToastContext.Provider value={{ addToast }}>
      {children}
      <ToastWrapper>
        {toasts.map((toast, i) => (
          // FIXME: not to use index, figure out if we need some sort of utility to create random ids
          // eslint-disable-next-line react/no-array-index-key
          <ToastHolder key={i}>
            <Toast theme={toast.theme}>{toast.message}</Toast>
          </ToastHolder>
        ))}
      </ToastWrapper>
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  return { ...context };
};
