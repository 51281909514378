import { SelectInputProps } from '@nowsta/ux-common-components';
import { Control, Controller } from 'react-hook-form';

import { SelectInput } from './styled';

interface Props extends SelectInputProps {
  control: Control<any, any>;
  name: string;
  label: string;
  children: React.ReactNode;
}

export const ControlledSelectInput = ({
  control,
  name,
  label,
  children,
  ...props
}: Props) => (
  <Controller
    control={control}
    name={name}
    rules={{ required: true }}
    render={({
      field: { onChange, onBlur, value, ref },
      fieldState: { error },
    }) => (
      <SelectInput
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        label={label}
        isRequired={Boolean(error)}
        message={error ? 'This field is required ' : undefined}
        ref={ref}
        {...props}
      >
        {children}
      </SelectInput>
    )}
  />
);
