export const endpoints = {
  login: '/auth/login',
  positions: '/positions',
  agencies: '/agencies',
  wfmUnallocatedCompaniesForAgencies: '/agencies/wfm-unallocated-companies',
  wfmUnallocatedCompaniesForCompanies: '/companies/wfm-unallocated-companies',
  invoicesHistory: '/invoices-history',
  invoices: '/invoices',
  receivables: '/receivables',
  clientInvoices: '/invoices/clients',
  invoiceErrors: '/invoice-errors',
  paymentErrors: '/payment-errors',
  payments: '/payments',
  rates: '/rates',
  agencyFindWidthNoRateForCompany:
    '/agencies/findWidthNoRateForCompany/:companyId',
  agencyFindRateForCompanyAndPosition:
    '/agencies/findWidthRateForCompanyAndPosition/:companyId/:positionId',
  agencyFindRateForCompany: '/agencies/findWidthRateForCompany/:companyId',
  companies: '/companies',
  companyFindWidthNoRateForAgency:
    '/companies/findWithNoRateForAgency/:agencyId',
  findRatesByCompany: '/rates/findPositionsByCompany',
  findRatesByAgency: '/rates/findPositionsByAgency',
  findRatesByCompanyAndAgency: '/rates/findByCompanyAndAgency',
  findCompanyDefaultRates: '/rates/findCompanyDefaultRates',
  orderAllocations: 'order-allocations',
  punchcards: '/invoice-items',
  createInvoice: '/invoice-items/create',
  agencyReceivables: '/receivables/agencies',
  eventsWithDepartments: '/invoice-items/getEventsAndDepartments',
  positionsWithDepartments: '/invoice-items/getPositionsAndDepartments',
};
