import { color } from '@nowsta/ux-common-components';
import styled from 'styled-components';

interface RowProps {
  spaceBetween?: boolean;
  gap?: number;
}

export const Row = styled.div<RowProps>`
  display: flex;
  align-items: center;
  ${props => props.gap && `gap: ${props.gap}px;`}
  ${props => props.spaceBetween && `justify-content: space-between;`}
`;

export const AgencyNameHolder = styled.div`
  width: 200px;
`;

export const Delete = styled.button`
  width: 40px;
  height: 32px;
  background: ${color.red};
  border-radius: 5px;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  cursor: pointer;
`;

export const Up = styled.button`
  width: 40px;
  height: 32px;
  background: ${color.green};
  border-radius: 5px;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  cursor: pointer;

  svg > path {
    fill: white;
  }
`;

export const Down = styled.button`
  width: 40px;
  height: 32px;
  background: ${color.blueMain};
  border-radius: 5px;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  cursor: pointer;

  svg > path {
    fill: white;
  }
`;
