import {
  useForm,
  SubmitHandler,
  useWatch,
  FormProvider,
} from 'react-hook-form';
import { useEffect } from 'react';

import Drawer from 'features/common/components/Drawer';
import { DrawerTitle } from 'features/common/components/Drawer/styled';
import { ControlledTextInput } from 'features/common/components/ControlledTextInput';
import { CreateAgencyDTO } from 'features/agencies/dto';
import { useFetchWfmUnallocatedCompaniesForAgencies } from 'features/agencies/hooks/queries';
import { Loader } from 'features/common/components/Loader';
import { SelectInput } from 'features/common/components/SelectInput';

import { Form } from './styled';

interface Props {
  onClose: () => void;
  onBack: () => void;
  isOpen: boolean;
  onSuccess: (data: CreateAgencyDTO) => void;
}

export const CreateAgencyDrawer = (props: Props) => {
  const methods = useForm<CreateAgencyDTO>();

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = methods;

  const { onSuccess, onClose, onBack, isOpen } = props;

  const companyIdValue = useWatch({ name: 'companyId', control });

  const {
    data: companyList,
    isLoading,
    refetch,
    isFetching,
  } = useFetchWfmUnallocatedCompaniesForAgencies();

  const onSubmit: SubmitHandler<CreateAgencyDTO> = data => {
    reset();
    if (!data.companyId || data.companyId === 'null') {
      data.companyId = null;
    }
    onSuccess(data);
  };
  const dispatchForm = () => handleSubmit(onSubmit)();

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleBack = () => {
    reset();
    onBack();
  };

  useEffect(() => {
    const selectedCompany = companyList?.data.find(
      c => c.id.toString() === companyIdValue,
    );
    if (selectedCompany) {
      setValue('name', selectedCompany.name);
    }
  }, [companyIdValue]);

  useEffect(() => {
    if (isOpen) refetch();
  }, [isOpen]);

  return (
    <Drawer
      title="Create a New Agency"
      onClose={handleClose}
      onBack={handleBack}
      onContinue={dispatchForm}
      continueText="Create Agency"
      isOpen={isOpen}
    >
      <DrawerTitle>New Agency Details</DrawerTitle>

      {isLoading || isFetching ? (
        <Loader />
      ) : (
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <SelectInput
              label="WFM Company"
              fieldId="companyId"
              list={companyList?.data}
              error={Boolean(errors.companyId)}
              required={false}
            />
            <ControlledTextInput
              control={control}
              label="Agency name"
              name="name"
              placeholder="e.g ‘Big Agency Inc’"
            />
            <ControlledTextInput
              control={control}
              spaceUI={{ mT: 16 }}
              maxLength={5}
              label="Agency abbreviation"
              name="abbreviation"
              placeholder="e.g ‘BAI’"
            />
            <ControlledTextInput
              control={control}
              spaceUI={{ mT: 16 }}
              label="Foreground Color"
              name="foregroundColor"
              type="color"
              placeholder=""
              required={false}
            />
            <ControlledTextInput
              control={control}
              spaceUI={{ mT: 16 }}
              label="Background Color"
              name="backgroundColor"
              type="color"
              placeholder=""
              required={false}
            />
            <ControlledTextInput
              control={control}
              spaceUI={{ mT: 16 }}
              label="Notification email"
              name="notificationEmail"
              placeholder="name@domain.com"
              required={false}
            />
          </Form>
        </FormProvider>
      )}
    </Drawer>
  );
};

export default CreateAgencyDrawer;
