import { useState, useMemo, useCallback } from 'react';
import {
  Button,
  Plus,
  MagnifyingGlass,
  TextInput,
  Tag,
  BusyIndicator,
  Clock,
  ChevronDown,
  ChevronUp,
} from '@nowsta/ux-common-components';
import {
  createTable,
  getCoreRowModelSync,
  useTableInstance,
} from '@tanstack/react-table';

import { H2 } from 'features/common/components/headings';
import Layout from 'features/common/components/Layout';
import { useURLQueryParams } from 'features/common/hooks/useURLQueryParams';
import { nullifyFalsy } from 'features/common/utils';
import { getFormattedDate } from 'features/rates/components/RatesListView/utils';
import {
  DeparmentsHolder,
  GeneratingCSV,
  OnQueue,
  Table,
} from 'features/invoices-history/components/InvoicesListView/styled';
import { InvoiceReportStatus } from 'features/invoices-history/types';
import { ApiPaginationMeta, Order, SortingState } from 'features/common/types';
import { Pagination } from 'features/common/components/Pagination';
import { AutoCompleteInput } from 'features/common/components/AutoCompleteInput';
import { YearMonthPicker } from 'features/common/components/YearMonthPicker';
import { useFetchCompanies } from 'features/companies/hooks/queries';
import { useFetchAgencies } from 'features/agencies/hooks/queries';
import { downloadPaymentCSV } from 'services/payments';

import { CreatePaymentReportDrawer } from '../CreatePaymentReportDrawer';
import { PaymentsListEmptyState } from '../PaymentsListEmptyState';
import { useFetchPayments } from '../../hooks/queries';
import { PaymentListItem } from '../../types';

import {
  ActionsHolder,
  ButtonsHolder,
  Header,
  Row,
  SortingArea,
} from './styled';
import { PaymentErrors } from '../PaymentErrors';

const parseDepartments = (str: string) => {
  try {
    const arrayOfDeparments = JSON.parse(str);
    return (
      <DeparmentsHolder>
        {arrayOfDeparments.map((item: any) => (
          <Tag key={item.label} uiPresets={{ uiSize: 'smaller' }}>
            {item.label}
          </Tag>
        ))}
      </DeparmentsHolder>
    );
  } catch (e) {
    return <Tag uiPresets={{ uiSize: 'smaller' }}>All Departments</Tag>;
  }
};

const contentByInvoiceStatus = (
  id: number,
  handleErrorDetails: (id: number) => void,
) => ({
  [InvoiceReportStatus.SUCCESS]: (
    <Button
      uiPresets={{ uiSize: 'small' }}
      onClick={() => downloadPaymentCSV(Number(id))}
    >
      Download CSV
    </Button>
  ),
  [InvoiceReportStatus.ERROR]: (
    <Button
      actionType="critical"
      uiPresets={{ uiSize: 'small' }}
      onClick={() => handleErrorDetails(id)}
    >
      Error Details
    </Button>
  ),
  [InvoiceReportStatus.IN_PROGRESS]: (
    <GeneratingCSV>
      <BusyIndicator />
      Generating .CSV
    </GeneratingCSV>
  ),
  [InvoiceReportStatus.PENDING]: (
    <OnQueue>
      <Clock />
      On Queue...
    </OnQueue>
  ),
});

interface TableData {
  id: number;
  requester: string;
  agencyName: string;
  dateRange: string;
  companyName: string;
  departments: string;
  actions: string;
}

interface Error {
  paymentId: null | number;
  visible: boolean;
}

export const PaymentsListView = () => {
  const [isDrawerOpen, setIsModalOpen] = useState(false);
  const [date, setDate] = useState<Date | null>(null);
  const [selectedAgency, setSelectedAgency] = useState<number>();
  const [agencySearchValue, setAgencySearchValue] = useState('');
  const [selectedCompany, setSelectedCompany] = useState<number>();
  const [companySearchValue, setCompanySearchValue] = useState('');

  const [sorting, setSorting] = useState<SortingState<TableData>>({
    orderBy: 'id',
    order: Order.DESC,
  });

  const [viewErrorsData, setViewErrors] = useState<Error>({
    paymentId: null,
    visible: false,
  });

  const handleCloseErrorsModal = useCallback(
    () =>
      setViewErrors(prevState => ({
        ...prevState,
        visible: false,
      })),
    [],
  );

  const toggleSorting = (fieldName: keyof TableData) => () =>
    setSorting(prevState => {
      if (fieldName !== prevState.orderBy) {
        return {
          orderBy: fieldName,
          order: Order.DESC,
        };
      }
      return {
        ...prevState,
        order: prevState.order === Order.ASC ? Order.DESC : Order.ASC,
      };
    });

  const handleViewErrors = useCallback((paymentId: number) => {
    setViewErrors({
      paymentId,
      visible: true,
    });
  }, []);

  const renderSortIcon = (fieldName: string) => {
    if (fieldName === sorting.orderBy) {
      return sorting.order === Order.DESC ? <ChevronDown /> : <ChevronUp />;
    }
    return null;
  };
  const handleCloseDrawer = () => setIsModalOpen(false);
  const handleOpenDrawer = () => setIsModalOpen(true);

  const { data: autoCompleteAgencies, isLoading: agencyAutocompleteLoading } =
    useFetchAgencies(1, 10, agencySearchValue);

  const handleSelectAgency = (agencyId: number | string) =>
    setSelectedAgency(+agencyId);
  const handleAgencyValueChange = (value: string) =>
    setAgencySearchValue(value);

  const { data: autoCompleteCompanies, isLoading: companyAutocompleteLoading } =
    useFetchCompanies(1, 10, companySearchValue);

  const handleSelectCompany = (companyId: number | string) =>
    setSelectedCompany(+companyId);
  const handleCompanyValueChange = (value: string) =>
    setCompanySearchValue(value);

  const { urlParams, setURLParams } = useURLQueryParams();
  const page = urlParams.get('page') ?? '1';
  const limit = urlParams.get('limit') ?? '10';

  const handlePageLimitChange = useCallback(
    (newLimit: number) => {
      setURLParams({ limit: String(newLimit) });
    },
    [setURLParams],
  );

  const handlePageNumberChange = useCallback(
    (newPage: number) => {
      setURLParams({ page: String(newPage) });
    },
    [setURLParams],
  );

  const [searchValue] = useState<string>('');

  const { data } = useFetchPayments(
    page,
    limit,
    nullifyFalsy(searchValue),
    selectedAgency,
    selectedCompany,
    date,
    sorting.order,
    sorting.orderBy,
  );

  const table = createTable<{ Row: { [value: string]: any } }>();
  const columns = useMemo(() => {
    const defaultColumns = [
      table.createDataColumn('id', {
        id: 'id',
        cell: info => info.value,
        header: () => 'Id',
      }),
      table.createDataColumn('requester', {
        id: 'requester',
        cell: info => info.value,
        header: () => 'Payable Requester',
      }),
      table.createDataColumn('companyName', {
        id: 'companyName',
        cell: info => info.value,
        header: () => 'Company Name',
      }),
      table.createDataColumn('agencyName', {
        id: 'agencyName',
        cell: info => info.value,
        header: () => 'Agency Name',
      }),
      table.createDataColumn('departments', {
        id: 'departments',
        cell: info => info.value,
        header: () => 'Department(s)',
      }),
      table.createDataColumn('dateRange', {
        id: 'dateRange',
        cell: info => info.value,
        header: () => 'Date Range',
      }),
      table.createDataColumn('actions', {
        id: 'actions',
        cell: info => info.value,
        header: () => ' ',
      }),
    ];

    return table.createColumns(defaultColumns);
  }, [data]);

  const tableData = useMemo(() => {
    const toReturn = data?.items?.map((payment: PaymentListItem) => ({
      id: payment.id,
      requester: payment.createdByName,
      agencyName: payment?.agencyName,
      dateRange: `${getFormattedDate(
        new Date(payment.startDate),
      )} - ${getFormattedDate(new Date(payment.endDate))}`,
      companyName: payment.companyName ? payment.companyName : 'Not set',
      departments: parseDepartments(payment.deparments),
      actions: contentByInvoiceStatus(payment.id, (id: number) =>
        handleViewErrors(id),
      )[payment.status],
    }));
    return toReturn;
  }, [data]);

  const instance = useTableInstance(table, {
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModelSync(),
  });

  const headersToBeExcludedFromSorting = ['departments', 'actions'];

  const showTable = data && data?.items?.length > 0;

  const renderTablePagination = (tableMetadata?: ApiPaginationMeta) => {
    if (tableMetadata && tableMetadata.itemCount) {
      const { itemCount, currentPage, totalPages } = tableMetadata;

      return (
        <Pagination
          pageNumber={currentPage}
          totalPages={Number(totalPages)}
          limit={itemCount}
          onLimitChange={handlePageLimitChange}
          onPageNumberChange={handlePageNumberChange}
        />
      );
    }

    return undefined;
  };

  return (
    <Layout>
      <Header>Labor Market Payables Sheet</Header>
      <ButtonsHolder>
        <ActionsHolder>
          <Button
            sizeUI={{ minW: 150 }}
            iconLeft={<Plus />}
            actionType="primary"
            onClick={handleOpenDrawer}
          >
            New .CSV Request
          </Button>
        </ActionsHolder>

        <ActionsHolder>
          <AutoCompleteInput
            inputStyle="uiAlt"
            name="company"
            placeholder="Company"
            onSelected={handleSelectCompany}
            inputValue={companySearchValue}
            onInputValueChange={handleCompanyValueChange}
            items={
              autoCompleteCompanies?.items?.map((company: any) => ({
                id: company.id,
                value: company.name,
              })) ?? []
            }
            loading={companyAutocompleteLoading}
          />

          <AutoCompleteInput
            inputStyle="uiAlt"
            name="agency"
            placeholder="Agency"
            onSelected={handleSelectAgency}
            inputValue={agencySearchValue}
            onInputValueChange={handleAgencyValueChange}
            items={
              autoCompleteAgencies?.data?.items?.map((agency: any) => ({
                id: agency.id,
                value: agency.name,
              })) || []
            }
            loading={agencyAutocompleteLoading}
          />
          <YearMonthPicker
            placeholder="Month/Year"
            value={date}
            onChange={setDate}
          />
        </ActionsHolder>
      </ButtonsHolder>

      <Row>
        <H2>
          {showTable
            ? `Showing ${data?.meta.itemCount} - ${data?.meta.totalItems} Reports`
            : 'No Reports'}
        </H2>
        <TextInput
          inputStyle="uiAlt"
          placeholder="Search"
          iconLeft={<MagnifyingGlass />}
        />
      </Row>
      {showTable ? (
        <Table {...instance.getTableProps()}>
          <thead>
            {instance.getHeaderGroups().map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(header => (
                  <th {...header.getHeaderProps()}>
                    {!(
                      headersToBeExcludedFromSorting.includes(header.id) ||
                      header.isPlaceholder
                    ) ? (
                      <SortingArea
                        onClick={toggleSorting(header.id as keyof TableData)}
                      >
                        {header.isPlaceholder ? null : header.renderHeader()}
                        {renderSortIcon(header.id)}
                      </SortingArea>
                    ) : (
                      header.renderHeader()
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...instance.getTableBodyProps()}>
            {instance.getRowModel().rows.map(row => (
              <tr {...row.getRowProps()}>
                {row.getVisibleCells().map(cell => (
                  <td role="presentation" {...cell.getCellProps()}>
                    {cell.renderCell()}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <PaymentsListEmptyState handleOpenDrawer={handleOpenDrawer} />
      )}

      {showTable && renderTablePagination(data?.meta)}

      <PaymentErrors
        paymentId={viewErrorsData.paymentId}
        visible={viewErrorsData.visible}
        handleClose={handleCloseErrorsModal}
      />

      <CreatePaymentReportDrawer
        isOpen={isDrawerOpen}
        onClose={handleCloseDrawer}
      />
    </Layout>
  );
};
