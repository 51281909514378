import { useMutation } from 'react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { DrawerProps, Drawer } from '@nowsta/tempo-ds';

import { InvoiceItem } from 'features/invoices/types';

import { FormValues } from 'features/common/components/InvoiceDrawerBody/types';

import EntityDrawerBody from 'features/common/components/InvoiceDrawerBody';
import DrawerFooter from 'features/common/components/InvoiceDrawerFooter';
import { useToast } from 'features/common/contexts/ToastContext';

import { updatePunchcardsInvoice, updateReceivable } from 'services';
import { ReceivableItem } from 'features/receivables/types';

interface Props extends Omit<DrawerProps, 'body' | 'title'> {
  entity: InvoiceItem | ReceivableItem;
  onSuccess: () => void;
  isInvoice?: boolean;
}

const EditEntityDrawer = ({
  isInvoice = true,
  onClose,
  onSuccess,
  entity,
  ...rest
}: Props) => {
  const entityType = isInvoice ? 'Invoice' : 'Receivable';
  const { addToast } = useToast();
  const mutation = useMutation(
    isInvoice ? updatePunchcardsInvoice : updateReceivable,
    {
      onError: () => {
        addToast({
          message: 'Something went wrong',
          theme: 'critical',
        });
      },
      onSuccess: () => {
        addToast({
          message: `${entityType} has been updated successfully`,
          theme: 'active',
        });
        onSuccess();
        onClose();
      },
    },
  );

  const handleUpdateEntity = (data: FormValues) => {
    mutation.mutate({
      id: String(entity.id),
      status: data.status,
    });
  };

  const methods = useForm<FormValues>({
    defaultValues: {
      companyId: entity.company.companyId,
      companyName: entity.company.name,
      invoiceNumber: entity.invoiceNumber,
      contractTerms: entity.term,
      status: entity.status,
    },

    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });
  return (
    <FormProvider {...methods}>
      <Drawer
        {...rest}
        title={`Edit ${entityType} - ${entity.invoiceNumber}`}
        body={<EntityDrawerBody isEdit isInvoice={isInvoice} />}
        footer={
          <DrawerFooter
            onCancel={onClose}
            onSubmit={methods.handleSubmit(handleUpdateEntity)}
            isLoading={mutation.isLoading}
            confirmText="Update"
          />
        }
        onClose={onClose}
      />
    </FormProvider>
  );
};

export default EditEntityDrawer;
