import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { ToastContextProvider } from 'features/common/contexts/ToastContext';

const queryClient = new QueryClient();

interface Props {
  children: JSX.Element;
}

export const Providers = ({ children }: Props) => (
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <ToastContextProvider>{children}</ToastContextProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </BrowserRouter>
);

export default Providers;
