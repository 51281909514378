import styled from 'styled-components/macro';
import {
  rem,
  Button,
  Plus,
  textSize,
  textWeight,
  Company,
} from '@nowsta/ux-common-components';
import { color } from 'features/common/constants';

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.02),
    0px 4px 8px -2px rgba(0, 0, 0, 0.02), 0px 16px 32px -8px rgba(0, 0, 0, 0.02);
  border-radius: ${rem(8)};
  display: flex;
  flex-direction: column;
  padding: ${rem(40)};
  align-items: center;
  isolation: isolate;
  margin-top: ${rem(24)};
`;

const Title = styled.h4`
  ${textSize.f24};
  color: ${color.slateDarker};
  font-weight: ${textWeight.semiBold};
  font-family: 'Silka';
  font-style: normal;
  margin: ${rem(14)} 0 0;
`;

const Text = styled.span`
  ${textSize.f14};
  color: ${color.slateDark};
  font-family: 'Silka';
  font-style: normal;
  font-weight: ${textWeight.regular};
  text-align: center;
  max-width: 360px;
  margin: ${rem(4)} 0 ${rem(16)} 0;
`;

const IconHolder = styled.div`
  width: 72px;
  height: 72px;
  position: relative;

  svg {
    width: 58px;
    height: 59px;
    left: -4px;
    bottom: -12px;
    position: relative;
  }
`;

const GradientCircle = styled.div`
  position: absolute;
  background: linear-gradient(
    90deg,
    #269fbd 0%,
    #1d9aca 14.29%,
    #3494d3 28.57%,
    #568cd8 42.86%,
    #7981d6 57.14%,
    #9973cd 71.43%,
    #b663bb 85.71%,
    #cc52a3 100%
  );
  opacity: 0.12;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
`;

export const AgencyListEmptyState = ({
  handleOpenDrawer,
}: {
  handleOpenDrawer: () => void;
}) => (
  <Wrapper>
    <IconHolder>
      <GradientCircle />
      <Company />
    </IconHolder>
    <Title>¯\_(ツ)_/¯</Title>
    <Text>It looks like you haven’t created any agencies yet!</Text>
    <Button
      sizeUI={{ minW: 240 }}
      iconLeft={<Plus />}
      actionType="primary"
      onClick={handleOpenDrawer}
    >
      Create a new Agency
    </Button>
  </Wrapper>
);
