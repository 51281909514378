import { rem } from '@nowsta/ux-common-components';
import styled from 'styled-components';

const LoadingHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${rem(8)};
  margin-top: ${rem(50)};
`;

export const Loader = () => (
  <LoadingHolder>
    <div className="lds-roller">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
    <div>Loading</div>
  </LoadingHolder>
);
