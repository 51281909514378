import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  useForm,
  SubmitHandler,
  useWatch,
  FormProvider,
} from 'react-hook-form';
import { SearchInput } from '@nowsta/tempo-ds';
import { Button, Plus } from '@nowsta/ux-common-components';

import Drawer from 'features/common/components/Drawer';
import { DrawerTitle } from 'features/common/components/Drawer/styled';
import { CreateCompanyDTO } from 'features/companies/dto';
import { RatesConfig } from 'features/rates/components/RatesConfig';
import { PositionDTO } from 'features/positions/dto';

import {
  Separator,
  Text,
  ActionsRowHolder,
  FlexTableHeader,
} from 'features/common/components/Styled';
import { ControlledTextInput } from 'features/common/components/ControlledTextInput';
import { CompanyDefaultRates, RateBaseData } from 'features/rates/interfaces';
import { CreateCompanyForm } from 'features/companies/interfaces';
import {
  FETCH_POSITIONS_QUERY,
  useFetchPositions,
} from 'features/positions/hooks/queries';
import { Loader } from 'features/common/components/Loader';
import { SelectInput } from 'features/common/components/SelectInput';

import { useFetchWfmUnallocatedCompaniesForCompanies } from '../../hooks/queries';
import { Form } from './styled';
import { timezones } from '../../utils';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onBack: () => void;
  onSuccess: (data: CreateCompanyDTO) => void;
  onNewPositionClick: () => void;
}

export const CreateCompanyDrawer = (props: Props) => {
  const { isOpen, onSuccess, onBack, onClose, onNewPositionClick } = props;
  const [positions, setPositions] = useState<PositionDTO[]>([]);
  const [positionFilterValue, setPositionFilterValue] = useState<string>('');
  const [enabledPositions, setEnabledPositions] = useState<number[]>([]);
  const [defaultRates, setDefaultRates] = useState<CompanyDefaultRates[]>([]);

  const queryClient = useQueryClient();

  const {
    data: companyList,
    isLoading,
    refetch,
    isFetching,
  } = useFetchWfmUnallocatedCompaniesForCompanies();

  useEffect(() => {
    if (isOpen) refetch();
  }, [isOpen]);
  const methods = useForm<CreateCompanyForm>();
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = methods;
  const companyIdValue = useWatch({ name: 'companyId', control });
  const onSubmit: SubmitHandler<CreateCompanyForm> = data => {
    const timezone = timezones.find(
      tz => tz.id === parseInt(data.timeZone, 10),
    );

    if (!timezone) {
      console.error('Invalid timezone ID:', data.timeZone);
      return;
    }

    const createRequestObj = {
      ...data,
      timeZone: timezone.name,
      enabledPositions,
      defaultRates,
    };
    onSuccess(createRequestObj);
    reset();
    setEnabledPositions([]);
    setDefaultRates([]);
  };

  const dispatchForm = () => handleSubmit(onSubmit)();

  const { data: { items } = {} } = useFetchPositions({ page: 1, limit: 0 });

  useEffect(() => {
    if (items) {
      if (positionFilterValue) {
        setPositions(
          items.filter((position: PositionDTO) =>
            position.name
              .toLowerCase()
              .includes(positionFilterValue.toLowerCase()),
          ),
        );
      } else {
        setPositions(items);
      }
    }
  }, [items, positionFilterValue]);

  useEffect(() => {
    if (isOpen) queryClient.invalidateQueries([FETCH_POSITIONS_QUERY]);
  }, [isOpen, queryClient]);

  useEffect(() => {
    const selectedCompany = companyList?.data.find(
      c => c.id.toString() === companyIdValue,
    );
    if (selectedCompany) {
      setValue('name', selectedCompany.name);
    }
  }, [companyIdValue]);

  const handleToggle = (positionId: number) => () =>
    setEnabledPositions(prevState => {
      const newPositions = [...prevState];
      if (newPositions.includes(positionId)) {
        const toReturn = newPositions.filter(
          position => position !== positionId,
        );
        return toReturn;
      }
      newPositions.push(positionId);

      return newPositions;
    });

  const handleRateChange = useCallback(
    (changedDefaultRates: RateBaseData[], positionId: number) =>
      setDefaultRates(prevState => {
        const newRates = [...prevState];
        const ratesObj: CompanyDefaultRates = {
          positionId,
          defaultRates: changedDefaultRates,
        };
        const index = newRates.findIndex(
          toFind => toFind.positionId === positionId,
        );
        if (index >= 0) {
          newRates[index] = ratesObj;
        } else {
          newRates.push(ratesObj);
        }
        return newRates;
      }),
    [],
  );

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleBack = () => {
    reset();
    onBack();
  };
  return (
    <Drawer
      title="Create a New Company"
      onClose={handleClose}
      onBack={handleBack}
      onContinue={dispatchForm}
      continueText="Create Company"
      isOpen={isOpen}
    >
      <DrawerTitle>New Company Details</DrawerTitle>
      {isLoading || isFetching ? (
        <Loader />
      ) : (
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <SelectInput
              label="WFM Company"
              fieldId="companyId"
              list={companyList?.data}
              error={Boolean(errors.companyId)}
            />
            <ControlledTextInput
              control={control}
              name="name"
              label="Company name"
              placeholder="e.g ‘Big Company Inc’"
            />
            <SelectInput
              label="Company Timezone"
              fieldId="timeZone"
              list={timezones.map(tz => ({ id: tz.id, name: tz.name }))}
              error={Boolean(errors.timeZone)}
            />
            <Separator />
            <DrawerTitle>Positions for this Company</DrawerTitle>
            <Text>
              Choose the positions this company will use and set default bill
              rates.
            </Text>
            <ActionsRowHolder>
              <SearchInput
                onChange={setPositionFilterValue}
                placeholder="Search positions"
                value={positionFilterValue}
              />
              <Button iconLeft={<Plus />} onClick={onNewPositionClick}>
                {' '}
                Add New Position
              </Button>
            </ActionsRowHolder>
            <FlexTableHeader>
              <span>Position Name</span>
              <span>Enabled Status</span>
            </FlexTableHeader>
            {positions.map(position => (
              <RatesConfig
                key={position.id}
                positionName={position.name}
                enabled={enabledPositions.includes(position.id)}
                onToggle={handleToggle(position.id)}
                positionId={position.id}
                onChange={handleRateChange}
              />
            ))}
          </Form>
        </FormProvider>
      )}
    </Drawer>
  );
};

export default CreateCompanyDrawer;
