import styled from 'styled-components/macro';
import { rem, Button, Plus, Company } from '@nowsta/ux-common-components';
import { RatesListViewType } from '../RatesListView/types';

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.02),
    0px 4px 8px -2px rgba(0, 0, 0, 0.02), 0px 16px 32px -8px rgba(0, 0, 0, 0.02);
  border-radius: ${rem(8)};
  display: flex;
  flex-direction: column;
  padding: ${rem(40)};
  align-items: center;
  isolation: isolate;
  margin-top: ${rem(24)};
`;

const Title = styled.h4`
  font-family: 'Silka';
  font-style: normal;
  font-weight: 600;
  font-size: ${rem(18)};
  line-height: ${rem(22)};
  color: #2d383d;
`;

const Text = styled.span`
  max-width: 360px;
  font-family: 'Silka';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #4a5d66;
  margin: ${rem(4)} 0 ${rem(16)} 0;
`;

const IconHolder = styled.div`
  width: 72px;
  height: 72px;
  position: relative;
  margin-bottom: 4px;

  svg {
    width: 58px;
    height: 59px;
    left: -13px;
    top: -9px;
    position: relative;
  }
`;

const GradientCircle = styled.div`
  position: absolute;
  background: linear-gradient(
    90deg,
    #269fbd 0%,
    #1d9aca 14.29%,
    #3494d3 28.57%,
    #568cd8 42.86%,
    #7981d6 57.14%,
    #9973cd 71.43%,
    #b663bb 85.71%,
    #cc52a3 100%
  );
  opacity: 0.12;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
`;

const CloseButton = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  left: 34px;
  top: 33px;
  background: #cc52a3;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.08),
    0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 50%;

  &:before {
    content: ' ';
    position: absolute;
    width: 12.25px;
    height: 1.75px;
    top: 9px;
    left: 4px;
    background: white;
    transform: rotate(-45deg);
  }

  &:after {
    content: ' ';
    position: absolute;
    width: 12.25px;
    height: 1.75px;
    top: 9px;
    left: 4px;
    background: white;
    transform: rotate(45deg);
  }
`;

export const RatesEmptyResult = ({
  handleOpenDrawer,
  ratesBy,
}: {
  handleOpenDrawer: () => void;
  ratesBy: RatesListViewType;
}) => (
  <Wrapper>
    <IconHolder>
      <GradientCircle />
      <Company />
      <CloseButton />
    </IconHolder>
    <Title>
      Add {ratesBy === RatesListViewType.byCompany ? 'a Company' : 'an Agency'}{' '}
      to Start
    </Title>
    <Text>
      Are you lonely? Because it looks like you could use some company...
    </Text>
    <Button
      sizeUI={{ minW: 240 }}
      iconLeft={<Plus />}
      actionType="primary"
      onClick={handleOpenDrawer}
    >
      Add Your First{' '}
      {ratesBy === RatesListViewType.byCompany ? 'Company' : 'Agency'}
    </Button>
  </Wrapper>
);
