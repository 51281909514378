import { InvoiceItem } from 'features/invoices/types';
import { PunchcardItem } from 'features/punchcards/types';

export interface ApiPaginationMeta {
  itemCount: number;
  totalItems?: number;
  itemsPerPage: number;
  totalPages?: number;
  currentPage: number;
}

export interface PaginatedData<DataItems> {
  items: DataItems;
  meta: ApiPaginationMeta;
}

export interface TableRow {
  [value: string]: any;
}

export interface Pagination {
  page?: number;
  limit?: number;
}

export enum Order {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface SortingState<Data> {
  orderBy: keyof Data;
  order: Order;
}

export interface GetInvoices {
  items: InvoiceItem[];
  meta: ApiPaginationMeta;
}

export interface GetReceivables {
  items: InvoiceItem[];
  meta: ApiPaginationMeta;
}

export interface GetPunchcards {
  items: PunchcardItem[];
  meta: ApiPaginationMeta;
}

export interface GetCompanies {
  items: CompanyItem[];
  meta: ApiPaginationMeta;
}

export interface CompanyItem {
  id: number;
  name: string;
  companyId: string;
  isArchived: boolean;
}
