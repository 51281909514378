import { Pagination } from 'features/common/types';
import { useQuery } from 'react-query';
import { fetchPositions } from 'services/positions';

export const FETCH_POSITIONS_QUERY = 'FETCH_POSITIONS_QUERY';

export const useFetchPositions = (options: Pagination = {}) =>
  useQuery(
    [FETCH_POSITIONS_QUERY, { ...options }],
    () => fetchPositions(options),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );
