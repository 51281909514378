import { useCallback, useEffect, useState } from 'react';

import { Button, Plus, ChevronRight } from '@nowsta/ux-common-components';
import { SearchInput } from '@nowsta/tempo-ds';
import { useQuery, useQueryClient } from 'react-query';

import Drawer from 'features/common/components/Drawer';
import { DrawerTitle } from 'features/common/components/Drawer/styled';

import {
  ActionsRowHolder,
  FlexTableHeader,
  List,
  ListItem,
  StrongText,
  Text,
} from 'features/common/components/Styled';
import { findWidthNoRateForAgency } from 'services';
import { AgencyListItem } from 'features/agencies/types';
import { PositionDTO } from 'features/positions/dto';
import {
  CompanyDefaultRates,
  CreateRateSetDTO,
  RateBaseData,
} from 'features/rates/interfaces';
import { CompanyList } from 'features/companies/interfaces';
import {
  FETCH_POSITIONS_QUERY,
  useFetchPositions,
} from 'features/positions/hooks/queries';
import { color } from 'features/common/constants';
import { RatesConfig } from '../RatesConfig';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (data: CreateRateSetDTO) => void;
  onNewCompanyClick: () => void;
  onNewPositionClick: () => void;
  // FIXME: define a default value
  // eslint-disable-next-line react/require-default-props
  agencyData?: { id: number; name: string; abbreviation: string };
}

const steps = [
  {
    title: 'Step 1 of 2. Choose a Company',
    message: 'Choose an company to associate with this agency.',
    continueButton: '',
  },
  {
    title: 'Step 2 of 2. Set %agency%’s (%abbreviation%) Rates',
    continueButton: 'Set Rates',
  },
];

export const CreateAgencyRateDrawer = (props: Props) => {
  const queryClient = useQueryClient();
  const {
    agencyData,
    isOpen,
    onClose,
    onNewCompanyClick,
    onNewPositionClick,
    onSuccess,
  } = props;
  const [activeStep, setActiveStep] = useState<number>(0);

  const [selectedCompany, setSelectedCompany] = useState<CompanyList | null>(
    null,
  );
  const [companies, setCompanies] = useState<CompanyList[]>([]);
  const [companyFilterValue, setCompanyFilterValue] = useState<string>('');

  const [positions, setPositions] = useState<PositionDTO[]>([]);
  const [positionFilterValue, setPositionFilterValue] = useState<string>('');

  const [enabledPositions, setEnabledPositions] = useState<number[]>([]);
  const [rates, setRates] = useState<CompanyDefaultRates[]>([]);

  const { data: dataCompanies } = useQuery(
    ['companiesWithNoRateForAgency', agencyData?.id],

    () => findWidthNoRateForAgency(agencyData?.id),
    {
      refetchOnMount: false,
      enabled: Boolean(agencyData?.id),
    },
  );

  const { data: { items: dataPositions } = {} } = useFetchPositions({
    page: 1,
    limit: 0,
  });

  const handleSelectCompany = (agency: CompanyList) => () => {
    setActiveStep(1);
    setSelectedCompany(agency);
  };

  const handleGoBack = () => {
    if (activeStep === 0) onClose();
    else setActiveStep(0);
  };

  const handleToggle = (positionId: number) => () =>
    setEnabledPositions(prevState => {
      const newPositions = [...prevState];
      if (newPositions.includes(positionId)) {
        const toReturn = newPositions.filter(
          position => position !== positionId,
        );
        return toReturn;
      }
      newPositions.push(positionId);

      return newPositions;
    });

  const handleRateChange = useCallback(
    (defaultRates: RateBaseData[], positionId: number) =>
      setRates(prevState => {
        const newRates = [...prevState];
        const ratesObj: CompanyDefaultRates = {
          positionId,
          defaultRates,
        };
        const index = newRates.findIndex(
          toFind => toFind.positionId === positionId,
        );
        if (index >= 0) {
          newRates[index] = ratesObj;
        } else {
          newRates.push(ratesObj);
        }
        return newRates;
      }),
    [],
  );

  const handleSubmit = () => {
    if (selectedCompany && agencyData) {
      const createRequestObj: CreateRateSetDTO = {
        enabledPositions: {
          agencyId: agencyData.id,
          companyId: selectedCompany?.id,
          positions: enabledPositions,
        },
        rates: [],
      };
      rates.forEach(objFirstLevel => {
        objFirstLevel.defaultRates.forEach(rate => {
          if (selectedCompany) {
            createRequestObj.rates.push({
              ...rate,
              companyId: selectedCompany.id,
              positionId: objFirstLevel.positionId,
            });
          }
        });
      });
      onSuccess(createRequestObj);
    }
  };

  useEffect(() => {
    if (dataPositions) {
      if (positionFilterValue) {
        setPositions(
          dataPositions.filter((position: PositionDTO) =>
            position.name
              .toLowerCase()
              .includes(positionFilterValue.toLowerCase()),
          ),
        );
      } else {
        setPositions(dataPositions);
      }
    }
  }, [dataPositions, positionFilterValue]);

  useEffect(() => {
    // @ts-ignore
    const dataToParse = dataCompanies?.data as AgencyListItem[];
    if (dataToParse) {
      if (companyFilterValue) {
        setCompanies(
          dataToParse.filter((position: AgencyListItem) =>
            position.name
              .toLowerCase()
              .includes(companyFilterValue.toLowerCase()),
          ),
        );
      } else {
        setCompanies(dataToParse);
      }
    }
  }, [dataCompanies, companyFilterValue]);

  useEffect(() => {
    if (activeStep !== 0)
      queryClient.invalidateQueries([FETCH_POSITIONS_QUERY]);
  }, [activeStep, queryClient]);

  useEffect(() => {
    queryClient.invalidateQueries([
      'companiesWithNoRateForAgency',
      agencyData?.id,
    ]);
  }, [isOpen, agencyData, queryClient]);

  const title =
    activeStep && selectedCompany
      ? steps[activeStep].title
          .replace('%agency%', agencyData?.name || '')
          .replace('%abbreviation%', agencyData?.abbreviation || '')
      : steps[activeStep].title;
  const message =
    activeStep && selectedCompany ? (
      <>
        Choose the positions <StrongText>{selectedCompany.name}</StrongText>{' '}
        will provide for <StrongText>{agencyData?.name}</StrongText> and set
        their hourly rates.
      </>
    ) : (
      steps[activeStep].message
    );

  return (
    <Drawer
      title={`Add New Rate for ${agencyData?.name}`}
      onClose={onClose}
      onBack={handleGoBack}
      onContinue={handleSubmit}
      continueText={steps[activeStep].continueButton}
      isOpen={isOpen}
    >
      <div>
        <DrawerTitle>{title}</DrawerTitle>
        <Text>{message}</Text>
        {activeStep === 0 ? (
          <>
            <ActionsRowHolder>
              <SearchInput
                onChange={setCompanyFilterValue}
                placeholder="Search agencies"
                value={companyFilterValue}
              />
              <Button iconLeft={<Plus />} onClick={onNewCompanyClick}>
                New Company
              </Button>
            </ActionsRowHolder>
            <FlexTableHeader agenciesHeader>
              <span>Company Name</span>
              <span>Company ID</span>
            </FlexTableHeader>

            <List>
              {companies.map(company => (
                <ListItem
                  key={company.id}
                  role="presentation"
                  onClick={handleSelectCompany(company)}
                >
                  <span className="agencyName">{company.name}</span>
                  <span className="chevronHolder">
                    #{company.id} <ChevronRight iconFill={color.slateDark} />
                  </span>
                </ListItem>
              ))}
            </List>
          </>
        ) : (
          <>
            <ActionsRowHolder>
              <SearchInput
                onChange={setPositionFilterValue}
                placeholder="Search positions"
                value={positionFilterValue}
              />
              <Button iconLeft={<Plus />} onClick={onNewPositionClick}>
                {' '}
                Add New Position
              </Button>
            </ActionsRowHolder>
            <FlexTableHeader>
              <span>Position Name</span>
              <span>Enabled Status</span>
            </FlexTableHeader>
            {positions.map(position => (
              <RatesConfig
                key={position.id}
                agencyId={selectedCompany?.id}
                positionName={position.name}
                enabled={enabledPositions.includes(position.id)}
                onToggle={handleToggle(position.id)}
                positionId={position.id}
                onChange={handleRateChange}
              />
            ))}
          </>
        )}
      </div>
    </Drawer>
  );
};

export default CreateAgencyRateDrawer;
