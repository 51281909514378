import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';

import {
  createPayment,
  fetchPayments,
  fetchPaymentErrors,
} from 'services/payments';
import { QueryKeys } from 'features/common/constants';
import { useToast } from 'features/common/contexts/ToastContext';

interface UseCreatePaymentOptions {
  onSuccess?: () => void;
}

export const useCreatePayment = ({ onSuccess }: UseCreatePaymentOptions) => {
  const { addToast } = useToast();
  const queryClient = useQueryClient();

  const mutation = useMutation(createPayment, {
    onMutate: async () => {
      await queryClient.cancelQueries([QueryKeys.payments]);
    },
    onSuccess: () => {
      if (onSuccess) {
        onSuccess();
      }
      addToast({
        message: 'Payable report created successfully!',
        theme: 'active',
      });
    },

    onSettled: () => {
      queryClient.invalidateQueries([QueryKeys.payments]);
    },
  });

  return mutation;
};

export const callFetchPayments = async (context: QueryFunctionContext) => {
  const { queryKey } = context;
  const limit = queryKey[1] as Number;
  const page = queryKey[2] as Number;
  const agencyId = queryKey[3] as number | undefined;
  const companyId = queryKey[4] as number | undefined;
  const date = queryKey[5] as string;
  const order = queryKey[6] as string;
  const orderBy = queryKey[7] as string;
  const createdBy = queryKey[8] as string;

  const response = await fetchPayments(
    createdBy,
    +limit,
    +page,
    agencyId,
    companyId,
    date,
    order,
    orderBy,
  );

  return response;
};

export const useFetchPayments = (
  page: string,
  limit: string,
  createdBy: string | null,
  agencyId?: number,
  companyId?: number,
  date?: Date | null,
  order?: string,
  orderBy?: string,
) =>
  useQuery(
    [
      QueryKeys.payments,
      +limit,
      +page,
      agencyId,
      companyId,
      date,
      order,
      orderBy,
      createdBy,
    ],
    callFetchPayments,
    {
      refetchOnWindowFocus: false,
    },
  );

export const callFetchPaymentErrors = (paymentId: number | null) =>
  fetchPaymentErrors(paymentId);

export const useFetchPaymentErrors = (paymentId: number | null) =>
  useQuery(
    [QueryKeys.paymentErrors, paymentId],
    () => callFetchPaymentErrors(paymentId),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(paymentId),
    },
  );
