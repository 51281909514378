import { OrderAllocationDTO } from 'features/orderAllocation/types';

import { endpoints } from './endpoints';
import { client } from './api';

export const setupOrderAllocation = async (data: OrderAllocationDTO) => {
  const response = await client.post(endpoints.orderAllocations, data);

  return response.data;
};

export const deleteOrderAllocation = async (id: number) => {
  const response = await client.delete(`${endpoints.orderAllocations}/${id}`);
  return response.data;
};

export const fetchOrderAllocation = async (
  limit: number = 10,
  page: number = 1,
  positionId?: number,
  companyId?: number,
  order?: string,
  orderBy?: string,
) => {
  const params = {
    limit,
    page,
    'position-id': positionId,
    'company-id': companyId,
    order,
    'order-by': orderBy,
  };
  const response = await client.get(endpoints.orderAllocations, {
    params,
  });

  return response.data;
};

export const findOrderAllocation = async (
  positionId: number,
  companyId: number,
) => {
  const response = await client.get(
    `${endpoints.orderAllocations}/${positionId}/${companyId}`,
  );

  return response.data;
};
