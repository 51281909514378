import { Button, MediumModal } from '@nowsta/ux-common-components';
import { useFetchInvoiceErrors } from 'features/invoices-history/hooks/queries';
import { ErrorLine } from './styled';

interface Props {
  visible: boolean;
  handleClose: () => void;
  invoiceId: null | number;
}

export const InvoiceErrors = ({ visible, invoiceId, handleClose }: Props) => {
  const { data } = useFetchInvoiceErrors(invoiceId);
  return (
    <MediumModal
      visible={visible}
      onClose={handleClose}
      enableCloseOnOutsideClick
      title="Oops! There seems to be a Data Discrepancy"
      body={
        <div>
          {data?.data?.map((errorLine: { id: number; description: string }) => (
            <ErrorLine key={errorLine.id}>{errorLine.description}</ErrorLine>
          ))}
        </div>
      }
      footer={
        <Button actionType="primary" onClick={handleClose}>
          Close
        </Button>
      }
    />
  );
};
