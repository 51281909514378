import { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { ControlledTextInput } from 'features/common/components/ControlledTextInput';
import { DrawerTitle } from 'features/common/components/Drawer/styled';
import { EditCompanyDTO } from 'features/companies/dto';
import { CreateCompanyForm } from 'features/companies/interfaces';
import Drawer from 'features/common/components/Drawer';
import { Loader } from 'features/common/components/Loader';
import { SelectInput } from 'features/common/components/SelectInput';

import { useFetchWfmUnallocatedCompaniesForCompanies } from '../../hooks/queries';
import { Form } from '../CreateCompanyDrawer/styled';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (data: EditCompanyDTO) => void;
  data: EditCompanyDTO;
}

export const EditCompanyDrawer = (props: Props) => {
  const { data, onSuccess, onClose, isOpen } = props;
  const methods = useForm<CreateCompanyForm>();
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = methods;

  const {
    data: companyList,
    isLoading,
    refetch,
    isFetching,
  } = useFetchWfmUnallocatedCompaniesForCompanies(data.companyId);

  useEffect(() => {
    if (isOpen) refetch();
  }, [isOpen]);

  useEffect(() => {
    setValue('name', data.name);
  }, [data]);

  useEffect(() => {
    setValue('companyId', data.companyId);
  }, [companyList, data]);

  const onSubmit: SubmitHandler<CreateCompanyForm> = dataObj => {
    const createRequestObj = {
      ...dataObj,
      id: data.id,
    };
    onSuccess(createRequestObj);
    reset();
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  const dispatchForm = () => handleSubmit(onSubmit)();

  return (
    <Drawer
      title="Edit Company"
      onClose={handleClose}
      onBack={handleClose}
      onContinue={dispatchForm}
      continueText="Save"
      isOpen={isOpen}
    >
      <DrawerTitle>Edit Company Details</DrawerTitle>
      {isLoading || isFetching ? (
        <Loader />
      ) : (
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <SelectInput
              label="WFM Company"
              fieldId="companyId"
              list={companyList?.data}
              error={Boolean(errors.companyId)}
            />
            <ControlledTextInput
              control={control}
              name="name"
              label="Company name"
              placeholder="e.g ‘Big Company Inc’"
            />
          </Form>
        </FormProvider>
      )}
    </Drawer>
  );
};
