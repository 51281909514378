import { formatInTimeZone } from 'date-fns-tz';

import { PunchcardItem } from 'features/punchcards/types';
import { DEFAULT_TIMEZONE } from 'features/common/constants';

interface GetTableData {
  punchcard: PunchcardItem;
}

const DEFAULT_STATUS = 'Never invoiced';

const getFormatMoney = (value?: number) => {
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return USDollar.format(value || 0);
};

export const getTableData = ({ punchcard }: GetTableData) => {
  const regularRateInDollars = punchcard?.regular_rate_cents
    ? punchcard.regular_rate_cents / 100
    : undefined;
  const regularTotalCentsInDollars = punchcard?.regular_total_cents
    ? punchcard.regular_total_cents / 100
    : undefined;
  const doubleOvertimeRateCentsInDollars = punchcard?.double_overtime_rate_cents
    ? punchcard.double_overtime_rate_cents / 100
    : undefined;
  const overtimeTotalCentsInDollars = punchcard?.overtime_total_cents
    ? punchcard.overtime_total_cents / 100
    : undefined;
  const tipPayTotalCentsInDollars = punchcard?.tip_pay_total_cents
    ? punchcard.tip_pay_total_cents / 100
    : undefined;
  const reimbursementPayTotalCentsInDollars =
    punchcard?.reimbursement_pay_total_cents
      ? punchcard.reimbursement_pay_total_cents / 100
      : undefined;
  const travelPayTotalCentsInDollars = punchcard?.travel_pay_total_cents
    ? punchcard.travel_pay_total_cents / 100
    : undefined;
  const grandTotalCentsInDollars = punchcard?.grand_total_cents
    ? punchcard.grand_total_cents / 100
    : undefined;
  const bonusCentsInDollars = punchcard?.bonus_cents
    ? punchcard.bonus_cents / 100
    : undefined;
  const driveTimeRateCentsInDollars = punchcard?.drive_time_rate_cents
    ? punchcard.drive_time_rate_cents / 100
    : undefined;
  const overtimeRateCentsInDollars = punchcard?.overtime_rate_cents
    ? punchcard.overtime_rate_cents / 100
    : undefined;
  const doubleOvertimeTotalCentsInDollars =
    punchcard?.double_overtime_total_cents
      ? punchcard.double_overtime_total_cents / 100
      : undefined;

  return {
    external_source: String(punchcard?.externalSource).toUpperCase(),
    external_punchcard_id: punchcard?.externalPunchcardId,
    name: `${punchcard?.workerFirstName} ${punchcard?.workerLastName}`,
    position: punchcard?.positionName,
    event: punchcard?.jobName,
    date: formatInTimeZone(
      new Date(punchcard.clockInTime),
      punchcard?.companyTimezone ?? DEFAULT_TIMEZONE,
      'MMM d, yyyy',
    ),
    clock_in: formatInTimeZone(
      new Date(punchcard.clockInTime),
      punchcard?.companyTimezone ?? DEFAULT_TIMEZONE,
      'h:mm a',
    ),
    clock_out: formatInTimeZone(
      new Date(punchcard.clockOutTime),
      punchcard?.companyTimezone ?? DEFAULT_TIMEZONE,
      'h:mm a',
    ),
    regular_hours: punchcard?.regular_rate_hours,
    regular_rate: getFormatMoney(regularRateInDollars),
    regular_total: getFormatMoney(regularTotalCentsInDollars),
    overtime_hours: punchcard?.overtime_rate_hours,
    double_overtime_rate: getFormatMoney(doubleOvertimeRateCentsInDollars),
    overtime_total: getFormatMoney(overtimeTotalCentsInDollars),

    tip_pay_total: getFormatMoney(tipPayTotalCentsInDollars),
    reimbursement_pay_total: getFormatMoney(
      reimbursementPayTotalCentsInDollars,
    ),
    travel_pay_total: getFormatMoney(travelPayTotalCentsInDollars),
    grand_total: getFormatMoney(grandTotalCentsInDollars),
    event_department_name: punchcard?.eventDepartmentName,
    position_department_name: punchcard?.positionDepartmentName,
    status: punchcard?.status ?? DEFAULT_STATUS,
    product: punchcard?.productType,
    bonus_cents: getFormatMoney(bonusCentsInDollars),
    drive_time_rate_cents: getFormatMoney(driveTimeRateCentsInDollars),
    drive_time_minutes: punchcard?.drive_time_minutes,
    minimum_hours: punchcard?.minimum_hours,
    overtime_rate: getFormatMoney(overtimeRateCentsInDollars),
    double_overtime_hours: punchcard.double_overtime_rate_hours,
    double_overtime_total: getFormatMoney(doubleOvertimeTotalCentsInDollars),
  };
};
