import { ArrowDown, ArrowUp, TextInput } from '@nowsta/ux-common-components';
import { ReactComponent as TrashIcon } from 'assets/trash-icon.svg';
import React from 'react';
import { AgencyNameHolder, Delete, Down, Row, Up } from './styled';

interface Props {
  position: number;
  agencyName: string;
  quantity: number;
  onQuantityChange: (value: number) => void;
  onUp: () => void;
  onDown: () => void;
  onDelete: () => void;
}

export const AgencyAllocationRow = (props: Props) => {
  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onQuantityChange(Number(e.target.value));
  };
  return (
    <Row spaceBetween>
      <Row gap={8}>
        <div>#{props.position}</div>
        <AgencyNameHolder>{props.agencyName}</AgencyNameHolder>
        <TextInput
          uiPresets={{ uiSize: 'smaller' }}
          sizeUI={{ w: 50 }}
          type="number"
          onChange={handleQuantityChange}
          value={String(props.quantity)}
        />
      </Row>
      <Row gap={3}>
        <Up type="button" onClick={props.onUp}>
          <ArrowUp />
        </Up>
        <Down type="button" onClick={props.onDown}>
          <ArrowDown />
        </Down>
        <Delete type="button" onClick={props.onDelete}>
          <TrashIcon />
        </Delete>
      </Row>
    </Row>
  );
};
