import {
  FC,
  useCallback,
  useEffect,
  useState,
  MouseEvent,
  useMemo,
} from 'react';
import { Calendar, TextInput, XMark } from '@nowsta/ux-common-components';

import { MONTHS } from './components/DatePickerPopup/constants';
import { DatePickerPopup } from './components';
import { ClearInput, YearMonthPickerWrapper } from './styles';

interface YearMonthPickerProps {
  value: Date | null;
  onChange: (value: Date | null) => void;
  placeholder?: string;
}

export const YearMonthPicker: FC<YearMonthPickerProps> = ({
  value,
  onChange,
  placeholder = '',
}) => {
  const initialDate = useMemo(
    () => (value ? `${MONTHS[value.getMonth()]} ${value.getFullYear()}` : ''),
    [value],
  );

  const [showPickerPopoup, setShowPickerPopoup] = useState(false);
  const [date, setDate] = useState(initialDate);

  const handleChange = useCallback((param: string | any) => {
    const newDate =
      String(typeof param) === 'string' ? param : param.target.value;
    setDate(newDate);
    onChange(new Date(newDate));
  }, []);

  const handleDocumentClicked = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (
        !document
          .getElementById('year/month-picker')
          ?.contains(event.target as Node) &&
        showPickerPopoup
      ) {
        setShowPickerPopoup(false);
      }
    },
    [showPickerPopoup],
  );

  const handleClear = () => {
    onChange(null);
    setDate('');
  };

  useEffect(() => {
    // @ts-ignore
    document.addEventListener('click', handleDocumentClicked);
    return () => {
      // @ts-ignore
      document.removeEventListener('click', handleDocumentClicked);
    };
  }, [handleDocumentClicked]);

  return (
    <YearMonthPickerWrapper id="year/month-picker">
      <TextInput
        iconLeft={<Calendar />}
        placeholder={placeholder}
        iconRight={
          <ClearInput onClick={handleClear}>
            <XMark uiSize="smaller" />
          </ClearInput>
        }
        inputStyle="uiAlt"
        sizeUI={{ w: 180 }}
        value={date}
        onFocus={() => {
          setShowPickerPopoup(true);
        }}
        onChange={handleChange}
      />

      {showPickerPopoup && (
        <DatePickerPopup value={date} onChange={handleChange} />
      )}
    </YearMonthPickerWrapper>
  );
};
