import { Controller, useFormContext } from 'react-hook-form';
import { TextInput, SelectInput } from '@nowsta/tempo-ds';

import { InvoiceStatus, InvoicingTerm } from 'features/common/constants';

import { FormValues } from './types';

import * as Presenter from './Presenter';

interface EntityDrawerBodyProps {
  isEdit?: boolean;
  isInvoice?: boolean;
}

const EntityDrawerBody = ({
  isEdit = false,
  isInvoice = true,
}: EntityDrawerBodyProps) => {
  const {
    formState: { errors },
  } = useFormContext<FormValues>();

  const mode = isEdit ? 'Edit' : 'New';
  const entity = isInvoice ? 'Invoice' : 'Receivable';

  return (
    <Presenter.Wrapper>
      <Presenter.Header>
        {mode} {entity} Details
      </Presenter.Header>
      <Controller
        name="companyName"
        render={({ field }) => (
          <TextInput disabled label="Company name" {...field} />
        )}
      />
      {!isEdit && (
        <Controller
          name="invoiceNumber"
          render={({ field }) => (
            <TextInput
              label="Invoice number"
              aria-label="Invoice number"
              message={errors.invoiceNumber?.message}
              palette={errors.invoiceNumber ? 'critical' : 'ui'}
              {...field}
              placeholder="e.g 123"
            />
          )}
        />
      )}
      {!isEdit && (
        <Controller
          name="contractTerms"
          render={({ field }) => (
            <SelectInput
              label="Contract terms"
              message={errors.contractTerms?.message}
              palette={errors.contractTerms ? 'critical' : 'ui'}
              {...field}
            >
              <option value="">Select...</option>
              <option value={InvoicingTerm.net15}>Net 15</option>
              <option value={InvoicingTerm.net30}>Net 30</option>
              <option value={InvoicingTerm.net45}>Net 45</option>
            </SelectInput>
          )}
        />
      )}
      <Controller
        name="status"
        render={({ field }) => (
          <SelectInput label="Status" {...field}>
            <option value={InvoiceStatus.DRAFT}>Draft</option>
            <option value={InvoiceStatus.INVOICED}>Invoiced</option>
            <option value={InvoiceStatus.PAID}>Paid</option>
            <option value={InvoiceStatus.STALE}>Stale</option>
          </SelectInput>
        )}
      />
    </Presenter.Wrapper>
  );
};

export default EntityDrawerBody;
