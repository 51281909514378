import { AxiosRequestConfig } from 'axios';

import { GetReceivables } from 'features/common/types';
import { endpoints } from './endpoints';
import { client } from './api';

export const fetchPunchcardReceivables = async (
  options: AxiosRequestConfig,
) => {
  const response = await client.get<GetReceivables>(
    endpoints.agencyReceivables,
    options,
  );
  return response?.data;
};

export const fetchAllReceivables = async (options: AxiosRequestConfig) => {
  const response = await client.get<GetReceivables>(
    endpoints.receivables,
    options,
  );
  return response?.data;
};
