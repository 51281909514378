import { QueryFunctionContext, useQuery } from 'react-query';
import { QueryKeys } from 'features/common/constants';
import {
  fetchOrderAllocation,
  findOrderAllocation,
} from 'services/orderAllocations';

export const callFetchOrderAllocation = async (
  context: QueryFunctionContext,
) => {
  const { queryKey } = context;
  const limit = queryKey[1] as Number;
  const page = queryKey[2] as Number;
  const positionId = queryKey[3] as number | undefined;
  const companyId = queryKey[4] as number | undefined;
  const order = queryKey[5] as string;
  const orderBy = queryKey[6] as string;

  const response = await fetchOrderAllocation(
    +limit,
    +page,
    positionId,
    companyId,
    order,
    orderBy,
  );

  return response;
};

export const useFetchOrderAllocation = (
  page: string,
  limit: string,
  position?: number,
  companyId?: number,
  order?: string,
  orderBy?: string,
) =>
  useQuery(
    [
      QueryKeys.orderAllocation,
      +limit,
      +page,
      position,
      companyId,
      order,
      orderBy,
    ],
    callFetchOrderAllocation,
    {
      refetchOnWindowFocus: false,
    },
  );

export const callFindOrderAllocation = async (
  context: QueryFunctionContext,
) => {
  const { queryKey } = context;
  const companyId = queryKey[1] as number;
  const positionId = queryKey[2] as number;

  const response = await findOrderAllocation(companyId, positionId);

  return response;
};

export const useFindOrderAllocation = (
  companyId?: number,
  positionId?: number,
) =>
  useQuery(
    [QueryKeys.findOrderAllocation, companyId, positionId],
    callFindOrderAllocation,
    {
      refetchOnWindowFocus: false,
      enabled: !!(companyId && positionId),
    },
  );
