// TODO: fix not required props to have default values
/* eslint-disable react/require-default-props */
import {
  Button,
  Plus,
  SelectInput,
  ToggleSwitch,
} from '@nowsta/ux-common-components';
import { ChangeEvent, useEffect, useState } from 'react';

import { ReactComponent as TrashIcon } from 'assets/trash-icon.svg';
import { BillingTarget } from 'features/rates/enums';
import { RateBaseData } from 'features/rates/interfaces';

import {
  Delete,
  Icon,
  RateDataHolder,
  RateDatePicker,
  RateInput,
  RateTargetHolder,
  WrapperConfigs,
  Wrapper,
  Row,
  Hyphen,
  RateConfigPanel,
  PositionInfo,
  PositionName,
  PositionStatus,
} from './styled';
import { getFormattedDate } from '../RatesListView/utils';

interface Props {
  positionName: string;
  positionId: number;
  enabled: boolean;
  target?: BillingTarget;
  agencyId?: number;
  onToggle: () => void;
  onChange: (value: RateBaseData[], positionId: number) => void;
  onEditRate?: (positionId: number, index: number, value: number) => void;
  onEditStartDate?: (positionId: number, index: number, value: string) => void;
  onEditBillingTarget?: (
    positionId: number,
    index: number,
    value: BillingTarget,
  ) => void;
  ratesData?: RateBaseData[];
}

const maxAllowedRate = 999.99;
const minAllowedRate = 0.01;

const applyRateLimiters = (rateValue: number) => {
  if (rateValue > maxAllowedRate) {
    return maxAllowedRate;
  }
  if (rateValue < minAllowedRate) {
    return minAllowedRate;
  }
  return rateValue;
};

export const RatesConfig = ({
  onChange,
  positionId,
  ratesData,
  agencyId,
  target,
  onEditRate,

  onEditStartDate,
  onEditBillingTarget,
  positionName,
  onToggle,
  enabled,
}: Props) => {
  const [rates, setRates] = useState<RateBaseData[]>([]);

  useEffect(() => {
    onChange(rates, positionId);
  }, [rates, positionId, onChange]);

  useEffect(() => {
    if (ratesData) {
      setRates(ratesData);
    }
  }, [ratesData]);

  const handleAddRate = () => {
    const today = new Date().toDateString();

    const newRate: RateBaseData = {
      startDate: today.substr(today.indexOf(' ') + 1),
      rate: 30,
      target: agencyId
        ? BillingTarget.CLIENT_BILL_RATE
        : target || BillingTarget.CLIENT_BILL_RATE,
      agencyId,
    };

    setRates(prevState => [...prevState, newRate]);
  };

  const handleRemoveRate = (index: number) => () =>
    setRates(prevState => {
      const newState = [...prevState];
      newState.splice(index, 1);
      return newState;
    });

  const handleChangeRate =
    (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
      const newRate = applyRateLimiters(Number(e.target.value));
      if (onEditRate) {
        onEditRate(positionId, index, newRate);
      } else {
        setRates(prevState => {
          const newState = [...prevState];
          newState[index].rate = newRate;
          return newState;
        });
      }
    };

  const handleChangeStartDate = (index: number) => (e: string) => {
    if (onEditStartDate) {
      onEditStartDate(positionId, index, e);
    } else {
      setRates(prevState => {
        const newState = [...prevState];
        newState[index].startDate = e;
        return newState;
      });
    }
  };

  const handleChangeBillingTarget =
    (index: number) => (e: ChangeEvent<HTMLSelectElement>) => {
      if (onEditBillingTarget) {
        onEditBillingTarget(positionId, index, e.target.value as BillingTarget);
      } else {
        setRates(prevState => {
          const newState = [...prevState];
          newState[index].target = e.target.value as BillingTarget;
          return newState;
        });
      }
    };

  return (
    <Wrapper>
      <RateConfigPanel role="presentation" onClick={onToggle}>
        <PositionInfo>
          <PositionName>{positionName}</PositionName>
          <PositionStatus $enabled={enabled}>
            ({enabled ? 'Enabled' : 'Disabled'})
          </PositionStatus>
        </PositionInfo>
        <ToggleSwitch
          checked={enabled}
          onChange={onToggle}
          uiPresets={{ uiSize: 'small', isRounded: true }}
        />
      </RateConfigPanel>

      {enabled && (
        <WrapperConfigs>
          {rates.map((rate, index) => {
            // TODO: date is formated but to be improved in the next pr
            const datePickerValue = ratesData
              ? getFormattedDate(new Date(rate.startDate))
              : rate.startDate;
            return (
              // FIXME: not use index, figure out if we need an util to generate random ids
              // eslint-disable-next-line react/no-array-index-key
              <Row key={index}>
                <RateDataHolder>
                  <Hyphen />
                  <RateTargetHolder>
                    <SelectInput
                      uiPresets={{ uiSize: 'small' }}
                      sizeUI={{ w: 160 }}
                      label={index === 0 ? 'Rate Type' : ''}
                      value={rate.target}
                      onChange={handleChangeBillingTarget(index)}
                      disabled={!rate.agencyId}
                    >
                      <option value={BillingTarget.AGENCY_PAY_RATE}>
                        Agency Pay Rate
                      </option>
                      <option value={BillingTarget.CLIENT_BILL_RATE}>
                        Company Bill Rate
                      </option>
                    </SelectInput>
                  </RateTargetHolder>
                  <RateDatePicker
                    includeIcon
                    sizeUI={{ w: 120 }}
                    label={index === 0 ? 'Start Date' : ''}
                    value={datePickerValue}
                    uiSize="small"
                    onChange={e => {
                      const date = new Date(e.target.value);

                      handleChangeStartDate(index)(
                        date.toISOString().split('T')[0],
                      );
                    }}
                  />
                  <RateInput
                    uiPresets={{ uiSize: 'smaller' }}
                    sizeUI={{ w: 80 }}
                    label={index === 0 ? 'Rate' : ''}
                    value={rate.rate.toString()}
                    type="number"
                    onChange={handleChangeRate(index)}
                    iconLeft={<Icon>$</Icon>}
                  />
                </RateDataHolder>
                <Delete type="button" onClick={handleRemoveRate(index)}>
                  <TrashIcon />
                </Delete>
              </Row>
            );
          })}
          <Button
            uiPresets={{ uiSize: 'small' }}
            sizeUI={{ w: 376 }}
            spaceUI={{ mL: 20, mB: 16 }}
            iconLeft={<Plus />}
            onClick={handleAddRate}
          >
            Add Rate
          </Button>
        </WrapperConfigs>
      )}
    </Wrapper>
  );
};
