import { useCallback, useEffect, useState } from 'react';

import { SearchInput } from '@nowsta/tempo-ds';
import { Button, Plus } from '@nowsta/ux-common-components';
import { useQuery, useQueryClient } from 'react-query';

import Drawer from 'features/common/components/Drawer';
import { DrawerTitle } from 'features/common/components/Drawer/styled';

import {
  ActionsRowHolder,
  FlexTableHeader,
  List,
  ListItem,
  StrongText,
  Text,
} from 'features/common/components/Styled';
import { findWidthNoRateForCompany } from 'services';
import { AgencyListItem } from 'features/agencies/types';
import { PositionDTO } from 'features/positions/dto';
import {
  CompanyDefaultRates,
  CreateRateSetDTO,
  RateBaseData,
} from 'features/rates/interfaces';
import {
  FETCH_POSITIONS_QUERY,
  useFetchPositions,
} from 'features/positions/hooks/queries';
import { RatesConfig } from '../RatesConfig';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (data: CreateRateSetDTO) => void;
  onNewAgencyClick: () => void;
  onNewPositionClick: () => void;
  // FIXME: define a default value
  // eslint-disable-next-line react/require-default-props
  companyData?: {
    id: number;
    name: string;
  };
}

const steps = [
  {
    title: 'Step 1 of 2. Choose an Agency',
    message: 'Choose an agency to associate with this account.',
    continueButton: '',
  },
  {
    title: 'Step 2 of 2. Set %agency%’s Rates',
    continueButton: 'Set Rates',
  },
];

export const CreateCompanyDrawerRate = (props: Props) => {
  const {
    companyData,
    isOpen,
    onClose,
    onNewAgencyClick,
    onNewPositionClick,
    onSuccess,
  } = props;
  const queryClient = useQueryClient();
  const [activeStep, setActiveStep] = useState<number>(0);

  const [selectedAgency, setSelectedAgency] = useState<AgencyListItem | null>(
    null,
  );
  const [agencies, setAgencies] = useState<AgencyListItem[]>([]);
  const [agencyFilterValue, setAgencyFilterValue] = useState<string>('');

  const [positions, setPositions] = useState<PositionDTO[]>([]);
  const [positionFilterValue, setPositionFilterValue] = useState<string>('');

  const [enabledPositions, setEnabledPositions] = useState<number[]>([]);
  const [rates, setRates] = useState<CompanyDefaultRates[]>([]);

  const { data: dataAgencies } = useQuery(
    ['agenciesWithNoRateForCompany', companyData?.id],
    () => findWidthNoRateForCompany(companyData?.id!),
    {
      refetchOnMount: false,
      enabled: Boolean(companyData?.id),
    },
  );

  const { data: { items: dataPositions } = {} } = useFetchPositions({
    page: 1,
    limit: 0,
  });

  const handleResetDrawer = () => {
    setActiveStep(0);
    setSelectedAgency(null);
    setAgencyFilterValue('');
    setPositionFilterValue('');
    setEnabledPositions([]);
    setRates([]);
  };

  const handleSelectAgency = (agency: AgencyListItem) => () => {
    setSelectedAgency(agency);
    setActiveStep(1);
  };

  const handleGoBack = () => {
    if (activeStep === 0) {
      onClose();
    } else {
      setActiveStep(0);
    }

    handleResetDrawer();
  };

  const handleToggle = (positionId: number) => () =>
    setEnabledPositions(prevState => {
      const newPositions = [...prevState];
      if (newPositions.includes(positionId)) {
        const toReturn = newPositions.filter(
          position => position !== positionId,
        );
        return toReturn;
      }
      newPositions.push(positionId);

      return newPositions;
    });

  const handleRateChange = useCallback(
    (defaultRates: RateBaseData[], positionId: number) =>
      setRates(prevState => {
        const newRates = [...prevState];
        const ratesObj: CompanyDefaultRates = {
          positionId,
          defaultRates,
        };
        const index = newRates.findIndex(
          toFind => toFind.positionId === positionId,
        );
        if (index >= 0) {
          newRates[index] = ratesObj;
        } else {
          newRates.push(ratesObj);
        }
        return newRates;
      }),
    [],
  );

  const handleClose = () => {
    handleResetDrawer();
    onClose();
  };

  const handleSubmit = () => {
    if (selectedAgency && companyData) {
      const createRequestObj: CreateRateSetDTO = {
        enabledPositions: {
          agencyId: selectedAgency?.id,
          companyId: companyData.id,
          positions: enabledPositions,
        },
        rates: [],
      };
      rates.forEach(objFirstLevel => {
        objFirstLevel.defaultRates.forEach(rate => {
          if (companyData) {
            createRequestObj.rates.push({
              ...rate,
              companyId: companyData.id,
              positionId: objFirstLevel.positionId,
            });
          }
        });
      });
      onSuccess(createRequestObj);
      handleResetDrawer();
    }
  };

  useEffect(() => {
    if (dataPositions) {
      if (positionFilterValue) {
        setPositions(
          dataPositions.filter((position: PositionDTO) =>
            position.name
              .toLowerCase()
              .includes(positionFilterValue.toLowerCase()),
          ),
        );
      } else {
        setPositions(dataPositions);
      }
    }
  }, [dataPositions, positionFilterValue]);

  useEffect(() => {
    const dataToParse: AgencyListItem[] = dataAgencies?.data;
    if (dataToParse) {
      if (agencyFilterValue) {
        setAgencies(
          dataToParse.filter((position: AgencyListItem) =>
            position.name
              .toLowerCase()
              .includes(agencyFilterValue.toLowerCase()),
          ),
        );
      } else {
        setAgencies(dataToParse);
      }
    }
  }, [dataAgencies, agencyFilterValue]);

  useEffect(() => {
    if (isOpen) queryClient.invalidateQueries([FETCH_POSITIONS_QUERY]);
  }, [isOpen, queryClient]);

  useEffect(() => {
    queryClient.invalidateQueries([
      'agenciesWithNoRateForCompany',
      companyData?.id,
    ]);
  }, [isOpen, companyData, queryClient]);

  // On step change, we reset the search input,
  // otherwise this is persisted through steps which is wrong.
  useEffect(() => {
    setAgencyFilterValue('');
    setPositionFilterValue('');
  }, [activeStep]);

  const title =
    activeStep && selectedAgency
      ? steps[activeStep].title.replace('%agency%', selectedAgency?.name)
      : steps[activeStep].title;

  const message =
    activeStep && selectedAgency ? (
      <>
        Choose the positions{' '}
        <StrongText>
          {selectedAgency?.name} ({selectedAgency?.abbreviation})
        </StrongText>{' '}
        will provide for <StrongText>{companyData?.name}</StrongText> and set
        their hourly rates.
      </>
    ) : (
      'Choose an agency to associate with this account..'
    );

  return (
    <Drawer
      title={`Add New Rate for ${companyData?.name}`}
      onClose={handleClose}
      onBack={handleGoBack}
      onContinue={handleSubmit}
      continueText={steps[activeStep].continueButton}
      isOpen={isOpen}
    >
      <DrawerTitle>{title}</DrawerTitle>
      <Text>{message}</Text>
      {activeStep === 0 ? (
        <>
          <ActionsRowHolder>
            <SearchInput
              onChange={setAgencyFilterValue}
              placeholder="Search agencies"
              value={agencyFilterValue}
            />
            <Button iconLeft={<Plus />} onClick={onNewAgencyClick}>
              New Agency
            </Button>
          </ActionsRowHolder>
          <FlexTableHeader agenciesHeader>
            <span>Agency Name</span>
          </FlexTableHeader>

          <List>
            {agencies.map(agency => (
              <ListItem
                key={agency.id}
                role="presentation"
                onClick={handleSelectAgency(agency)}
              >
                <span className="agencyName">
                  {agency.name} ({agency.abbreviation})
                </span>
              </ListItem>
            ))}
          </List>
        </>
      ) : (
        <>
          <ActionsRowHolder>
            <SearchInput
              onChange={setPositionFilterValue}
              placeholder="Search positions"
              value={positionFilterValue}
            />
            <Button iconLeft={<Plus />} onClick={onNewPositionClick}>
              Add Position
            </Button>
          </ActionsRowHolder>
          <FlexTableHeader>
            <span>Position Name</span>
            <span>Enabled Status</span>
          </FlexTableHeader>
          {positions.map(position => (
            <RatesConfig
              key={position.id}
              agencyId={selectedAgency?.id}
              positionName={position.name}
              enabled={enabledPositions.includes(position.id)}
              onToggle={handleToggle(position.id)}
              positionId={position.id}
              onChange={handleRateChange}
            />
          ))}
        </>
      )}
    </Drawer>
  );
};

export default CreateCompanyDrawerRate;
