import styled from 'styled-components';

import { color, rem, textSize, textWeight } from '@nowsta/tempo-ds';
import { H1 } from 'features/common/components/headings';

export const Header = styled(H1)`
  margin-top: ${rem(24)};
  margin-bottom: ${rem(24)};
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableHeader = styled.thead`
  tr {
    th {
      ${textSize.f14Readout}
      background-color: ${color.slate160};
      color: ${color.white100};
      height: ${rem(48)};
      overflow: hidden;
      padding: 0 ${rem(24)};
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

export const TableContainer = styled.div`
  margin-top: ${rem(24)};
  background: #ffffff;
  box-shadow: ${rem(0)} ${rem(2)} ${rem(4)} -${rem(1)} rgba(0, 0, 0, 0.02),
    ${rem(0)} ${rem(4)} ${rem(8)} -${rem(2)} rgba(0, 0, 0, 0.02),
    ${rem(0)} ${rem(16)} ${rem(32)} -${rem(8)} rgba(0, 0, 0, 0.02);
  border-radius: ${rem(8)};
  width: 100%;
  overflow: auto;
`;

export const TableBody = styled.tbody`
  tr {
    :nth-of-type(even) {
      background-color: ${color.slate20};
    }
    td {
      font-size: ${rem(14)};
      line-height: ${rem(18)};
      text-align: center;
    }
  }
`;

export const Cell = styled.div`
  ${textSize.f12Readout}
  font-weight: ${textWeight.medium};
  justify-content: center;
  height: ${rem(42)};
  display: grid;
  align-content: center;
`;

export const CSVButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
