import styled from 'styled-components/macro';
import { rem, textWeight } from '@nowsta/ux-common-components';
import { color } from 'features/common/constants';

export const Holder = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.02),
    0px 4px 8px -2px rgba(0, 0, 0, 0.02), 0px 16px 32px -8px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  padding-bottom: 32px;
  width: 100%;
  overflow: auto;

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      tr {
        &:nth-of-type(1) {
          th {
            max-width: 79px;
            min-width: 79px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:nth-of-type(2) {
              padding: 0;
            }
          }
        }

        th {
          padding: 0 24px;
        }
        &:nth-of-type(1) {
          background: ${color.slateDarker};
          font-family: 'Silka';
          font-style: normal;
          font-weight: ${textWeight.semiBold};
          font-size: ${rem(14)};
          line-height: ${rem(18)};
          text-align: right;
          color: white;
          height: 48px;

          th {
            &:first-child {
              background: ${color.slateDarker};
              text-align: left;
              max-width: 100px;
              font-style: normal;
              font-weight: ${textWeight.regular};
              font-size: ${rem(12)};
              line-height: ${rem(14)};
              position: sticky;
              left: 0;
              z-index: 1;
              border-right: 0;
            }
            text-align: center;
            border-right: 1px solid ${color.blackPearl};
          }
        }

        &:nth-of-type(2) {
          background-color: ${color.slateMid};
          height: ${rem(24)};
          font-family: 'Silka';
          font-style: normal;
          font-weight: 600;
          font-size: ${rem(12)};
          line-height: ${rem(14)};
          text-align: right;
          color: #ffffff;
          th {
            &:first-child {
              background-color: ${color.slateMid};
              text-align: left;
              position: sticky;
              left: 0;
              z-index: 1;
            }
            &:nth-child(even) {
              border-right: 1px solid ${color.blueBayoux};
              text-align: left;
            }
            &:nth-of-type(2) {
              max-width: 79px;
              min-width: 79px;
              padding: 0 12px;
              text-align: center;
            }
          }
        }
      }
    }

    tbody {
      tr {
        height: 40px;
        background: #ffffff;
        border-bottom: 1px solid ${color.pattensBlue};

        td {
          padding: 0 24px;
          font-family: 'Silka';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          text-align: right;
          color: ${color.slateDarker};

          &:first-child {
            text-align: left;
            font-weight: 600;
            font-size: ${rem(14)};
            line-height: ${rem(18)};
            background-color: white;
            &:first-child {
              min-width: 195px;
              max-width: 195px;
              padding: 0 0 0 24px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              position: sticky;
              left: 0;
              z-index: 1;
            }
          }
          &:nth-child(even) {
            text-align: left;
          }
          &:nth-of-type(2) {
            border-right: 1px solid ${color.pattensBlue};
            padding: 0;
            text-align: center;
            max-width: 79px;
            min-width: 79px;
          }
        }
      }
    }
  }
`;

export const Header = styled.div`
  height: ${rem(56)};
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  left: 0;
  z-index: 1;

  h1 {
    font-family: 'Silka';
    font-style: normal;
    font-weight: 700;
    font-size: ${rem(18)};
    line-height: ${rem(22)};
    color: #2d383d;
  }
`;
