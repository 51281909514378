import { QueryKeys } from 'features/common/constants';
import { useToast } from 'features/common/contexts/ToastContext';
import { useMutation, useQueryClient } from 'react-query';
import {
  setupOrderAllocation,
  deleteOrderAllocation,
} from 'services/orderAllocations';

export const useSetupOrderAllocation = () => {
  const { addToast } = useToast();
  const queryClient = useQueryClient();

  const mutation = useMutation(setupOrderAllocation, {
    onMutate: async () => {
      await queryClient.cancelQueries([QueryKeys.orderAllocation]);
    },
    onSuccess: () => {
      addToast({
        message: 'Order allocation successfully saved!',
        theme: 'active',
      });
    },

    onSettled: () => {
      queryClient.invalidateQueries([QueryKeys.orderAllocation]);
    },
  });

  return mutation;
};

export const useDeleteOrderAllocation = () => {
  const { addToast } = useToast();
  const queryClient = useQueryClient();

  const mutation = useMutation(deleteOrderAllocation, {
    onMutate: async () => {
      await queryClient.cancelQueries([QueryKeys.orderAllocation]);
    },
    onSuccess: () => {
      addToast({
        message: 'Order allocation successfully deleted!',
        theme: 'critical',
      });
    },

    onSettled: () => {
      queryClient.invalidateQueries([QueryKeys.orderAllocation]);
    },
  });

  return mutation;
};
