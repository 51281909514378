import { AxiosRequestConfig } from 'axios';

import { GetPunchcards } from 'features/common/types';
import {
  CreatePunchcardInvoiceDTO,
  UpdatePunchcardInvoiceDTO,
} from 'features/punchcards/dto';

import { client } from './api';
import { endpoints } from './endpoints';

export const fetchPunchcards = async (options?: AxiosRequestConfig) => {
  const response = await client.get<GetPunchcards>(
    endpoints.punchcards,
    options,
  );

  return response?.data;
};

export const createPunchcardsInvoice = async (
  data?: CreatePunchcardInvoiceDTO,
) => {
  const response = await client.post(endpoints.createInvoice, {
    invoice: data,
  });

  return response?.data;
};

export const updatePunchcardsInvoice = async ({
  id,
  ...data
}: UpdatePunchcardInvoiceDTO) => {
  const response = await client.patch(`${endpoints.invoices}/${id}`, data);

  return response?.data;
};

export const updateReceivable = async ({
  id,
  ...data
}: UpdatePunchcardInvoiceDTO) => {
  const response = await client.patch(`${endpoints.receivables}/${id}`, data);

  return response?.data;
};
