import { ReactComponent as Report } from 'assets/report.svg';

import * as Presenter from './Presenter';

interface EmptyStateProps {
  message?: string;
}

const EmptyState = ({ message = 'No items found.' }: EmptyStateProps) => (
  <Presenter.Wrapper>
    <Presenter.IconHolder>
      <Presenter.GradientCircle />
      <Report />
    </Presenter.IconHolder>
    <Presenter.Title>{message}</Presenter.Title>
  </Presenter.Wrapper>
);

export default EmptyState;
