import { useCallback, useEffect, useState } from 'react';

import { SearchInput } from '@nowsta/tempo-ds';
import { Button, Plus } from '@nowsta/ux-common-components';
import { useQuery, useQueryClient } from 'react-query';

import Drawer from 'features/common/components/Drawer';
import { DrawerTitle } from 'features/common/components/Drawer/styled';

import {
  ActionsRowHolder,
  FlexTableHeader,
  StrongText,
  Text,
} from 'features/common/components/Styled';
import { findRatesByCompanyAndAgency } from 'services';
import { PositionDTO } from 'features/positions/dto';
import {
  CompanyEditRates,
  EditRateSetDto,
  IdAndName,
  RateBaseData,
  RatesByCompanyAndAgency,
} from 'features/rates/interfaces';
import { BillingTarget } from 'features/rates/enums';
import {
  FETCH_POSITIONS_QUERY,
  useFetchPositions,
} from 'features/positions/hooks/queries';
import { RatesConfig } from '../RatesConfig';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (data: EditRateSetDto) => void;
  onNewPositionClick: () => void;
  // FIXME: define a default value
  // eslint-disable-next-line react/require-default-props
  companyData?: IdAndName;
  // FIXME: define a default value
  // eslint-disable-next-line react/require-default-props
  agencyData?: IdAndName | null;
}

export const CreateCompanyDrawer = (props: Props) => {
  const {
    companyData,
    agencyData,
    isOpen,
    onClose,
    onNewPositionClick,
    onSuccess,
  } = props;

  const queryClient = useQueryClient();

  const [positions, setPositions] = useState<PositionDTO[]>([]);
  const [positionFilterValue, setPositionFilterValue] = useState<string>('');

  const [enabledPositions, setEnabledPositions] = useState<number[]>([]);
  const [rates, setRates] = useState<CompanyEditRates[]>([]);

  const { data: { items: dataPositions } = {} } = useFetchPositions({
    page: 1,
    limit: 0,
  });

  const hydrateDrawer = (data: RatesByCompanyAndAgency) => {
    setEnabledPositions(data.enabledPositions);

    const newRates: CompanyEditRates[] = [];

    positions.forEach(pos => {
      const unParsedRates = data.rates.filter(
        rate => rate.position.id === pos.id,
      );

      const parsedRates = unParsedRates.map(ratePerse => ({
        startDate: ratePerse.startDate,
        rate: ratePerse.rate,
        id: ratePerse.id,
        target: ratePerse.target,
        agencyId: agencyData?.id,
      }));

      const toPush = {
        positionId: pos.id,
        rates: parsedRates,
      };

      newRates.push(toPush);
    });

    setRates(newRates);
  };

  const { data: dataRates } = useQuery(
    ['dataRates', companyData, agencyData],
    () => findRatesByCompanyAndAgency(companyData?.id, agencyData?.id),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: Boolean(companyData?.id),
      onSuccess: resp => hydrateDrawer(resp.data),
    },
  );

  const handleResetDrawer = () => {
    setPositionFilterValue('');
    setEnabledPositions([]);
    setRates([]);
  };

  const handleGoBack = () => {
    onClose();
    handleResetDrawer();
  };

  const handleToggle = (positionId: number) => () =>
    setEnabledPositions(prevState => {
      const newPositions = [...prevState];

      if (newPositions.includes(positionId)) {
        const toReturn = newPositions.filter(
          position => position !== positionId,
        );

        return toReturn;
      }

      newPositions.push(positionId);

      return newPositions;
    });

  const handleRateChange = useCallback(
    (changedRates: RateBaseData[], positionId: number) => {
      if (changedRates.length) {
        setRates(prevState => {
          const newRates = [...prevState];

          const ratesObj: CompanyEditRates = {
            positionId,
            rates: changedRates,
          };

          const index = newRates.findIndex(
            toFind => toFind.positionId === positionId,
          );

          if (index >= 0) {
            newRates[index] = ratesObj;
          } else {
            newRates.push(ratesObj);
          }

          return newRates;
        });
      }
    },
    [],
  );

  const handleClose = () => {
    handleResetDrawer();
    onClose();
  };

  const handleSubmit = () => {
    if (companyData) {
      const editRequestObj: EditRateSetDto = {
        agencyId: agencyData?.id || null,
        companyId: companyData.id,
        enabledPositions: {
          agencyId: agencyData?.id || null,
          companyId: companyData.id,
          positions: enabledPositions,
        },
        rates: [],
      };

      rates.forEach(objFirstLevel => {
        objFirstLevel.rates.forEach(rate => {
          if (companyData) {
            editRequestObj.rates.push({
              ...rate,
              companyId: companyData.id,
              positionId: objFirstLevel.positionId,
            });
          }
        });
      });

      onSuccess(editRequestObj);
    }
  };

  const handleEditRate = (positionId: number, index: number, value: number) =>
    setRates(prevState => {
      const newState = [...prevState];

      const positionIndex = newState.findIndex(
        position => position.positionId === positionId,
      );

      newState[positionIndex].rates[index].rate = value;

      return newState;
    });

  const handleEditStartDate = (
    positionId: number,
    index: number,
    value: string,
  ) =>
    setRates(prevState => {
      const newState = [...prevState];

      const positionIndex = newState.findIndex(
        position => position.positionId === positionId,
      );

      newState[positionIndex].rates[index].startDate = value;

      return newState;
    });

  const handleEditBillingTarget = (
    positionId: number,
    index: number,
    value: BillingTarget,
  ) =>
    setRates(prevState => {
      const newState = [...prevState];

      const positionIndex = newState.findIndex(
        position => position.positionId === positionId,
      );

      newState[positionIndex].rates[index].target = value;

      return newState;
    });

  useEffect(() => {
    queryClient.invalidateQueries(['dataRates', companyData, agencyData]);
  }, [companyData, agencyData, queryClient]);

  useEffect(() => {
    if (dataRates && dataRates.data) {
      hydrateDrawer(dataRates.data);
    }
  }, [positions, agencyData]);

  useEffect(() => {
    if (dataPositions) {
      if (positionFilterValue) {
        setPositions(
          dataPositions.filter((position: PositionDTO) =>
            position.name
              .toLowerCase()
              .includes(positionFilterValue.toLowerCase()),
          ),
        );
      } else {
        setPositions(dataPositions);
      }
    }
  }, [dataPositions, positionFilterValue]);

  useEffect(() => {
    if (isOpen) {
      queryClient.invalidateQueries([FETCH_POSITIONS_QUERY]);
    }
  }, [isOpen, queryClient]);

  const drawerTitle = agencyData
    ? `Edit Rates for ${companyData?.name}`
    : `Edit Default Rates for ${companyData?.name}`;

  const contentTitle = agencyData
    ? `Edit ${agencyData?.name}’s Rates`
    : 'Edit Default Rates';

  const message = agencyData ? (
    <>
      Choose the positions <StrongText>{agencyData?.name}</StrongText> will
      provide for <StrongText>{companyData?.name}</StrongText> and set their
      hourly rates.
    </>
  ) : (
    'Choose the positions this company will use by adding default rates.'
  );

  return (
    <Drawer
      title={drawerTitle}
      onClose={handleClose}
      onBack={handleGoBack}
      onContinue={handleSubmit}
      continueText="Update Rates"
      isOpen={isOpen}
    >
      <div>
        <DrawerTitle>{contentTitle}</DrawerTitle>
        <Text>{message}</Text>

        <ActionsRowHolder>
          <SearchInput
            onChange={setPositionFilterValue}
            placeholder="Search positions"
            value={positionFilterValue}
          />
          <Button iconLeft={<Plus />} onClick={onNewPositionClick}>
            New Position
          </Button>
        </ActionsRowHolder>
        <FlexTableHeader>
          <span>Position Name</span>
          <span>Enabled Status</span>
        </FlexTableHeader>
        {positions.map(position => (
          <RatesConfig
            key={position.id}
            agencyId={agencyData?.id}
            positionName={position.name}
            enabled={enabledPositions.includes(position.id)}
            onToggle={handleToggle(position.id)}
            positionId={position.id}
            onChange={handleRateChange}
            ratesData={
              rates.find(pos => pos.positionId === position.id)?.rates ||
              undefined
            }
            onEditRate={handleEditRate}
            onEditStartDate={handleEditStartDate}
            onEditBillingTarget={handleEditBillingTarget}
          />
        ))}
      </div>
    </Drawer>
  );
};

export default CreateCompanyDrawer;
