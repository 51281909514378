import { WFMDeparmentByCompanyResponse } from 'features/invoices-history/types';
import { client } from './api';

export const fetchDeparmentsByCompany = async (
  companyId: string | null,
): Promise<WFMDeparmentByCompanyResponse> => {
  const result = await client.get(
    `companies/departmentByCompany?company=${companyId}`,
  );
  return result.data;
};
