import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { CreateCompanyDTO, EditCompanyDTO } from 'features/companies/dto';
import { GetCompanies } from 'features/common/types';

import { endpoints } from './endpoints';
import { client } from './api';

export const fetchCompanies = async (options?: AxiosRequestConfig) => {
  const response = await client.get<GetCompanies>(endpoints.companies, options);
  return response.data;
};

export const fetchWfmUnAllocatedCompaniesForCompanies = async (
  include?: string | null,
): Promise<AxiosResponse<{ id: number; name: string }[]>> => {
  const response = await client.get(
    endpoints.wfmUnallocatedCompaniesForCompanies,
    {
      params: {
        include,
      },
    },
  );

  return response;
};

export const createCompany = async (data: CreateCompanyDTO) =>
  client.post(endpoints.companies, data);

export const patchCompany = async (data: EditCompanyDTO) => {
  const { id } = data;
  // @ts-expect-error / I really know i want to delete id from there XD
  delete data.id;
  return client.patch(`${endpoints.companies}/${id}`, data);
};

export const findWidthNoRateForAgency = async (companyId?: number) => {
  const response = await client.get(
    endpoints.companyFindWidthNoRateForAgency.replace(
      ':agencyId',
      String(companyId),
    ),
  );

  return response;
};
