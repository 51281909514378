import { AxiosRequestConfig, AxiosResponse } from 'axios';

import { CreateAgencyDTO, EditAgencyDTO } from 'features/agencies/dto';
import { AgencyListItem } from 'features/agencies/types';
import { PaginatedData } from 'features/common/types';

import { endpoints } from './endpoints';
import { client } from './api';

export const createAgency = async (data: CreateAgencyDTO) =>
  client.post(endpoints.agencies, data);

export const findWidthNoRateForCompany = async (companyId: number) => {
  const response = await client.get(
    endpoints.agencyFindWidthNoRateForCompany.replace(
      ':companyId',
      String(companyId),
    ),
  );

  return response;
};

export const findThatHaveRateWithCompanyAndPosition = async (
  companyId: number,
  positionId: number,
) => {
  const response = await client.get(
    endpoints.agencyFindRateForCompanyAndPosition
      .replace(':companyId', String(companyId))
      .replace(':positionId', String(positionId)),
  );

  return response;
};

export const findThatHaveRateWithCompany = async (companyId: number) => {
  const response = await client.get(
    endpoints.agencyFindRateForCompany.replace(':companyId', String(companyId)),
  );

  return response;
};

export const fetchAgencies = async (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PaginatedData<AgencyListItem[]>>> => {
  const response = await client.get(endpoints.agencies, options);
  return response;
};

export const fetchWfmUnAllocatedCompaniesForAgencies = async (
  include?: string | null,
): Promise<AxiosResponse<{ id: number; name: string }[]>> => {
  const response = await client.get(
    endpoints.wfmUnallocatedCompaniesForAgencies,
    {
      params: {
        include,
      },
    },
  );

  return response;
};

export const updateAgency = async ({ id, ...data }: EditAgencyDTO) => {
  const response = await client.patch(`${endpoints.agencies}/${id}`, data);

  return response.data;
};

export const updateLogo = async (id: number, formData: FormData) => {
  const response = await client.patch(
    `${endpoints.agencies}/${id}/logo`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  );

  return response;
};
