import { rem } from '@nowsta/ux-common-components';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';

export const InputHolder = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: ${rem(8)};
`;

export const Required = styled.span`
  display: block;
  font-weight: 500;
  color: #cc52a3;
  font-size: 0.75rem;
  line-height: 1.125rem;
`;

export const ErrorLine = styled.span`
  display: inline-block;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #68828f;
  transition: color 0.25s ease-in-out;
  svg {
    display: inline-block;
    vertical-align: text-top;
    margin-right: 0.25rem;
    height: 0.75rem;
    width: 0.75rem;
    fill: #68828f;
  }
`;

export const InputLabel = styled.span`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  font-family: 'Silka', 'Helvetica', 'Helvetica Neue', Arial, sans-serif;
  font-weight: 500;
  transition: color 0.25s ease-in-out;
  font-weight: 600;
  color: #4a5d66;
  font-size: 0.75rem;
  line-height: 0.875rem;
  margin-bottom: 0.5rem;
`;

export const Select = styled.select`
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font-family: 'Silka', 'Helvetica', 'Helvetica Neue', Arial, sans-serif;
  font-weight: 500;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
  color: inherit;
  appearance: none;
  width: 100%;
  color: #2d383d;
  font-size: 0.875rem;
  line-height: 1.375rem;
  border-radius: 0.3125rem;
  font-size: 0.875rem;
  line-height: 1.375rem;
  min-height: 2.5rem;
  padding: 0.5625rem 0.75rem;
  background-color: white;
  box-shadow: inset 0 0 0 0.0625rem #68828f;
  transition: padding 0.25s ease-in-out, background-color 0.25s ease-in-out,
    color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;

  &:before {
    display: block;
    content: '';
    position: absolute;
    z-index: 1;
    pointer-events: none;
    -webkit-transition: opacity 0.25s ease-in-out;
    transition: opacity 0.25s ease-in-out;
    z-index: -2;
    top: -0.1875rem;
    right: -0.1875rem;
    bottom: -0.1875rem;
    left: -0.1875rem;
    border-radius: 0.5rem;
    -webkit-transition: opacity 0.25s ease-in-out,
      -webkit-transform 0.25s ease-in-out;
    -webkit-transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
    transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
    background-color: #269fbd;
    opacity: 0;
    -webkit-transform: scale3d(1.06, 1.3, 1);
    -ms-transform: scale3d(1.06, 1.3, 1);
    transform: scale3d(1.06, 1.3, 1);
    pointer-events: none;
  }
`;

interface Props {
  label: string;
  fieldId: string;
  list?: { id: number; name: string }[];
  error?: boolean;
  required?: boolean;
}

export const SelectInput = ({
  label,
  fieldId,
  list,
  error,
  required = true,
}: Props) => {
  const { register } = useFormContext();
  return (
    <InputHolder>
      <InputLabel>
        <span>{label}</span>
        {error && <Required>Required</Required>}
      </InputLabel>
      <Select {...register(fieldId, { required })}>
        <option value="null">Select one</option>
        {list?.map((wfmCompany: { id: number; name: string }) => (
          <option key={wfmCompany.id} value={wfmCompany.id}>
            {wfmCompany.name}
          </option>
        ))}
      </Select>
      {error && (
        <ErrorLine>
          <svg viewBox="0 0 768 768" xmlns="http://www.w3.org/2000/svg">
            <rect x="96" y="336" width="576" height="96" rx="48" />
          </svg>
          This field is required{' '}
        </ErrorLine>
      )}
    </InputHolder>
  );
};
