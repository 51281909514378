import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { CreateInvoiceDTO } from 'features/invoices-history/types';

import { GetInvoices } from 'features/common/types';
import { endpoints } from './endpoints';
import { client } from './api';

export const fetchInvoices = async (
  yearMonth: Date | null,
  terms: string,
  createdBy: string,
  limit: number = 10,
  page: number = 1,
  companyId?: number,
) => {
  const params = {
    limit,
    page,
    terms,
    'created-by': createdBy,
    'company-id': companyId,
    'year-month':
      yearMonth instanceof Date
        ? `${yearMonth.getFullYear()}-${yearMonth.getMonth() + 1}`
        : null,
  };
  const response = await client.get(endpoints.invoicesHistory, {
    params,
  });

  return response.data;
};

export const createInvoice = async (data: CreateInvoiceDTO) => {
  const response = await client.post(endpoints.invoicesHistory, data);

  return response.data;
};

export const fetchInvoiceErrors = async (invoiceId: number | null) =>
  client.get(`${endpoints.invoiceErrors}/${invoiceId}`);

export const fetchInvoiceCSV = (invoiceId: number | null) =>
  client
    .get(`${endpoints.invoicesHistory}/${invoiceId}/download`, {
      responseType: 'stream',
    })
    .then((response: { data: BlobPart }) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: 'text/csv',
        }),
      );

      window.open(url);
    });

function getFilenameFromResponse(response: AxiosResponse): string | null {
  const contentDispositionHeader = response.headers['content-disposition'];
  const match =
    contentDispositionHeader &&
    contentDispositionHeader.match(/filename="(.+)"/);
  return match ? decodeURIComponent(match[1]) : null;
}

export const dowloadInvoiceCSV = (invoiceId: number | null) =>
  client
    .get(`${endpoints.invoices}/${invoiceId}/download`, {
      responseType: 'stream',
    })
    .then(response => {
      const filename =
        getFilenameFromResponse(response) || 'default-filename.csv';
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: 'text/csv',
        }),
      );

      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });

export const dowloadReceivableCSV = (receivableId: number | null) =>
  client
    .get(`${endpoints.receivables}/${receivableId}/download`, {
      responseType: 'stream',
    })
    .then(response => {
      const filename =
        getFilenameFromResponse(response) || 'default-filename.csv';
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: 'text/csv',
        }),
      );

      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });

export const fetchPunchcardInvoices = async (options: AxiosRequestConfig) => {
  const response = await client.get<GetInvoices>(
    endpoints.clientInvoices,
    options,
  );
  return response?.data;
};

export const fetchAllInvoices = async (options: AxiosRequestConfig) => {
  const response = await client.get<GetInvoices>(endpoints.invoices, options);
  return response?.data;
};
