import styled from 'styled-components/macro';
import { DatePicker } from '@nowsta/tempo-ds';
import {
  TextInput,
  textSize,
  textWeight,
  rem,
} from '@nowsta/ux-common-components';
import { color } from 'features/common/constants';

export const Wrapper = styled.div`
  border-bottom: 1px solid #e1e6e9;
`;

export const Row = styled.li`
  display: flex;
  align-items: flex-end;
  padding-right: ${rem(10)};
  justify-content: space-between;
`;

export const RateTargetHolder = styled.div`
  ${textSize.f12};
  color: ${color.slateDarker};
  font-family: 'Silka';
  font-style: normal;
  font-weight: ${textWeight.semiBold};
`;

export const RateConfigPanel = styled.div`
  ${textSize.f14};
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: space-between;
  padding: 0 ${rem(12)} 0 ${rem(16)};
  cursor: pointer;
`;

export const WrapperConfigs = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${rem(8)};
  margin: 0;
  padding-left: ${rem(18)};
  position: relative;

  :before {
    content: '';
    position: absolute;
    height: calc(100% - 73px);
    width: 2px;
    background-color: ${color.slateLighter};
  }
`;

export const PositionInfo = styled.dl`
  display: flex;

  span + span {
    margin-left: ${rem(4)};
  }
`;
export const PositionStatus = styled.span<{ $enabled: boolean }>`
  ${textSize.f14}
  color: ${({ $enabled }) => ($enabled ? color.tealDark : color.slate)};
  font-family: 'Silka';
  font-style: normal;
  font-weight: ${textWeight.medium};
  margin: 0;
`;

export const PositionName = styled.span`
  font-family: 'Silka';
  font-style: normal;
  font-weight: ${textWeight.semiBold};
  ${textSize.f14}
  color: ${color.slateDarker};
  margin: 0;
`;

export const Icon = styled.div`
  font-family: 'Silka';
  font-style: normal;
  font-weight: ${textWeight.regular};
  ${textSize.f12};
  color: ${color.slate};
  margin-right: ${rem(5)};
`;

export const Delete = styled.button`
  width: 40px;
  height: 32px;
  background: ${color.red};
  border-radius: 5px;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  cursor: pointer;
`;

export const RateDataHolder = styled.div`
  width: 400px;
  display: flex;
  border: 0;
  align-items: flex-end;
  gap: ${rem(8)};
`;

export const RateInput = styled(TextInput)`
  span[role='presentation'] {
    height: 32px;
  }

  input {
    ${textSize.f12}
  }
`;

export const RateDatePicker = styled(DatePicker)`
  span[role='presentation'] {
    height: 32px;
  }

  input {
    ${textSize.f12}
  }
`;

export const Hyphen = styled.span`
  background-color: ${color.slateLighter};
  display: block;
  height: 2px;
  margin-bottom: ${rem(15.5)};
  width: 12px;
`;
