import { QueryKeys } from 'features/common/constants';
import { useQuery } from 'react-query';

import {
  fetchCompanies,
  fetchWfmUnAllocatedCompaniesForCompanies,
} from 'services';

export const FETCH_COMPANIES_QUERY = 'fetch-companies-query';

export interface Company {
  id: string;
  name: string;
}

export const fetchCompaniesRequest = async (
  page: number,
  limit: number,
  startsWith: string,
) => {
  const response = await fetchCompanies({
    params: { page, limit, startsWith },
  });
  return response;
};

export const useFetchCompanies = (
  page: number,
  limit: number,
  startsWith: string,
) =>
  useQuery(
    [QueryKeys.companies, { page, limit, startsWith }],
    () => fetchCompaniesRequest(page, limit, startsWith),
    {
      refetchOnWindowFocus: false,
    },
  );

export const FETCH_WFM_UNALLOCATED_COMPANIES_FOR_COMPANIES =
  'FETCH_WFM_UNALLOCATED_COMPANIES_FOR_COMPANIES';

export const useFetchWfmUnallocatedCompaniesForCompanies = (
  include?: string | null,
) =>
  useQuery(
    [FETCH_WFM_UNALLOCATED_COMPANIES_FOR_COMPANIES],
    () => fetchWfmUnAllocatedCompaniesForCompanies(include),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );
