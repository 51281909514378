import styled from 'styled-components';
import { H1 } from 'features/common/components/headings';
import { breakpoints, color } from 'features/common/constants';
import { textSize, textWeight, rem } from '@nowsta/ux-common-components';

export const Header = styled(H1)`
  margin-top: 1.3rem;
  margin-bottom: 1.48rem;
`;

export const ActionsHolder = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const ButtonsHolder = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: 23px 0;
  justify-content: space-between;

  @media (max-width: ${breakpoints.medium}) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const AllocationAgencyContent = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  .agencyName {
    font-weight: ${textWeight.semiBold};
    ${textSize.f14};
  }

  .index {
    font-weight: ${textWeight.semiBold};
    ${textSize.f12};
  }

  .quantity {
    ${textSize.f12}
  }
  .allocationInfo {
    display: flex;
    flex-direction: column;
    gap: ${rem(5)};
  }
`;

export const AllocationRow = styled.div`
  display: flex;
  gap: ${rem(8)};
`;

export const AllocationAgency = styled.div`
  box-shadow: 5px 10px 18px -9px rgba(0, 0, 0, 0.75);
  padding: ${rem(8)};
  border-radius: ${rem(10)};
`;

export const SortingArea = styled.div`
  display: flex;
  gap: 8px;
  cursor: pointer;
  align-items: center;

  svg.icon {
    fill: ${color.white};
  }
`;

export const Table = styled.table`
  margin-top: 40px;
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;

  thead {
    tr {
      background: ${color.slateDarker};
      font-family: 'Silka';
      font-style: normal;
      font-weight: ${textWeight.semiBold};
      font-size: ${rem(14)};
      line-height: ${rem(18)};
      text-align: right;
      color: white;
      height: 48px;

      th {
        padding: 0 24px;

        background: ${color.slateDarker};
        text-align: left;
        max-width: 100px;
        font-style: normal;
        font-weight: ${textWeight.regular};
        font-size: ${rem(12)};
        line-height: ${rem(14)};
        left: 0;
        z-index: 1;
        border-right: 0;
      }
    }
  }

  tbody {
    tr {
      height: 40px;
      background: #ffffff;
      border-bottom: 1px solid ${color.pattensBlue};

      td {
        padding: 10px 24px;
        font-family: 'Silka';
        font-style: normal;
        font-weight: ${textWeight.regular};
        font-size: 14px;
        line-height: 18px;
        text-align: right;
        color: ${color.slateDarker};
        text-align: left;

        &:first-child {
          font-weight: ${textWeight.semiBold};
          ${textSize.f14};
          background-color: white;
        }
      }
    }
  }
`;
