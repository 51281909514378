import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  createTable,
  getCoreRowModelSync,
  useTableInstance,
} from '@tanstack/react-table';
import { Button, Plus, TablePagination } from '@nowsta/ux-common-components';
import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import Layout from 'features/common/components/Layout';
import {
  FETCH_POSITIONS_QUERY,
  useFetchPositions,
} from 'features/positions/hooks/queries';
import { useURLQueryParams } from 'features/common/hooks/useURLQueryParams';
import { CreatePositionDTO, PositionDTO } from 'features/positions/dto';
import { createPosition, updatePosition } from 'services';
import { useToast } from 'features/common/contexts/ToastContext';

import { ButtonsHolder, Header, Table } from './styled';
import { ListItemSkeleton } from './skeleton';
import CreatePositionDrawer from '../CreatePositionDrawer';
import EditPositionDrawer from '../EditPositionDrawer';

interface DrawerStates {
  create: boolean;
  edit: boolean;
}

export const PositionsListView = () => {
  const { addToast } = useToast();
  const queryClient = useQueryClient();

  const [positionToEdit, setPositionToEdit] = useState<PositionDTO>();

  const [drawerState, setDrawerState] = useState<DrawerStates>({
    create: false,
    edit: false,
  });
  const openCreateDrawer = () => setDrawerState({ create: true, edit: false });
  const openEditDrawer = (data: PositionDTO) => () => {
    setPositionToEdit(data);
    setDrawerState({ create: false, edit: true });
  };
  const closeDrawers = () => setDrawerState({ create: false, edit: false });

  const { urlParams, setURLParams } = useURLQueryParams();
  const page = Number(urlParams.get('page')) || 1;
  const limit = Number(urlParams.get('limit')) || 10;

  const { data: { items: positions = [], meta } = {}, isLoading } =
    useFetchPositions({ page, limit });

  const handlePageLimitChange = useCallback(
    (newLimit: number) => {
      setURLParams({ limit: String(newLimit) });
    },
    [setURLParams],
  );

  const handlePageNumberChange = useCallback(
    (newPage: number) => {
      setURLParams({ page: String(newPage) });
    },
    [setURLParams],
  );

  const table = createTable<{ Row: { [value: string]: any } }>();

  const columns = useMemo(
    () =>
      table.createColumns([
        table.createDataColumn('id', {
          id: 'id',
          cell: info => info.value,
          header: () => 'ID',
        }),
        table.createDataColumn('name', {
          id: 'name',
          cell: info => info.value,
          header: () => 'Position',
        }),
      ]),

    [positions],
  );

  const instance = useTableInstance(table, {
    data: positions,
    columns,
    getCoreRowModel: getCoreRowModelSync(),
  });

  const {
    mutate: positionCreate,
    isSuccess: positionCreateSuccess,
    isError: positionCreateIsError,
    error: positionCreateError,
    reset: positionCreateReset,
  } = useMutation(createPosition);

  const handlePositionCreate = (data: CreatePositionDTO) =>
    positionCreate(data);

  useEffect(() => {
    if (positionCreateSuccess) {
      closeDrawers();
      addToast({ message: 'Position added successfully!', theme: 'active' });
      positionCreateReset();
      setTimeout(() => {
        queryClient.invalidateQueries([FETCH_POSITIONS_QUERY]);
      }, 600);
    }

    if (positionCreateIsError) {
      const { response } = positionCreateError as { response: AxiosResponse };
      addToast({ message: response.data.message, theme: 'critical' });
      positionCreateReset();
    }
  }, [
    positionCreateSuccess,
    positionCreateError,
    addToast,
    positionCreateIsError,
    positionCreateReset,
    closeDrawers,
    queryClient,
  ]);

  const {
    mutate: positionUpdate,
    isSuccess: positionUpdateSuccess,
    isError: positionUpdateIsError,
    error: positionUpdateError,
    reset: positionUpdateReset,
  } = useMutation(updatePosition);

  const handlePositionUpdate = (data: PositionDTO) => positionUpdate(data);

  useEffect(() => {
    if (positionUpdateSuccess) {
      closeDrawers();
      addToast({ message: 'Position updated successfully!', theme: 'active' });
      positionUpdateReset();
      setTimeout(() => {
        queryClient.invalidateQueries([FETCH_POSITIONS_QUERY]);
      }, 600);
    }

    if (positionUpdateIsError) {
      const { response } = positionUpdateError as { response: AxiosResponse };
      addToast({ message: response.data.message, theme: 'critical' });
      positionUpdateReset();
    }
  }, [
    positionUpdateSuccess,
    positionUpdateError,
    addToast,
    positionUpdateIsError,
    positionUpdateReset,
    closeDrawers,
    queryClient,
  ]);

  return (
    <Layout>
      <Header>Labor Market Positions</Header>
      <ButtonsHolder>
        <Button
          sizeUI={{ minW: 101 }}
          iconLeft={<Plus />}
          onClick={openCreateDrawer}
        >
          Position
        </Button>
      </ButtonsHolder>
      {isLoading ? (
        <ListItemSkeleton />
      ) : (
        <>
          <Table {...instance.getTableProps()}>
            <thead>
              {instance.getHeaderGroups().map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(header => (
                    <th {...header.getHeaderProps()}>
                      {header.isPlaceholder ? null : header.renderHeader()}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...instance.getTableBodyProps()}>
              {instance.getRowModel().rows.map(row => (
                <tr {...row.getRowProps()}>
                  {row.getVisibleCells().map(cell => (
                    <td
                      role="presentation"
                      {...cell.getCellProps()}
                      onClick={openEditDrawer(row.values as PositionDTO)}
                    >
                      {cell.renderCell()}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
          <TablePagination
            pageNumber={meta.currentPage}
            totalPages={Number(meta.totalPages)}
            limit={meta.itemCount}
            onLimitChange={handlePageLimitChange}
            onPageNumberChange={handlePageNumberChange}
            spaceUI={{ mT: 48, mB: 30 }}
          />
        </>
      )}
      <CreatePositionDrawer
        onClose={closeDrawers}
        onBack={closeDrawers}
        isOpen={drawerState.create}
        onSuccess={handlePositionCreate}
      />
      <EditPositionDrawer
        onClose={closeDrawers}
        isOpen={drawerState.edit}
        data={positionToEdit}
        onSuccess={handlePositionUpdate}
      />
    </Layout>
  );
};
