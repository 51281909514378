import styled from 'styled-components';
import { rem, textSize, textWeight } from '@nowsta/ux-common-components';
import { color } from 'features/common/constants';

export const Form = styled.form`
  margin-top: ${rem(24)};
  display: flex;
  flex-direction: column;
  gap: ${rem(16)};
`;
export const ErrorBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${rem(8)};
`;

export const Title = styled.h4`
  ${textSize.f24};
  color: ${color.slateDarker};
  font-weight: ${textWeight.semiBold};
  font-family: 'Silka';
  font-style: normal;
  margin: ${rem(14)} 0 0;
`;

export const Text = styled.span`
  ${textSize.f14};
  color: ${color.slateDark};
  font-family: 'Silka';
  font-style: normal;
  font-weight: ${textWeight.regular};
  text-align: center;
  max-width: 360px;
  margin: ${rem(4)} 0 ${rem(16)} 0;
`;
