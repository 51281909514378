import styled from 'styled-components';
import {
  SelectInput as TempoSelectInput,
  SelectInputProps,
} from '@nowsta/ux-common-components';

import { color } from 'features/common/constants';
import { fade } from 'features/common/utils';

export const SelectInput = styled(TempoSelectInput)<SelectInputProps>`
  select {
    ${({ value }) =>
      !value &&
      `
        color: ${fade(color.slateDarker, 30)};
      `}
  }
`;
