import { CreatePaymentDTO } from 'features/payments/types';

import { endpoints } from './endpoints';
import { client } from './api';

export const createPayment = async (data: CreatePaymentDTO) => {
  const response = await client.post(endpoints.payments, data);

  return response.data;
};

export const fetchPayments = async (
  createdBy: string,
  limit: number = 10,
  page: number = 1,
  agencyId?: number,
  companyId?: number,
  date?: string,
  order?: string,
  orderBy?: string,
) => {
  const jsDate = date ? new Date(date) : null;
  const params = {
    limit,
    page,
    'created-by': createdBy,
    'agency-id': agencyId,
    'company-id': companyId,
    'year-month':
      jsDate instanceof Date
        ? `${jsDate.getFullYear()}-${jsDate.getMonth() + 1}`
        : null,
    order,
    'order-by': orderBy,
  };
  const response = await client.get(endpoints.payments, {
    params,
  });

  return response.data;
};

export const fetchPaymentErrors = async (paymentId: number | null) =>
  client.get(`${endpoints.paymentErrors}/${paymentId}`);

export const downloadPaymentCSV = (paymentId: number | null) =>
  client
    .get(`${endpoints.payments}/${paymentId}/download`, {
      responseType: 'stream',
    })
    .then(response => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: 'text/csv',
        }),
      );

      window.open(url);
    });
