import { rem } from '@nowsta/ux-common-components';
import { ControlledSelectInput } from 'features/common/components/ControlledSelectInput';
import styled, { css } from 'styled-components/macro';

interface RowProps {
  dateRangeRow?: boolean;
}

export const Form = styled.form`
  margin-top: ${rem(24)};
  display: flex;
  flex-direction: column;
  gap: ${rem(16)};
`;

export const Row = styled.div<RowProps>`
  display: grid;
  ${({ dateRangeRow }) =>
    dateRangeRow
      ? css`
          grid-template-columns: 1fr 15px 1fr;
          gap: ${rem(6)};
        `
      : `grid-auto-columns: 1fr;
          gap: ${rem(16)};
      `};
  grid-auto-flow: column;
`;

export const SelectInput = styled(ControlledSelectInput)`
  width: ${rem(280)};
`;

export const MultiSelectHolder = styled.div`
  #react-select-5-listbox {
    z-index: 2;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: flex-end;
  height: 62px;

  svg {
    display: block;
    padding-bottom: ${rem(12)};
  }
`;
