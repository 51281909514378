export const timezones = [
  { id: 1, name: 'UTC' },
  { id: 2, name: 'America/New_York' },
  { id: 3, name: 'America/Chicago' },
  { id: 4, name: 'America/Denver' },
  { id: 5, name: 'America/Phoenix' },
  { id: 6, name: 'America/Los_Angeles' },
  { id: 7, name: 'America/Halifax' },
  { id: 8, name: 'America/Anchorage' },
  { id: 9, name: 'Pacific/Honolulu' },
  { id: 10, name: 'Canada/Saskatchewan' },
  { id: 11, name: 'America/Puerto_Rico' },
  { id: 12, name: 'Africa/Johannesburg' },
  { id: 13, name: 'America/Anchorage' },
  { id: 14, name: 'Australia/Sydney' },
  { id: 15, name: 'Australia/Brisbane' },
  { id: 16, name: 'Europe/Rome' },
  { id: 17, name: 'Pacific/Auckland' },
  { id: 18, name: 'Asia/Singapore' },
  { id: 19, name: 'Asia/Tokyo' },
];
