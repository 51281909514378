import { AxiosRequestConfig } from 'axios';
import { QueryKeys } from 'features/common/constants';
import { useToast } from 'features/common/contexts/ToastContext';
import { useURLQueryParams } from 'features/common/hooks/useURLQueryParams';
import { FETCH_RATES_BY_AGENCY_QUERY } from 'features/rates/hooks/queries';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  updateAgency,
  fetchAgencies,
  findThatHaveRateWithCompanyAndPosition,
  findThatHaveRateWithCompany,
  fetchWfmUnAllocatedCompaniesForAgencies,
} from 'services';

export const FETCH_AGENCIES_QUERY = 'fetch-agencies-query';

interface UseUpdateAgencyOptions {
  onSuccess: () => void;
}
export interface Agency {
  id: number;
  name: string;
  abbreviation: string;
}

export const fetchAgenciesRequest = async (
  page: number,
  limit: number,
  startsWith: string,
) => {
  const options: AxiosRequestConfig = {
    params: {
      page,
      limit,
      startsWith,
    },
  };
  const response = await fetchAgencies(options);

  if (response.status === 200) {
    return response;
  }

  throw new Error('Error while fetching companies');
};

export const useFetchAgencies = (
  page: number,
  limit: number,
  startsWith: string,
) =>
  useQuery(
    [FETCH_AGENCIES_QUERY, { page, limit, startsWith }],
    () => fetchAgenciesRequest(page, limit, startsWith),
    {
      refetchOnWindowFocus: false,
    },
  );

export const fetchAgenciesThatHaveRateWithCompanyAndPositionRequest = async (
  companyId: number,
  positionId: number,
) => {
  const response = await findThatHaveRateWithCompanyAndPosition(
    companyId,
    positionId,
  );

  if (response.status === 200) {
    return response;
  }

  throw new Error('Error while fetching companies');
};

export const useFetchAgenciesThatHaveRateWithCompanyAndPosition = (
  companyId: number,
  positionId: number | string,
) =>
  useQuery(
    [
      QueryKeys.agenciesWidthRatesWithCompanyAndPosition,
      { companyId, positionId },
    ],
    () =>
      fetchAgenciesThatHaveRateWithCompanyAndPositionRequest(
        companyId,
        +positionId,
      ),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(companyId && positionId !== 'not-selected'),
    },
  );

export const fetchAgenciesThatHaveRateWithCompanyRequest = async (
  companyId: number,
) => {
  const response = await findThatHaveRateWithCompany(companyId);

  if (response.status === 200) {
    return response;
  }

  throw new Error('Error while fetching companies');
};

export const useFetchAgenciesThatHaveRateWithCompany = (companyId: number) =>
  useQuery(
    [QueryKeys.agenciesWidthRatesWithCompany, { companyId }],
    () => fetchAgenciesThatHaveRateWithCompanyRequest(companyId),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(companyId),
    },
  );

export const useUpdateAgency = ({ onSuccess }: UseUpdateAgencyOptions) => {
  const { urlParams } = useURLQueryParams();
  const page = urlParams.get('page') ?? '1';
  const limit = urlParams.get('limit') ?? '10';
  const searchByName = urlParams.get('search') ?? '';
  const startDate = urlParams.get('startDate');
  const isArchived = urlParams.get('isArchived') === 'true';

  const { addToast } = useToast();
  const queryClient = useQueryClient();

  const mutation = useMutation(updateAgency, {
    onMutate: async () => {
      await queryClient.cancelQueries([
        FETCH_RATES_BY_AGENCY_QUERY,
        Number(limit),
        Number(page),
        searchByName,
        isArchived,
        startDate,
      ]);
    },
    onSuccess: () => {
      if (onSuccess) {
        onSuccess();
      }
      addToast({ message: 'Agency updated successfully!', theme: 'active' });
    },

    onSettled: () => {
      queryClient.invalidateQueries([
        FETCH_RATES_BY_AGENCY_QUERY,
        Number(limit),
        Number(page),
        searchByName,
        isArchived,
        startDate,
      ]);
    },
  });

  return mutation;
};

export const FETCH_WFM_UNALLOCATED_COMPANIES_FOR_AGENCIES =
  'FETCH_WFM_UNALLOCATED_COMPANIES_FOR_AGENCIES';

export const useFetchWfmUnallocatedCompaniesForAgencies = (
  include?: string | null,
) =>
  useQuery(
    [FETCH_WFM_UNALLOCATED_COMPANIES_FOR_AGENCIES],
    () => fetchWfmUnAllocatedCompaniesForAgencies(include),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );
