import React from 'react';
import { createRoot } from 'react-dom/client';
import { LDProvider } from 'launchdarkly-react-client-sdk';

import Router from './Router';
import reportWebVitals from './reportWebVitals';
import { Providers } from './features/common/components/Providers';

import './index.css';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <LDProvider clientSideID={process.env.REACT_APP_LD_CLIENTSIDE_ID!}>
    <React.StrictMode>
      <Providers>
        <Router />
      </Providers>
    </React.StrictMode>
    ,
  </LDProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
