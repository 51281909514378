import { useState, useCallback } from 'react';

import {
  Container,
  UserMenuContainer,
  Menu,
  MenuItem,
  Avatar,
  IconWrapper,
} from './styled';

const renderChevron = (open: boolean) => {
  if (open) {
    return (
      <IconWrapper>
        <svg viewBox="0 0 768 768" xmlns="http://www.w3.org/2000/svg">
          <path d="M720 576L384 192 48 576h672z" />
        </svg>
      </IconWrapper>
    );
  }

  return (
    <IconWrapper>
      <svg viewBox="0 0 768 768" xmlns="http://www.w3.org/2000/svg">
        <path d="M720 192L384 576 48 192h672z" />
      </svg>
    </IconWrapper>
  );
};

const renderOptionsMenu = (open: boolean) => {
  if (open) {
    return (
      <Menu>
        <MenuItem>Sample user item</MenuItem>
        <MenuItem>Another user item</MenuItem>
      </Menu>
    );
  }

  return null;
};

export const AppHeader = () => {
  const [menuClicked, setMenuClicked] = useState(false);

  const handleUserMenuClick = useCallback(() => {
    setMenuClicked(curr => !curr);
  }, []);

  return (
    <Container>
      <UserMenuContainer onClick={handleUserMenuClick}>
        <Avatar />
        {renderChevron(menuClicked)}
        {renderOptionsMenu(menuClicked)}
      </UserMenuContainer>
    </Container>
  );
};
