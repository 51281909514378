import { ReactComponent as CSVIcon } from 'assets/csv.svg';

import { ReceivableItem } from 'features/receivables/types';

import { formatInTimeZone } from 'date-fns-tz';
import { Edit } from '@nowsta/tempo-ds';
import * as Presenter from './Presenter';
import { dowloadReceivableCSV } from '../../../../services';
import { getFormattedDate } from '../../../rates/components/RatesListView/utils';
import { DEFAULT_TIMEZONE } from '../../../common/constants';

export const HEADER_COLUMNS = [
  {
    header: 'Receivable #',
    id: 'receivableNumber',
    accessorKey: 'receivableNumber',
  },
  {
    header: 'Company name',
    id: 'companyName',
    accessorKey: 'companyName',
  },
  {
    header: 'Date range',
    id: 'dateRange',
    accessorKey: 'dateRange',
  },
  {
    header: 'Term',
    id: 'term',
    accessorKey: 'term',
  },
  {
    header: 'Status',
    id: 'status',
    accessorKey: 'status',
  },
  {
    header: 'Receivable requester',
    id: 'receivableRequester',
    accessorKey: 'receivableRequester',
  },
  {
    header: 'Created at',
    id: 'createdAt',
    accessorKey: 'createdAt',
  },
  {
    header: 'Receivable file',
    id: 'receivableFile',
    accessorKey: 'receivableFile',
  },
  {
    header: '',
    id: 'edit',
    accessorKey: 'edit',
  },
];

interface Options {
  onEdit: (invoiceId: number) => void;
}

export const getReceivableData = (
  receivable: ReceivableItem,
  { onEdit }: Options,
) => ({
  receivableNumber: receivable.invoiceNumber,
  companyName: receivable.company.name,
  dateRange: `${getFormattedDate(
    new Date(receivable.startDate),
  )} - ${getFormattedDate(new Date(receivable.endDate))}`,
  term: receivable.term,
  status: receivable.status,
  receivableRequester: receivable.createdByName,
  createdAt: `${formatInTimeZone(
    new Date(receivable.createdAt),
    DEFAULT_TIMEZONE,
    'MMM d, yyyy h:mm a z',
  )}`,
  receivableFile: receivable.csvFileURL ? (
    <Presenter.CSVButton
      onClick={() => {
        dowloadReceivableCSV(receivable.id);
      }}
      type="button"
    >
      <CSVIcon />
    </Presenter.CSVButton>
  ) : null,
  edit: (
    <Presenter.CSVButton onClick={() => onEdit(receivable.id)} type="button">
      <Edit />
    </Presenter.CSVButton>
  ),
});
