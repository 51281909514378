import styled from 'styled-components/macro';

import { ViewTab, TextInput, rem } from '@nowsta/ux-common-components';

import { breakpoints } from 'features/common/constants';
import { H1 } from 'features/common/components/headings';

export const SearchInput = styled(TextInput)`
  margin-top: -0.4rem;
`;

export const TabButton = styled(ViewTab)`
  padding: 0.5rem 0 1.5rem 0;
`;

export const ViewTabHolder = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  box-shadow: inset 0 -0.0625rem 0 0 rgb(0 0 0 / 8%);
`;

export const ButtonsHolder = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: 23px 0;
  justify-content: space-between;

  @media (max-width: ${breakpoints.medium}) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const ActionsHolder = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const Header = styled(H1)`
  margin-top: 1.3rem;
  margin-bottom: 1.48rem;
`;

export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;

  margin: ${rem(38.4)} 0 0 0;
`;
