import styled from 'styled-components/macro';
import { rem } from '@nowsta/ux-common-components';

export const Container = styled.header`
  display: flex;
  justify-content: flex-end;
  padding: 9px 13px;
`;

export const Avatar = styled.div`
  display: block;
  border-radius: 50%;
  background-color: palevioletred;

  width: 2rem;
  height: 2rem;

  margin-right: ${rem(6.08)};
`;

export const Menu = styled.menu`
  position: absolute;
  top: 100%;
  right: 50%;

  min-width: 12rem;

  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;

  display: flex;
  flex-flow: column;

  list-style: none;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 0px 2px rgb(0 0 0 / 16%),
    0px 32px 64px 0px rgb(0 0 0 / 6%), 0px 16px 32px 0px rgb(0 0 0 / 6%),
    0px 8px 16px 0px rgb(0 0 0 / 6%);

  li:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  li:last-child {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
`;

export const MenuItem = styled.li`
  flex: 1;
  width: 100%;
  text-align: center;
  cursor: pointer;

  padding: 0.5rem 0;

  &:hover {
    background-color: gray;
  }
`;

export const IconWrapper = styled.div`
  width: 0.6rem;
  margin-bottom: 2px;
`;

// TODO: Convert to a tabbable element.
// Maybe a <select> or just a <button>.
export const UserMenuContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin-right: 1.6px;
`;
