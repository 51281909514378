import { useCallback } from 'react';

import { useFetchRatesByAgency } from 'features/rates/hooks/queries';
import { IdAndName } from 'features/rates/interfaces';
import { useURLQueryParams } from 'features/common/hooks/useURLQueryParams';
import { Pagination } from 'features/common/components/Pagination';
import { ApiPaginationMeta } from 'features/common/types';

import { RateByAgency } from 'services/rates';

import { ListItemSkeleton } from './skeleton';

import { Container, List, Item } from './styled';
import { RatesEmptyResult } from '../RatesEmptyResult';
import { RatesListViewType } from '../RatesListView/types';
import AgencyRatesTable from '../AgencyRatesTable';

export interface RatesListByAgencyProps {
  handleOpenDrawer: (
    companyId: number,
    name: string,
    abbreviation: string,
  ) => void;
  // eslint-disable-next-line react/require-default-props
  onEditRate: (companyData: IdAndName, agencyData: IdAndName) => void;
  handleNewAgency: () => void;
}

export const RatesListByAgency = ({
  handleNewAgency,
  handleOpenDrawer,
  onEditRate,
}: RatesListByAgencyProps) => {
  const { urlParams, setURLParams } = useURLQueryParams();
  const page = urlParams.get('page') ?? '1';
  const limit = urlParams.get('limit') ?? '10';
  const searchByName = urlParams.get('search');
  const startDate = urlParams.get('startDate');
  const isArchived = urlParams.get('isArchived') === 'true';

  const handlePageLimitChange = useCallback(
    (newPageLimit: number) => {
      setURLParams({ limit: String(newPageLimit) });
    },
    [setURLParams],
  );

  const handlePageNumberChange = useCallback(
    (newPageNumber: number) => {
      setURLParams({ page: String(newPageNumber) });
    },
    [setURLParams],
  );

  const handleEditRate = (agencyData: IdAndName, companyData: IdAndName) => {
    onEditRate(companyData, agencyData);
  };

  const { isLoading, data } = useFetchRatesByAgency(
    +limit,
    +page,
    searchByName,
    isArchived,
    startDate,
  );

  if (isLoading) {
    return (
      <List>
        <Item>
          <ListItemSkeleton />
        </Item>
      </List>
    );
  }

  if (!data || !data?.data.items || !data?.data.items.length) {
    return (
      <RatesEmptyResult
        handleOpenDrawer={handleNewAgency}
        ratesBy={RatesListViewType.byAgency}
      />
    );
  }

  const renderTablePagination = (tableMetadata?: ApiPaginationMeta) => {
    if (tableMetadata && tableMetadata.itemCount) {
      const { itemCount, currentPage, totalPages } = tableMetadata;

      return (
        <Pagination
          pageNumber={currentPage}
          totalPages={Number(totalPages)}
          limit={itemCount}
          onLimitChange={handlePageLimitChange}
          onPageNumberChange={handlePageNumberChange}
        />
      );
    }

    return undefined;
  };

  return (
    <Container>
      <List>
        {data?.data.items.map(
          ({ agencyId, agencyName, agencyAbbr, positions }: RateByAgency) => (
            <Item key={agencyId}>
              <AgencyRatesTable
                data={{ agencyId, agencyName, agencyAbbr, positions }}
                handleOpenDrawer={handleOpenDrawer}
                onSelectRate={handleEditRate}
              />
            </Item>
          ),
        )}
        {renderTablePagination(data.data.meta)}
      </List>
    </Container>
  );
};
