import { useEffect, useState } from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { MediumModal, Button, TextInput } from '@nowsta/ux-common-components';

import { EditAgencyDTO } from 'features/agencies/dto';
import {
  FETCH_AGENCIES_QUERY,
  useFetchWfmUnallocatedCompaniesForAgencies,
  useUpdateAgency,
} from 'features/agencies/hooks/queries';
import Drawer from 'features/common/components/Drawer';
import { DrawerTitle } from 'features/common/components/Drawer/styled';
import { ControlledTextInput } from 'features/common/components/ControlledTextInput';
import { updateLogo } from 'services/agencies';
import { useToast } from 'features/common/contexts/ToastContext';
import { Loader } from 'features/common/components/Loader';
import { SelectInput } from 'features/common/components/SelectInput';

import { AgencyListItem } from '../../types';
import {
  ChangeLogoCTA,
  FooterHolder,
  Form,
  LogoComponent,
  LogoHolder,
} from './styled';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  agency?: AgencyListItem;
}

export const EditAgencyDrawer = ({ onClose, isOpen, agency }: Props) => {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [showUploadLogoModal, setShowUploadLogoModal] = useState(false);
  const toggleShowUploadLogoModal = () =>
    setShowUploadLogoModal(prevState => !prevState);
  const { addToast } = useToast();
  const methods = useForm<EditAgencyDTO>();
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = methods;
  const queryClient = useQueryClient();

  const {
    data: companyList,
    isLoading,
    refetch,
    isFetching,
  } = useFetchWfmUnallocatedCompaniesForAgencies(agency?.companyId);

  useEffect(() => {
    if (agency?.companyId) {
      setValue('companyId', agency?.companyId);
    }
  }, [companyList, agency]);

  useEffect(() => {
    if (isOpen) refetch();
  }, [isOpen]);

  const { mutate } = useUpdateAgency({
    onSuccess: () => {
      onClose();
      reset();
      queryClient.invalidateQueries([FETCH_AGENCIES_QUERY]);
    },
  });

  useEffect(() => {
    const newAgency = {
      ...agency,
      foregroundColor: agency?.foregroundColor || '',
      backgroundColor: agency?.backgroundColor || '',
      notificationEmail: agency?.notificationEmail || '',
      companyId: agency?.companyId || '',
    };
    reset(newAgency);
  }, [agency]);

  const onSubmit: SubmitHandler<any> = data => {
    if (!data.companyId || data.companyId === 'null') {
      data.companyId = null;
    }
    const toMutate = { ...data };

    delete toMutate.logo;
    mutate(toMutate);
  };
  const dispatchForm = () => handleSubmit(onSubmit)();

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleLogoSelectChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files[0]) {
      const sizeInMega = (event.target.files[0].size / 1024 / 1024).toFixed(4); // MB
      if (Number(sizeInMega) > 6) {
        alert('File should cannot weight more than 5 mg');
        return false;
      }
      setSelectedFile(event.target.files[0]);
    }
    return true;
  };

  const handleSubmitLogo = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (!selectedFile || !agency) return false;
    const formData = new FormData();
    formData.append('selectedFile', selectedFile);
    try {
      await updateLogo(agency.id, formData);
      queryClient.invalidateQueries([FETCH_AGENCIES_QUERY]);
      addToast({
        message: 'Agency logo updated successfully!',
        theme: 'active',
      });
    } catch (error) {
      console.error(error);
      addToast({ message: 'Agency logo upload failed!', theme: 'critical' });
    }
    toggleShowUploadLogoModal();
    handleClose();
    return true;
  };

  return (
    <>
      <Drawer
        title="Edit Agency"
        onClose={handleClose}
        onBack={handleClose}
        onContinue={dispatchForm}
        continueText="Update Agency"
        isOpen={isOpen}
      >
        <DrawerTitle>Edit Agency Details</DrawerTitle>

        {isLoading || isFetching ? (
          <Loader />
        ) : (
          <>
            <LogoHolder>
              <LogoComponent>
                <ChangeLogoCTA onClick={toggleShowUploadLogoModal}>
                  change logo
                </ChangeLogoCTA>
              </LogoComponent>
            </LogoHolder>
            <FormProvider {...methods}>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <SelectInput
                  label="WFM Company"
                  fieldId="companyId"
                  list={companyList?.data}
                  error={Boolean(errors.companyId)}
                  required={false}
                />
                <ControlledTextInput
                  control={control}
                  label="Agency name"
                  name="name"
                  placeholder="e.g ‘Big Agency Inc’"
                />
                <ControlledTextInput
                  control={control}
                  spaceUI={{ mT: 16 }}
                  label="Agency abbreviation"
                  name="abbreviation"
                  placeholder="e.g ‘BAI’"
                />
                <ControlledTextInput
                  control={control}
                  spaceUI={{ mT: 16 }}
                  label="Foreground Color"
                  name="foregroundColor"
                  type="color"
                  placeholder=""
                  required={false}
                />
                <ControlledTextInput
                  control={control}
                  spaceUI={{ mT: 16 }}
                  label="Background Color"
                  name="backgroundColor"
                  type="color"
                  placeholder=""
                  required={false}
                />
                <ControlledTextInput
                  control={control}
                  spaceUI={{ mT: 16 }}
                  label="Notification email"
                  name="notificationEmail"
                  placeholder="name@domain.com"
                  required={false}
                />
              </Form>
            </FormProvider>
          </>
        )}
      </Drawer>
      {showUploadLogoModal && (
        <MediumModal
          visible
          title={<div>Upload Agency Logo</div>}
          hasBackdrop
          onClose={toggleShowUploadLogoModal}
          body={
            <form onSubmit={handleSubmitLogo}>
              <TextInput
                spaceUI={{ mT: 16 }}
                label="New Agency Logo"
                type="file"
                onChange={handleLogoSelectChange}
              />
              <FooterHolder>
                <Button type="button">Cancel</Button>
                <Button type="submit" actionType="primary">
                  Upload logo
                </Button>
              </FooterHolder>
            </form>
          }
        />
      )}
    </>
  );
};
