import { Button } from '@nowsta/tempo-ds';

type ListSelectionInfoBoxProps = {
  totalItems: number;
  isFetching: boolean;
  hasCurrentPageSelected: boolean;
  hasAllListSelected: boolean;
  totalSelected: number;
  onClearSelection: () => void;
  onSelectionChange: (allSelected: boolean) => void;
};

const ListSelectionInfoBox = ({
  totalItems,
  isFetching,
  hasCurrentPageSelected,
  totalSelected,
  onClearSelection,
  hasAllListSelected,
  onSelectionChange,
}: ListSelectionInfoBoxProps) => {
  const handleSelectAllPunchcards = () => onSelectionChange(true);
  const handleClearAllPunchcards = () => {
    onSelectionChange(false);
    onClearSelection();
  };

  if (isFetching || totalItems < 1) return null;

  if (hasAllListSelected) {
    return (
      <div>
        All <strong>{totalItems}</strong> punchcards in the list are selected.{' '}
        <Button uiSize="small" onClick={handleClearAllPunchcards}>
          Clear selection
        </Button>
      </div>
    );
  }

  if (hasCurrentPageSelected) {
    return (
      <div>
        All <strong>{totalSelected}</strong> punchcards on this page are
        selected.{' '}
        <Button uiSize="small" onClick={handleSelectAllPunchcards}>
          Select all {totalItems} punchcards on the list
        </Button>
      </div>
    );
  }

  return (
    <div>
      {totalSelected} selected punchcards out of {totalItems}
    </div>
  );
};

export default ListSelectionInfoBox;
