import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-flow: column;
`;

export const List = styled.ul`
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  isolation: isolate;
`;

export const Item = styled.li`
  margin: 24px 0;
`;
