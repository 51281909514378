import React, { useCallback, useState } from 'react';
import { useURLQueryParams } from 'features/common/hooks/useURLQueryParams';

import { DrawerStatus, RatesListViewType } from './types';
import { IdAndName } from '../../interfaces';
import { getFormattedDate } from './utils';

const drawerStatusInitialState = {
  position: false,
  agency: false,
  company: false,
  rateAfterCompany: false,
  editRateAfterCompany: false,
  rateAfterAgency: false,
};

const useRateListView = () => {
  const { urlParams, setURLParams } = useURLQueryParams();
  const isArchived = urlParams.get('isArchived') === 'true';

  const handleSetIsArchived = useCallback(
    (value: boolean) => {
      setURLParams({ isArchived: String(value) });
    },
    [setURLParams],
  );

  const date = urlParams.get('startDate')
    ? getFormattedDate(new Date(urlParams.get('startDate')!))
    : getFormattedDate(new Date());

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = new Date(e.target.value).toISOString();
    const [finalNewDate] = newDate.split('T');
    setURLParams({ startDate: finalNewDate });
  };

  const [createRateCompany, setCreateRateCompany] = useState<
    IdAndName | undefined
  >();

  const [createRateAgency, setCreateRateAgency] = useState<
    { id: number; name: string; abbreviation: string } | undefined
  >();

  const [editAgency, setEditAgency] = useState<
    { name: string; abbreviation: string } | undefined
  >();

  const [editRateCompany, setEditRateCompany] = useState<
    IdAndName | undefined
  >();

  const [editRateAgency, setEditRateAgency] = useState<IdAndName | null>(null);

  const [drawerStatus, setDrawerStatus] = useState<DrawerStatus>({
    ...drawerStatusInitialState,
  });
  const [nextStateAfterClose, setNextStateAfterClose] = useState<
    DrawerStatus[]
  >([]);

  const handleChangeRatesBy = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setURLParams({ ratesBy: e.currentTarget.value });
    },
    [setURLParams],
  );

  const pushAfterCloseState = (value: DrawerStatus) =>
    setNextStateAfterClose(prevState => [...prevState, value]);

  const handleCloseDrawers = useCallback(() => {
    if (nextStateAfterClose.length) {
      const newNextStateAfterClose = [...nextStateAfterClose];
      const newDrawerStatus = newNextStateAfterClose.shift();

      setNextStateAfterClose(newNextStateAfterClose);
      setDrawerStatus(newDrawerStatus as DrawerStatus);
    } else {
      setDrawerStatus({ ...drawerStatusInitialState });
    }
  }, [nextStateAfterClose]);

  const handleOpenDrawer = useCallback(
    (value: keyof DrawerStatus, nextStateAfterClosed?: keyof DrawerStatus) =>
      () => {
        if (nextStateAfterClosed)
          pushAfterCloseState({
            position: nextStateAfterClosed === 'position',
            agency: nextStateAfterClosed === 'agency',
            company: nextStateAfterClosed === 'company',
            rateAfterCompany: nextStateAfterClosed === 'rateAfterCompany',
            editRateAfterCompany:
              nextStateAfterClosed === 'editRateAfterCompany',
            rateAfterAgency: value === 'rateAfterAgency',
          });
        setDrawerStatus({
          position: value === 'position',
          agency: value === 'agency',
          company: value === 'company',
          rateAfterCompany: value === 'rateAfterCompany',
          editRateAfterCompany: value === 'editRateAfterCompany',
          rateAfterAgency: value === 'rateAfterAgency',
        });
      },
    [],
  );

  const handleOpenEditAgencyDrawer = useCallback(
    (name: string, abbreviation: string) => {
      setEditAgency({ name, abbreviation });
      handleOpenDrawer('rateAfterAgency')();
    },
    [handleOpenDrawer],
  );

  const handleOpenCreateCompanyRateDrawer = useCallback(
    (id: number, name: string) => {
      setCreateRateCompany({ id, name });
      handleOpenDrawer('rateAfterCompany')();
    },
    [handleOpenDrawer],
  );

  const handleOpenCreateAgencyRateDrawer = useCallback(
    (id: number, name: string, abbreviation: string) => {
      setCreateRateAgency({ id, name, abbreviation });
      handleOpenDrawer('rateAfterAgency')();
    },
    [handleOpenDrawer],
  );

  const handleOpenEditRateDrawer = useCallback(
    (companyData: IdAndName, agencyData: IdAndName | null) => {
      setEditRateCompany(companyData);
      setEditRateAgency(agencyData);
      handleOpenDrawer('editRateAfterCompany')();
    },
    [handleOpenDrawer],
  );

  return {
    handleDateChange,
    handleCloseDrawers,
    handleOpenDrawer,
    handleSetIsArchived,
    isArchived,
    drawerStatus,
    handleOpenCreateCompanyRateDrawer,
    handleOpenEditAgencyDrawer,
    editAgency,
    createRateCompany,
    handleOpenCreateAgencyRateDrawer,
    createRateAgency,
    handleChangeRatesBy,
    handleOpenEditRateDrawer,
    editRateAgency,
    editRateCompany,
    date,
    ratesBy:
      urlParams.get('ratesBy') === RatesListViewType.byAgency
        ? RatesListViewType.byAgency
        : RatesListViewType.byCompany,
  };
};

export default useRateListView;
