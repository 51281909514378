import { useCallback, useEffect } from 'react';
import {
  SelectInput,
  // TODO: We need to swap DatePicker from ux-common-components
  // to the one from tempo-ds, the current DatePicker has an issue
  // with its internal state,it sucks.
  DatePicker,
  Button,
  Plus,
  MagnifyingGlass,
} from '@nowsta/ux-common-components';
import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { createAgency, createCompany, createPosition } from 'services';

import { useToast } from 'features/common/contexts/ToastContext';
import Layout from 'features/common/components/Layout';

import { CreatePositionDTO } from 'features/positions/dto';
import CreatePositionDrawer from 'features/positions/components/CreatePositionDrawer';
import CreateAgencyDrawer from 'features/agencies/components/CreateAgencyDrawer';
import { CreateAgencyDTO } from 'features/agencies/dto';
import CreateCompanyDrawer from 'features/companies/components/CreateCompanyDrawer';
import { CreateCompanyDTO } from 'features/companies/dto';
import { FETCH_POSITIONS_QUERY } from 'features/positions/hooks/queries';

import { createRate, updateRate } from 'services/rates';
import { CreateRateSetDTO, EditRateSetDto } from 'features/rates/interfaces';
import {
  FETCH_RATES_BY_COMPANY_QUERY,
  useDataRatesByCompany,
  useDataRatesByAgency,
  FETCH_RATES_BY_AGENCY_QUERY,
} from 'features/rates/hooks/queries';

import { H2 } from 'features/common/components/headings';
import { useURLQueryParams } from 'features/common/hooks/useURLQueryParams';
import { ApiPaginationMeta } from 'features/common/types';

import { RatesListByCompany } from '../RatesListByCompany';
import { RatesListViewType } from './types';
import useHook from './hook';
import CreateCompanyRateDrawer from '../CreateCompanyRateDrawer';
import { RatesListByAgency } from '../RatesListByAgency';
import CreateAgencyRateDrawer from '../CreateAgencyRateDrawer';
import EditCompanyRatesDrawer from '../EditCompanyRatesDrawer';

import {
  Header,
  ViewTabHolder,
  ButtonsHolder,
  ActionsHolder,
  TabButton,
  SearchInput,
  TableHeader,
} from './styled';

import { getFormattedDate } from './utils';

export const RatesListView = () => {
  const queryClient = useQueryClient();

  const { urlParams, setURLParams } = useURLQueryParams();

  const [searchParams] = useSearchParams();
  const page = urlParams.get('page') ?? '1';
  const limit = urlParams.get('limit') ?? '10';
  const searchByName = urlParams.get('search') ?? '';
  const startDate = urlParams.get('startDate');
  const isArchived = urlParams.get('isArchived') === 'true';

  const {
    mutate: positionMutate,
    isSuccess: positionSuccess,
    isError: positionIsError,
    error: positionError,
    reset: positionReset,
  } = useMutation(createPosition);

  const {
    mutate: agencyMutate,
    isSuccess: agencySuccess,
    isError: agencyIsError,
    error: agencyError,
    reset: agencyReset,
  } = useMutation(createAgency, {
    onMutate: async () => {
      await queryClient.cancelQueries([
        FETCH_RATES_BY_AGENCY_QUERY,
        Number(limit),
        Number(page),
        searchByName,
        isArchived,
        startDate,
      ]);
    },
    onSettled: () => {
      queryClient.invalidateQueries([
        FETCH_RATES_BY_AGENCY_QUERY,
        Number(limit),
        Number(page),
        searchByName,
        isArchived,
        startDate,
      ]);
    },
  });

  const {
    mutate: companyMutate,
    isSuccess: companySuccess,
    isError: companyIsError,
    error: companyError,
    reset: companyReset,
  } = useMutation(createCompany);

  const {
    mutate: rateCreateMutate,
    isSuccess: rateCreateSuccess,
    isError: rateCreateIsError,
    error: rateCreateError,
    reset: rateCreateReset,
  } = useMutation(createRate);

  const {
    mutate: rateEditMutate,
    isSuccess: rateEditSuccess,
    isError: rateEditIsError,
    error: rateEditError,
  } = useMutation(updateRate);

  const {
    handleSetIsArchived,
    drawerStatus,
    handleCloseDrawers,
    handleOpenDrawer,
    createRateCompany,
    handleOpenCreateCompanyRateDrawer,
    createRateAgency,
    handleOpenCreateAgencyRateDrawer,
    handleChangeRatesBy,
    editRateAgency,
    editRateCompany,
    handleOpenEditRateDrawer,
    ratesBy,
    date,
    handleDateChange,
  } = useHook();

  const handleRefecthList = useCallback(() => {
    setTimeout(() => {
      queryClient.invalidateQueries([
        FETCH_RATES_BY_COMPANY_QUERY,
        +limit,
        +page,
      ]);
    }, 600);
  }, [queryClient, searchParams]);

  const { addToast } = useToast();

  const handlePositionCreate = (data: CreatePositionDTO) =>
    positionMutate(data);
  const handleAgencyCreate = (data: CreateAgencyDTO) => agencyMutate(data);
  const handleCompanyCreate = (data: CreateCompanyDTO) => companyMutate(data);
  const handleRateCreate = (data: CreateRateSetDTO) => rateCreateMutate(data);
  const handleRateEdit = (data: EditRateSetDto) => rateEditMutate(data);

  useEffect(() => {
    if (positionSuccess) {
      handleCloseDrawers();
      addToast({ message: 'Position added successfully!', theme: 'active' });
      positionReset();
      setTimeout(() => {
        queryClient.invalidateQueries([FETCH_POSITIONS_QUERY]);
      }, 600);
    }

    if (positionIsError) {
      const { response } = positionError as { response: AxiosResponse };
      addToast({ message: response.data.message, theme: 'critical' });
      positionReset();
    }
  }, [
    positionSuccess,
    positionError,
    addToast,
    positionIsError,
    positionReset,
    handleCloseDrawers,
    queryClient,
  ]);

  useEffect(() => {
    if (agencySuccess) {
      handleCloseDrawers();
      addToast({ message: 'Agency added successfully!', theme: 'active' });
      agencyReset();
      handleRefecthList();
      setTimeout(() => {
        queryClient.invalidateQueries(['agenciesWithNoRateForCompany']);
      }, 600);
    }

    if (agencyIsError) {
      const { response } = agencyError as { response: AxiosResponse };
      addToast({ message: response.data.message, theme: 'critical' });
      agencyReset();
    }
  }, [
    agencySuccess,
    agencyError,
    queryClient,
    addToast,
    agencyIsError,
    agencyReset,
    handleCloseDrawers,
    handleRefecthList,
  ]);

  useEffect(() => {
    if (companySuccess) {
      handleCloseDrawers();
      addToast({ message: 'company added successfully!', theme: 'active' });
      companyReset();
      handleRefecthList();

      setTimeout(() => {
        queryClient.invalidateQueries([
          FETCH_RATES_BY_COMPANY_QUERY,
          +limit,
          +page,
        ]);
      }, 600);
    }

    if (companyIsError) {
      const { response } = companyError as { response: AxiosResponse };
      addToast({ message: response.data.message, theme: 'critical' });
      companyReset();
    }
  }, [
    companySuccess,
    companyError,
    addToast,
    companyIsError,
    companyReset,
    handleCloseDrawers,
    queryClient,
    searchParams,
    handleRefecthList,
  ]);

  useEffect(() => {
    if (rateCreateSuccess) {
      handleCloseDrawers();
      addToast({ message: 'rates added successfully!', theme: 'active' });
      rateCreateReset();
      handleRefecthList();
    }

    if (rateCreateIsError) {
      const { response } = rateCreateError as { response: AxiosResponse };
      addToast({ message: response.data.message, theme: 'critical' });
      rateCreateReset();
    }
  }, [
    rateCreateSuccess,
    rateCreateError,
    addToast,
    rateCreateIsError,
    rateCreateReset,
    handleCloseDrawers,
    handleRefecthList,
  ]);

  useEffect(() => {
    if (rateEditSuccess) {
      addToast({ message: 'Rates updated successfully!', theme: 'active' });
      handleCloseDrawers();
      handleRefecthList();
    }

    if (rateEditIsError) {
      const { response } = rateEditError as { response: AxiosResponse };
      addToast({ message: response.data.message, theme: 'critical' });
    }
  }, [
    rateEditSuccess,
    rateEditError,
    addToast,
    rateEditIsError,
    handleCloseDrawers,
    handleRefecthList,
  ]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setURLParams({ search: event.target.value });
  };

  const { data: dataCompanyRates } = useDataRatesByCompany(
    page,
    limit,
    searchByName,
    isArchived,
    startDate,
  );

  const { data: dataAgencyRates } = useDataRatesByAgency(
    Number(limit),
    Number(page),
    searchByName,
    isArchived,
    startDate,
  );

  const handleIsArchive = (value: boolean) => () => {
    handleSetIsArchived(value);
  };

  const pluralLabel =
    RatesListViewType.byCompany === ratesBy ? 'Companies' : 'Agencies';

  const singularLabel =
    RatesListViewType.byCompany === ratesBy ? 'Company' : 'Agency';

  const tableDataPayload =
    RatesListViewType.byCompany === ratesBy
      ? dataCompanyRates
      : dataAgencyRates;

  const renderTableHeader = useCallback(
    (meta?: ApiPaginationMeta) => {
      const { totalItems = 0 } = meta || {};

      const statusLabel = !isArchived ? 'Active' : 'Archived';

      return (
        <TableHeader>
          <H2>{`${totalItems} ${statusLabel} ${
            totalItems === 1 ? singularLabel : pluralLabel
          }`}</H2>
          <SearchInput
            inputStyle="uiAlt"
            onChange={handleSearch}
            value={urlParams.get('search')}
            placeholder="Search"
            iconLeft={<MagnifyingGlass />}
          />
        </TableHeader>
      );
    },

    [singularLabel, pluralLabel, handleSearch, urlParams, tableDataPayload],
  );

  return (
    <Layout>
      <div>
        <Header>Labor Market Rate Sheet</Header>
        <ViewTabHolder>
          <TabButton onClick={handleIsArchive(false)} active={!isArchived}>
            <span>{`Active ${pluralLabel}`}</span>
          </TabButton>
          <TabButton onClick={handleIsArchive(true)} active={isArchived}>
            <span>{`Archived ${pluralLabel}`}</span>
          </TabButton>
        </ViewTabHolder>
        <ButtonsHolder>
          <ActionsHolder>
            <Button
              sizeUI={{ minW: 101 }}
              iconLeft={<Plus />}
              onClick={handleOpenDrawer('position')}
            >
              Position
            </Button>
            <Button
              sizeUI={{ minW: 100 }}
              iconLeft={<Plus />}
              onClick={handleOpenDrawer('agency')}
            >
              Agency
            </Button>
            <Button
              sizeUI={{ minW: 150 }}
              iconLeft={<Plus />}
              actionType="primary"
              onClick={handleOpenDrawer('company')}
            >
              New Company
            </Button>
          </ActionsHolder>
          <ActionsHolder>
            <SelectInput
              inputStyle="uiAlt"
              value={ratesBy}
              sizeUI={{ w: 160 }}
              onChange={handleChangeRatesBy}
            >
              <option value={RatesListViewType.byCompany}>
                {RatesListViewType.byCompany}
              </option>
              <option value={RatesListViewType.byAgency}>
                {RatesListViewType.byAgency}
              </option>
            </SelectInput>
            <DatePicker
              inputStyle="uiAlt"
              sizeUI={{ w: 162 }}
              onChange={handleDateChange}
              value={date}
              formatDate={getFormattedDate}
            />
          </ActionsHolder>
        </ButtonsHolder>
        <CreateCompanyRateDrawer
          companyData={createRateCompany}
          onClose={handleCloseDrawers}
          isOpen={drawerStatus.rateAfterCompany}
          onSuccess={handleRateCreate}
          onNewAgencyClick={handleOpenDrawer('agency', 'rateAfterCompany')}
          onNewPositionClick={handleOpenDrawer('position', 'rateAfterCompany')}
        />
        <EditCompanyRatesDrawer
          companyData={editRateCompany}
          agencyData={editRateAgency}
          onClose={handleCloseDrawers}
          isOpen={drawerStatus.editRateAfterCompany}
          onSuccess={handleRateEdit}
          onNewPositionClick={handleOpenDrawer(
            'position',
            'editRateAfterCompany',
          )}
        />
        <CreateAgencyRateDrawer
          agencyData={createRateAgency}
          onClose={handleCloseDrawers}
          isOpen={drawerStatus.rateAfterAgency}
          onSuccess={handleRateCreate}
          onNewCompanyClick={handleOpenDrawer('agency', 'rateAfterAgency')}
          onNewPositionClick={handleOpenDrawer('position', 'rateAfterAgency')}
        />
        <CreateAgencyDrawer
          onClose={handleCloseDrawers}
          onBack={handleCloseDrawers}
          isOpen={drawerStatus.agency}
          onSuccess={handleAgencyCreate}
        />
        <CreateCompanyDrawer
          onClose={handleCloseDrawers}
          onBack={handleCloseDrawers}
          isOpen={drawerStatus.company}
          onSuccess={handleCompanyCreate}
          onNewPositionClick={handleOpenDrawer('position', 'company')}
        />
        <CreatePositionDrawer
          onClose={handleCloseDrawers}
          onBack={handleCloseDrawers}
          isOpen={drawerStatus.position}
          onSuccess={handlePositionCreate}
        />

        {renderTableHeader(tableDataPayload?.data.meta)}
        {ratesBy === RatesListViewType.byCompany && (
          <RatesListByCompany
            handleNewCompany={handleOpenDrawer('company')}
            onEditRate={handleOpenEditRateDrawer}
            handleOpenDrawer={handleOpenCreateCompanyRateDrawer}
          />
        )}
        {ratesBy === RatesListViewType.byAgency && (
          <RatesListByAgency
            handleNewAgency={handleOpenDrawer('agency')}
            onEditRate={handleOpenEditRateDrawer}
            handleOpenDrawer={handleOpenCreateAgencyRateDrawer}
          />
        )}
      </div>
    </Layout>
  );
};

export default RatesListView;
