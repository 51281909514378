import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { LoginPageComponent } from '@nowsta/ux-common-components';
import { IAuthFormData } from '@nowsta/ux-common-components/dist/components/pages/Login/FormSteps/AuthForm';
import { useMutation } from 'react-query';
import { GLOBAL_COOKIE_NAME, routes } from 'features/common/constants';

import { requestOTP, requestSignin } from 'services';

export const Login = () => {
  const [otp, setOtp] = useState(false);

  const navigate = useNavigate();

  const { mutate: mutateOTP, isLoading: loadingOtp } = useMutation(requestOTP, {
    onSettled: () => {
      setOtp(true);
    },
  });

  const { mutate: mutateSignin, isLoading: loadingSignin } = useMutation<
    any,
    any,
    any,
    any
  >(requestSignin, {
    onSettled: response => {
      Cookies.set(GLOBAL_COOKIE_NAME, {
        token: response?.accessToken,
      });
      setTimeout(() => {
        navigate(routes.rates);
      }, 600);
    },
  });

  const handleOtpSubmit = (login: string) => mutateOTP({ login });

  const handleAuthSubmit = ({ login, password, remember }: IAuthFormData) =>
    mutateSignin({ login, code: password, remember: Boolean(remember) });

  return (
    <>
      <LoginPageComponent
        sentOtp={otp}
        authLoading={loadingSignin}
        otpLoading={loadingOtp}
        onRequestOTPSubmit={handleOtpSubmit}
        onAuthSubmit={handleAuthSubmit}
      />
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName={GLOBAL_COOKIE_NAME}
        style={{ background: '#2B373B' }}
        buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
        expires={150}
      >
        Nowsta uses cookies to enhance the user experience.
      </CookieConsent>
    </>
  );
};

export default Login;
