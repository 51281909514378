import { useState } from 'react';
import { ViewTab } from '@nowsta/tempo-ds';

import Layout from 'features/common/components/Layout';
import { PunchcardsListView } from 'features/punchcards/components/PunchcardsListView';
import { ReceivablesListView } from 'features/receivables/components/ReceivablesListView';
import { useFlags } from 'launchdarkly-react-client-sdk';

import * as Presenter from './Presenter';

import PunchcardInvoicesListView from './components/PunchcardInvoicesListView';

type TabOptions = 'invoices' | 'punchcards' | 'receivables';

const Invoice = () => {
  const [activeTab, setActiveTab] = useState<TabOptions>('invoices');

  const handleTabChange = (tab: TabOptions) => () => setActiveTab(tab);
  const { enableReceivables } = useFlags();

  return (
    <Layout>
      <Presenter.Tabs role="tablist">
        <ViewTab
          id="invoices-tab"
          onClick={handleTabChange('invoices')}
          isActive={activeTab === 'invoices'}
        >
          Invoices List
        </ViewTab>
        {enableReceivables && (
          <ViewTab
            id="receivables-tab"
            onClick={handleTabChange('receivables')}
            isActive={activeTab === 'receivables'}
          >
            Receivables List
          </ViewTab>
        )}
        <ViewTab
          id="punchcards-tab"
          onClick={handleTabChange('punchcards')}
          isActive={activeTab === 'punchcards'}
        >
          Punchcards List
        </ViewTab>
      </Presenter.Tabs>
      <section>
        {activeTab === 'invoices' && (
          <PunchcardInvoicesListView
            role="tabpanel"
            aria-labelledby="invoices-tab"
          >
            Invoices List
          </PunchcardInvoicesListView>
        )}
        {activeTab === 'punchcards' && (
          <PunchcardsListView
            role="tabpanel"
            aria-labelledby="punchcards-tab"
          />
        )}
        {activeTab === 'receivables' && (
          <ReceivablesListView
            role="tabpanel"
            aria-labelledby="receivabeles-tab"
          />
        )}
      </section>
    </Layout>
  );
};

export default Invoice;
