import styled from 'styled-components/macro';
import {
  rem,
  TextInput as TempoInput,
  textWeight,
} from '@nowsta/ux-common-components';
import { color } from 'features/common/constants';

interface FlexTableHeaderProps {
  agenciesHeader?: boolean;
}
export const Text = styled.p`
  margin: 0;
  font-family: 'Silka';
  font-style: normal;
  font-weight: ${textWeight.regular};
  font-size: 14px;
  line-height: 22px;
  color: ${color.slateDark};
`;

export const StrongText = styled.span`
  font-weight: ${textWeight.semiBold};
`;

export const FieldRow = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 16px;
`;

export const TextInput = styled(TempoInput)`
  width: 100%;
`;

export const Separator = styled.div`
  height: 48px;
`;

export const FlexTableHeader = styled.div<FlexTableHeaderProps>`
  background: ${color.slateDarker};
  border-radius: 5px;
  height: 48px;
  font-family: 'Silka';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${({ agenciesHeader }) => (agenciesHeader ? rem(48) : rem(16))} 0
    ${rem(16)};
  margin: ${rem(24)} 0 ${rem(8)} 0;
`;

export const ActionsRowHolder = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${rem(24)};
`;

export const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const ListItem = styled.li`
  height: 48px;
  border-bottom: 1px solid #e1e6e9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${rem(10)};
  cursor: pointer;

  &:hover {
    background: #e1e6e9;
  }

  .agencyName {
    font-family: 'Silka';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }

  .chevronHolder {
    display: flex;
    font-size: 12px;

    svg {
      margin-left: 22px;
    }
  }
`;
