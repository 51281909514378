import { AxiosRequestConfig } from 'axios';

import { CreatePositionDTO, PositionDTO } from 'features/positions/dto';
import { Pagination } from 'features/common/types';

import { endpoints } from './endpoints';
import { client } from './api';

interface FetchPositionsDataResponse {
  positions: string[];
  department_positions: string[];
}

export const createPosition = async (data: CreatePositionDTO) =>
  client.post(endpoints.positions, data);

export const updatePosition = async (data: PositionDTO) =>
  client.patch(`${endpoints.positions}/${data.id}`, { name: data.name });

export const fetchPositions = async (params: Pagination) => {
  const response = await client.get(endpoints.positions, { params });

  return response.data;
};

export const fetchWFMPositions = async (options?: AxiosRequestConfig) => {
  const response = await client.get<FetchPositionsDataResponse>(
    endpoints.positionsWithDepartments,
    options,
  );

  return response?.data;
};
