import { FC, useMemo } from 'react';
import {
  ChevronLeft,
  ChevronRight,
  Button,
} from '@nowsta/ux-common-components';

import { isValidDate } from 'features/common/utils';

import { MONTHS } from './constants';
import {
  MonthButton,
  DatePickerPopupWrapper,
  PickerBody,
  PickerHeader,
  Year,
} from './styles';

interface DatePickerPopupProps {
  value?: string;
  onChange?: (date: string) => void;
}

export const DatePickerPopup: FC<DatePickerPopupProps> = ({
  value = '',
  onChange,
}) => {
  const formattedDate = isValidDate(value) ? new Date(value) : null;
  const currentDate = new Date();

  const currentMonth = useMemo(
    () => formattedDate && MONTHS[formattedDate.getMonth()],
    [value],
  );

  const year = useMemo(
    () =>
      formattedDate ? formattedDate.getFullYear() : currentDate.getFullYear(),
    [value],
  );

  // TODO: figure out why tempo doesn't like MouseEvent<HTMLButtonElement>
  const handleMonthClick = (event: any) => {
    const newDate = `${event.currentTarget.value} ${year}`;
    if (onChange) onChange(newDate);
  };

  const handleFowardClick = () => {
    const newDate = `${currentMonth} ${year + 1}`;
    if (onChange) onChange(newDate);
  };

  const handleBackwardClick = () => {
    const newDate = `${currentMonth} ${year - 1}`;
    if (onChange) onChange(newDate);
  };

  return (
    <DatePickerPopupWrapper>
      <PickerHeader>
        <Button
          onClick={handleBackwardClick}
          uiPresets={{ uiSize: 'small' }}
          actionType="ui"
        >
          <ChevronLeft />
        </Button>
        <Year>{year}</Year>
        <Button
          onClick={handleFowardClick}
          uiPresets={{ uiSize: 'small' }}
          actionType="ui"
        >
          <ChevronRight />
        </Button>
      </PickerHeader>
      <PickerBody>
        {MONTHS.map(month => (
          <MonthButton
            key={month}
            value={month}
            onClick={handleMonthClick}
            actionType={currentMonth === month ? 'primary' : 'ui'}
            isActive={currentMonth === month}
          >
            {month}
          </MonthButton>
        ))}
      </PickerBody>
    </DatePickerPopupWrapper>
  );
};
