import { rem } from '@nowsta/ux-common-components';
import styled from 'styled-components';
import NoImage from 'assets/noImage.png';

export const Form = styled.form`
  margin-top: ${rem(24)};
`;

export const LogoHolder = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export const LogoComponent = styled.div`
  width: 280px;
  height: 180px;
  background-image: url(${NoImage});
`;

export const FooterHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  gap: 8px;
`;

export const ChangeLogoCTA = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: flex;
  opacity: 0;
  transition: 0.4s;

  &:hover {
    opacity: 1;
  }
`;
