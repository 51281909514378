import { Button } from '@nowsta/tempo-ds';

import * as Presenter from './Presenter';

interface DrawerFooterProps {
  confirmText?: string;
  cancelText?: string;
  onSubmit?: () => void;
  onCancel?: () => void;
  isLoading?: boolean;
}

const DrawerFooter = ({
  cancelText = 'Cancel',
  confirmText = 'Apply',
  onSubmit = () => {},
  onCancel = () => {},
  isLoading = false,
}: DrawerFooterProps) => (
  <Presenter.Wrapper>
    <Button palette="white" disabled={isLoading} onClick={onCancel}>
      {cancelText}
    </Button>
    <Button onClick={onSubmit} type="submit" isWorking={isLoading}>
      {confirmText}
    </Button>
  </Presenter.Wrapper>
);

export default DrawerFooter;
