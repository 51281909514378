import { color, rem, textSize, textWeight } from '@nowsta/ux-common-components';
import styled from 'styled-components';

export const ErrorLine = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: ${rem(10)} ${rem(12)};
  background: ${color.shadowLightest};
  border: ${rem(1)} solid #${color.slateLighter};
  border-left: ${rem(4)} solid ${color.red};
  border-radius: ${rem(5)};
  color: ${color.slateDarker};
  font-weight: ${textWeight.regular};
  ${textSize.f12};
  margin-bottom: ${rem(8)};
`;
