export enum PunchcardStatus {
  INVOICED = 'Invoiced',
  NEEDS_TO_BE_INVOICED = 'Needs to be Invoiced',
  CORRECTED_NEEDS_TO_BE_INVOICED = 'Corrected - Needs to be Invoiced',
  STALE = 'Stale',
  PAID_TO_AGENCY = 'Paid to Agency',
  PAID_BY_CLIENT = 'Paid by Client',
}

export type StatusKey = keyof typeof PunchcardStatus;

export interface SearchPunchcardsForm {
  companyId?: string;
  startDate?: string;
  page: number;
  limit: number;
  endDate?: string;
  event?: string;
  eventDepartment?: string;
  position?: string;
  positionDepartment?: string;
  status: Record<keyof typeof PunchcardStatus, boolean>;
}

export interface PunchcardItem {
  id: number;
  externalPunchcardId: string;
  clockInTime: string;
  clockOutTime: string;
  workerFirstName: string;
  workerLastName: string;
  positionName: string;
  jobName: string;
  regular_rate_cents: number;
  regular_rate_hours: number;
  regular_total_cents: number;
  overtime_rate_cents: number;
  overtime_rate_hours: number;
  overtime_total_cents: number;
  double_overtime_rate_cents: number;
  double_overtime_rate_hours: number;
  double_overtime_total_cents: number;
  tip_pay_total_cents: number;
  reimbursement_pay_total_cents: number;
  travel_pay_total_cents: number;
  grand_total_cents: number;
  client_bill_rate_cents: number;
  agency_pay_rate_cents: number;
  status: string;
  companyId: number;
  invoiceId: null;
  externalSource: string;
  productType: string;
  companyTimezone: string;
  invoiceItemTimezone: string;
  eventDepartmentName: string;
  positionDepartmentName: string;
  bonus_cents: number;
  drive_time_rate_cents: number;
  drive_time_minutes: number;
  minimum_hours: number;
}
