import { Option } from './styled';

interface SelectPlaceholderProps {
  disabled?: boolean;
  children: React.ReactNode | null;
}

const SelectPlaceholder = ({
  disabled = true,
  children = null,
  ...props
}: SelectPlaceholderProps) => (
  <Option value="" disabled={disabled} {...props}>
    {children}
  </Option>
);

export default SelectPlaceholder;
