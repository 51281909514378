import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { Drawer, DrawerProps } from '@nowsta/tempo-ds';
import { yupResolver } from '@hookform/resolvers/yup';

import { InvoiceStatus } from 'features/common/constants';
import { useToast } from 'features/common/contexts/ToastContext';
import { CreateInvoiceSchema } from 'features/punchcards/schemas';
import {
  PunchcardItem,
  PunchcardStatus,
  SearchPunchcardsForm,
} from 'features/punchcards/types';
import { CompanyItem } from 'features/common/types';
import { createPunchcardsInvoice } from 'services/punchcards';

import DrawerFooter from 'features/common/components/InvoiceDrawerFooter';
import EntityDrawerBody from 'features/common/components/InvoiceDrawerBody';

import { FormValues } from 'features/common/components/InvoiceDrawerBody/types';

interface CreateInvoiceDrawerProps extends Omit<DrawerProps, 'body' | 'title'> {
  company?: CompanyItem;
  selectedPunchcards: PunchcardItem[];
  startDate?: string;
  endDate?: string;
  onSuccess: () => void;
  hasAllListSelected: boolean;
  filters?: SearchPunchcardsForm;
}

const CreateInvoiceDrawer = ({
  company,
  onClose,
  onSuccess,
  startDate,
  endDate,
  selectedPunchcards,
  filters,
  hasAllListSelected,
  ...rest
}: CreateInvoiceDrawerProps) => {
  const { addToast } = useToast();
  const mutation = useMutation(createPunchcardsInvoice, {
    onError: () => {
      addToast({
        message: 'Something went wrong',
        theme: 'critical',
      });
      onClose();
    },
    onSuccess: () => {
      addToast({
        message: 'Invoice has been created successfully',
        theme: 'active',
      });
      onSuccess();
      onClose();
    },
  });
  const methods = useForm<FormValues>({
    defaultValues: {
      companyId: company?.companyId,
      companyName: company?.name,
      invoiceNumber: '',
      contractTerms: '',
      status: InvoiceStatus.DRAFT,
    },
    // @ts-ignore
    resolver: yupResolver(CreateInvoiceSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    methods.reset({
      companyId: company?.companyId,
      companyName: company?.name,
    });
  }, [company?.companyId, company?.name]);

  const handleCreateInvoice = (data: FormValues) => {
    if (hasAllListSelected) {
      const { status, ...restFilters } = filters!;

      const statusKeys = Object.keys(
        status,
      ) as (keyof typeof PunchcardStatus)[];
      const statusFilter = statusKeys.filter(key => status[key]);

      mutation.mutate({
        companyId: data.companyId,
        number: data.invoiceNumber,
        term: data.contractTerms,
        status: data.status,
        startDate: startDate!,
        endDate: endDate!,
        hasAllListSelected: true,
        statusFilter,
        ...restFilters,
      });
    } else {
      mutation.mutate({
        companyId: data.companyId,
        number: data.invoiceNumber,
        term: data.contractTerms,
        status: data.status,
        startDate: startDate!,
        endDate: endDate!,
        items: selectedPunchcards,
      });
    }
  };
  return (
    <FormProvider {...methods}>
      <Drawer
        {...rest}
        onClose={onClose}
        enableCloseOnOutsideClick
        title="Create a new Invoice"
        hasBackdrop={false}
        body={<EntityDrawerBody />}
        footer={
          <DrawerFooter
            onSubmit={methods.handleSubmit(handleCreateInvoice)}
            onCancel={onClose}
            confirmText="Create"
            isLoading={mutation.isLoading}
          />
        }
      />
    </FormProvider>
  );
};

export default CreateInvoiceDrawer;
