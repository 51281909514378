import styled from 'styled-components';
import { rem } from '@nowsta/tempo-ds';

import { H3 } from 'features/common/components/headings';

export const Header = styled(H3)`
  padding-bottom: ${rem(16)};
`;

export const Wrapper = styled.div`
  display: grid;
  gap: ${rem(16)};
`;
