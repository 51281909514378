import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ArrowRight, SelectInput } from '@nowsta/ux-common-components';
import Select from 'react-select';

import Drawer from 'features/common/components/Drawer';
import { AutoCompleteInput } from 'features/common/components/AutoCompleteInput';
// import { ControlledDatePicker } from 'features/common/components/ControlledDatePicker';
import { DrawerTitle } from 'features/common/components/Drawer/styled';
import { useDebounceState } from 'features/common/hooks/useDebounceState';

import { useFetchCompanies } from 'features/companies/hooks/queries';
import { useFetchAgenciesThatHaveRateWithCompany } from 'features/agencies/hooks/queries';
import { AgencyListItem } from 'features/agencies/types';
import { useFetchDeparmentsByCompany } from 'features/invoices-history/hooks/queries';

import { useCreatePayment } from '../../hooks/queries';
import { IconContainer, Form, Row, MultiSelectHolder } from './styled';

interface CreatePaymentReportDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

const allDepartmentsOption = { value: 'all', label: 'All Departments' };

export const CreatePaymentReportDrawer = ({
  isOpen,
  onClose,
}: CreatePaymentReportDrawerProps) => {
  const [companiesSearchValue, setCompaniesSearchValue] = useState('');
  const [selectedCompany, setSelectedCompany] = useState<string | null>(null);
  const [selectedDeparment, setSelectedDeparment] = useState<
    { value: number | string; label: string }[]
  >([allDepartmentsOption]);
  const debouncedCompanySearch: string = useDebounceState(
    companiesSearchValue,
    300,
  );

  const { control, handleSubmit, reset, getValues, watch, register } =
    useForm();

  const watchCompanyChange = watch('companyId');
  useEffect(() => {
    setSelectedCompany(getValues().companyId);
  }, [watchCompanyChange, getValues]);

  const handleClose = () => {
    onClose();
    reset();
    setCompaniesSearchValue('');
  };
  const handleSelectDeparment = (value: any) => {
    setSelectedDeparment(value);
  };
  const handleCompanyInputValueChange = (value: string) =>
    setCompaniesSearchValue(value);

  const { data: deparments } = useFetchDeparmentsByCompany(selectedCompany);
  const { data: companies, isLoading: companiesLoading } = useFetchCompanies(
    1,
    10,
    debouncedCompanySearch,
  );
  const { data: agencies } = useFetchAgenciesThatHaveRateWithCompany(
    watch('companyId'),
  );

  const { mutate } = useCreatePayment({
    onSuccess: () => {
      handleClose();
    },
  });

  const onSubmit: SubmitHandler<any> = data => {
    if (data.agencyId === 'not-selected') {
      alert('You must select an agency');
    }
    mutate({
      ...data,
      startDate: new Date(data.startDate).toISOString().split('T')[0],
      endDate: new Date(data.endDate).toISOString().split('T')[0],
      departments: JSON.stringify(selectedDeparment),
    });
  };

  const parseDeparmentOptions = () => {
    if (!deparments) return [allDepartmentsOption];
    const result = deparments?.objects.map(item => ({
      value: item.id,
      label: item.name,
    }));
    return [allDepartmentsOption, ...result];
  };

  return (
    <Drawer
      title="Create a New .CSV"
      onClose={handleClose}
      onBack={handleClose}
      onContinue={handleSubmit(onSubmit)}
      continueText="Generate .CSV"
      isOpen={isOpen}
    >
      <DrawerTitle>New .CSV Details</DrawerTitle>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <AutoCompleteInput
          name="companyId"
          control={control}
          label="Company name"
          inputValue={companiesSearchValue}
          onInputValueChange={handleCompanyInputValueChange}
          items={
            companies?.items?.map((company: any) => ({
              id: company.id,
              value: company.name,
            })) ?? []
          }
          loading={companiesLoading}
        />
        <SelectInput label="Select an Agency" {...register('agencyId')}>
          <option value="not-selected">Select one</option>
          {agencies?.data?.map((agency: AgencyListItem) => (
            <option key={agency.id} value={agency.id}>
              {agency.name}
            </option>
          ))}
        </SelectInput>
        <MultiSelectHolder>
          <Select
            options={parseDeparmentOptions()}
            onChange={handleSelectDeparment}
            isMulti
          />
        </MultiSelectHolder>
        <Row>
          <Row dateRangeRow>
            <input
              // name="startDate"
              type="date"
              {...register('startDate')}
            />
            {/* <ControlledDatePicker
              label="Start Date"
              placeholder="Start Date"
              includeIcon
              name="startDate"
              rules={{
                validate: {
                  IsEarlerThanEndDate: (value: any) =>
                    new Date(value) <
                      (getValues().endDate && new Date(getValues().endDate)) ||
                    'start date is greater than end date ',
                },
              }}
              control={control}
            /> */}
            <IconContainer>
              <ArrowRight />
            </IconContainer>
            <input
              // name="startDate"
              type="date"
              {...register('endDate')}
            />
            {/* <ControlledDatePicker
              label="End Date"
              placeholder="End Date"
              includeIcon
              name="endDate"
              rules={{
                validate: {
                  IsEarlerThanEndDate: (value: any) =>
                    new Date(value) >
                      (getValues().startDate &&
                        new Date(getValues().startDate)) ||
                    'end date is lower than start date ',
                },
              }}
              control={control}
            /> */}
          </Row>
        </Row>
      </Form>
    </Drawer>
  );
};

export default CreatePaymentReportDrawer;
