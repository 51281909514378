import { useQuery } from 'react-query';
import { useFormContext, Controller } from 'react-hook-form';
import { AutoCompleteInput, CheckboxInput } from '@nowsta/tempo-ds';

import { fetchWFMPositions, fetchEventsData } from 'services';
import {
  PunchcardStatus,
  SearchPunchcardsForm,
} from 'features/punchcards/types';
import { QueryKeys } from 'features/common/constants';

import * as Presenter from './Presenter';

const FiltersBody = () => {
  const { watch } = useFormContext<SearchPunchcardsForm>();

  const { companyId, startDate, endDate } = watch();

  const eventsQuery = useQuery(
    [
      QueryKeys.eventsData,
      {
        filters: {
          companyId,
          startDate,
          endDate,
        },
      },
    ],
    () =>
      fetchEventsData({
        params: { companyId, startDate, endDate },
      }),
  );

  const positionsQuery = useQuery(
    [
      QueryKeys.positionsData,
      {
        filters: {
          companyId,
        },
      },
    ],
    () => fetchWFMPositions({ params: { companyId } }),
  );

  return (
    <Presenter.Wrapper>
      <Controller
        name="event"
        render={({ field }) => (
          <AutoCompleteInput
            openOnFocus
            sizeUI={{ w: '100%' }}
            placeholder="Search for an event"
            label="Event"
            inputValue={field.value}
            {...field}
            isWorking={eventsQuery.isLoading}
            onInputValueChange={field.onChange}
            onSelected={selection => {
              field.onChange(selection?.id);
            }}
            items={
              eventsQuery.data?.events.map(event => ({
                displayValue: event,
                id: event,
              })) ?? []
            }
          />
        )}
      />
      <Controller
        name="eventDepartment"
        render={({ field }) => (
          <AutoCompleteInput
            openOnFocus
            onInputValueChange={field.onChange}
            onSelected={selection => {
              field.onChange(selection?.id);
            }}
            sizeUI={{ w: '100%' }}
            placeholder="Search for an event department"
            label="Event Department"
            inputValue={field.value}
            {...field}
            items={
              eventsQuery.data?.department_events.map(event => ({
                displayValue: event,
                id: event,
              })) ?? []
            }
            isWorking={eventsQuery.isLoading}
          />
        )}
      />
      <Controller
        name="position"
        render={({ field }) => (
          <AutoCompleteInput
            openOnFocus
            onInputValueChange={field.onChange}
            onSelected={selection => {
              field.onChange(selection?.id);
            }}
            sizeUI={{ w: '100%' }}
            placeholder="Search for a position"
            label="Position"
            isWorking={positionsQuery.isLoading}
            inputValue={field.value}
            {...field}
            items={
              positionsQuery.data?.positions.map(position => ({
                displayValue: position,
                id: position,
              })) ?? []
            }
          />
        )}
      />
      <Controller
        name="positionDepartment"
        render={({ field }) => (
          <AutoCompleteInput
            openOnFocus
            onInputValueChange={field.onChange}
            onSelected={selection => {
              field.onChange(selection?.id);
            }}
            sizeUI={{ w: '100%' }}
            placeholder="Search for a position department"
            label="Position Department"
            isWorking={positionsQuery.isLoading}
            inputValue={field.value}
            {...field}
            items={
              positionsQuery.data?.department_positions.map(position => ({
                displayValue: position,
                id: position,
              })) ?? []
            }
          />
        )}
      />
      <Presenter.StatusSection>
        <Presenter.StatusLabel>Status</Presenter.StatusLabel>
        {Object.keys(PunchcardStatus).map(status => (
          <Controller
            key={status}
            name={`status.${status}`}
            render={({ field }) => (
              <CheckboxInput type="checkbox" checked={field.value} {...field}>
                {PunchcardStatus[status as keyof typeof PunchcardStatus]}
              </CheckboxInput>
            )}
          />
        ))}
      </Presenter.StatusSection>
    </Presenter.Wrapper>
  );
};

export default FiltersBody;
