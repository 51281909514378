import styled from 'styled-components/macro';

import { H1 } from 'features/common/components/headings';
import { color, rem, textSize } from '@nowsta/tempo-ds';
import { textWeight } from '@nowsta/ux-common-components';

export const Header = styled(H1)`
  margin-top: ${rem(24)};
  margin-bottom: ${rem(24)};
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: ${rem(32)};
  gap: ${rem(8)};
  align-items: flex-start;
`;

export const RowText = styled.div`
  ${textSize.f12Readout}
  font-weight: ${textWeight.medium};
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableHeader = styled.thead`
  tr {
    th {
      ${textSize.f14Readout}
      background-color: ${color.slate160};
      color: ${color.white100};
      height: ${rem(48)};
      overflow: hidden;
      padding: 0 ${rem(24)};
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:nth-child(5),
      &:nth-child(7) {
        display: grid;
        place-content: center;
      }
    }
  }
`;

export const TableBody = styled.tbody`
  tr {
    :nth-of-type(even) {
      background-color: ${color.slate20};
    }
    td {
      font-size: ${rem(14)};
      line-height: ${rem(18)};
      text-align: center;

      &:first-child {
        font-size: ${rem(14)};
        line-height: ${rem(18)};

        &:first-child {
          padding-left: ${rem(24)};
        }
      }
    }
  }
`;

export const TableContainer = styled.div`
  background: #ffffff;
  box-shadow: ${rem(0)} ${rem(2)} ${rem(4)} -${rem(1)} rgba(0, 0, 0, 0.02),
    ${rem(0)} ${rem(4)} ${rem(8)} -${rem(2)} rgba(0, 0, 0, 0.02),
    ${rem(0)} ${rem(16)} ${rem(32)} -${rem(8)} rgba(0, 0, 0, 0.02);
  border-radius: ${rem(8)};
  width: 100%;
  overflow: auto;
`;

export const FormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StatusesWrapper = styled.div`
  display: flex;
  align-items: center;

  gap: ${rem(8)};
`;
export const Title = styled.span`
  ${textSize.f13Paragraph}
  font-weight: ${textWeight.medium};
`;

export const InfoWrapper = styled.div`
  ${textSize.f13Paragraph}
  font-weight: ${textWeight.medium};
  height: ${rem(50)};
  place-items: center;
  justify-content: start;
  display: flex;
  align-items: center;
`;
