import {
  rem,
  textSize,
  textWeight,
  TextInput as TempoTextField,
} from '@nowsta/ux-common-components';
import styled from 'styled-components/macro';

import { color } from 'features/common/constants';

export const AutoCompeteInputWrapper = styled.div`
  position: relative;
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  ${textSize.f12};
  color: ${color.slateDark};
  cursor: pointer;
  font-weight: ${textWeight.semiBold};
`;

export const OptionsList = styled.ul`
  background-color: white;
  border-radius: 5px;
  border: 1px solid ${color.slate};
  margin: 0;
  margin-top: ${rem(4)};
  padding: 0;
  position: absolute;
  width: 100%;
  z-index: 10;
`;

export const OptionsListItem = styled.li`
  ${textSize.f14}
  list-style: none;
  margin: 0;
  padding: ${rem(10)} ${rem(12)};
  z-index: 10;

  :first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  :last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

export const TextInput = styled(TempoTextField)`
  @-ms-keyframes spin {
    from {
      -ms-transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
    }
  }

  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }
    to {
      -moz-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  svg {
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
`;
