import { FC } from 'react';
import { Loading, TextInputProps } from '@nowsta/ux-common-components';
import Downshift from 'downshift';
import { Control, Controller } from 'react-hook-form';

import {
  AutoCompeteInputWrapper,
  Label,
  OptionsList,
  OptionsListItem,
  TextInput,
  Wrapper,
} from './styled';

interface RenderOptions {
  getItemProps: any;
  highlightedIndex: number | null;
  selectedItem: { value: string };
}

interface AutoCompleteInputProps extends TextInputProps {
  control?: Control<any, any>;
  label?: string;
  name: string;
  notFountText?: string;
  placeholder?: string;
  items: { value: string; id: string | number }[];
  loading: boolean;
  inputValue: string;
  onInputValueChange: (e: any) => void;
  onSelected?: (value: number | string) => void;
}

export const AutoCompleteInput: FC<AutoCompleteInputProps> = ({
  notFountText = 'No results found',
  label,
  placeholder = 'Search...',
  name,
  control,
  loading,
  items = [],
  inputValue = '',
  onSelected,
  onInputValueChange,
  inputStyle,
}) => {
  const renderOptions = ({
    getItemProps,
    highlightedIndex,
    selectedItem,
  }: RenderOptions) => {
    const options = items.filter(
      item =>
        !inputValue ||
        item.value.toLowerCase().includes(inputValue.toLowerCase()),
    );

    if (options.length > 0) {
      return options.map((item, index) => (
        <OptionsListItem
          {...getItemProps({
            key: item.id,
            index,
            item,
            style: {
              backgroundColor:
                highlightedIndex === index ? 'lightgray' : 'white',
              fontWeight: selectedItem === item ? 'bold' : 'normal',
            },
          })}
        >
          {item.value}
        </OptionsListItem>
      ));
    }

    return <OptionsListItem>{notFountText}</OptionsListItem>;
  };
  return control ? (
    <Controller
      control={control}
      name={name}
      rules={{ required: true }}
      render={({
        field: { onBlur, ref, onChange },
        // FIXME: use error
        // fieldState: { error },
      }) => (
        <Downshift
          itemToString={item => (item ? item.value : '')}
          // TODO: allow autocomplete to be uncontrolled
          onSelect={selectedItem => onChange(selectedItem.id)}
          inputValue={inputValue}
          onInputValueChange={onInputValueChange}
        >
          {({
            getInputProps,
            getLabelProps,
            getMenuProps,
            isOpen,
            getItemProps,
            highlightedIndex,
            selectedItem,
            getRootProps,
          }) => (
            <AutoCompeteInputWrapper>
              <Label {...getLabelProps()}>
                <Wrapper>
                  <div {...getRootProps()}>
                    {/* @ts-ignore */}
                    <TextInput
                      inputStyle={inputStyle}
                      iconRight={loading && <Loading />}
                      {...getInputProps({
                        isOpen,
                        placeholder,
                        label,
                        onBlur,
                        ref,
                      })}
                      ref={ref}
                    />
                  </div>
                </Wrapper>
              </Label>
              {isOpen && !loading && (
                <OptionsList {...getMenuProps()}>
                  {renderOptions({
                    getItemProps,
                    highlightedIndex,
                    selectedItem,
                  })}
                </OptionsList>
              )}
            </AutoCompeteInputWrapper>
          )}
        </Downshift>
      )}
    />
  ) : (
    <Downshift
      itemToString={item => (item ? item.value : '')}
      // TODO: allow autocomplete to be uncontrolled
      onSelect={selectedItem => onSelected && onSelected(selectedItem.id)}
      inputValue={inputValue}
      onInputValueChange={onInputValueChange}
    >
      {({
        getInputProps,
        getLabelProps,
        getMenuProps,
        isOpen,
        getItemProps,
        highlightedIndex,
        selectedItem,
        getRootProps,
      }) => (
        <AutoCompeteInputWrapper>
          <Label {...getLabelProps()}>
            <Wrapper>
              <div {...getRootProps()}>
                {/* @ts-ignore */}
                <TextInput
                  inputStyle={inputStyle}
                  iconRight={loading && <Loading />}
                  {...getInputProps({
                    isOpen,
                    placeholder,
                    label,
                  })}
                />
              </div>
            </Wrapper>
          </Label>
          {isOpen && !loading && (
            <OptionsList {...getMenuProps()}>
              {renderOptions({
                getItemProps,
                highlightedIndex,
                selectedItem,
              })}
            </OptionsList>
          )}
        </AutoCompeteInputWrapper>
      )}
    </Downshift>
  );
};
