import styled from 'styled-components/macro';
import { rem, textSize, textWeight } from '@nowsta/tempo-ds';
import { color } from 'features/common/constants';

export const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: ${rem(0)} ${rem(2)} ${rem(4)} -${rem(1)} rgba(0, 0, 0, 0.02),
    ${rem(0)} ${rem(4)} ${rem(8)} -${rem(2)} rgba(0, 0, 0, 0.02),
    ${rem(0)} ${rem(16)} ${rem(32)} -${rem(8)} rgba(0, 0, 0, 0.02);
  border-radius: ${rem(8)};
  display: flex;
  flex-direction: column;
  padding: ${rem(40)};
  align-items: center;
  margin-top: ${rem(24)};
`;

export const Title = styled.h4`
  ${textSize.f14Readout};
  color: ${color.slateDarker};
  font-weight: ${textWeight.semiBold};
  margin: ${rem(14)} 0 0;
`;

export const IconHolder = styled.div`
  width: ${rem(72)};
  height: ${rem(72)};
  position: relative;

  svg {
    width: ${rem(58)};
    height: ${rem(59)};
    position: relative;
  }
`;

export const GradientCircle = styled.div`
  position: absolute;
  background: linear-gradient(
    90deg,
    #269fbd 0%,
    #1d9aca 14.29%,
    #3494d3 28.57%,
    #568cd8 42.86%,
    #7981d6 57.14%,
    #9973cd 71.43%,
    #b663bb 85.71%,
    #cc52a3 100%
  );
  opacity: 0.12;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
`;
