import axios from 'axios';
import { GLOBAL_COOKIE_NAME } from 'features/common/constants';
import { Cookies } from 'react-cookie-consent';
import { refreshAccessToken } from './auth';

export const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 300000,
  headers: {
    'x-api-key': process.env.REACT_APP_API_KEY || '',
  },
});

client.interceptors.request.use(config => {
  const cookie = Cookies.get(GLOBAL_COOKIE_NAME);
  const parsedCookie =
    cookie && typeof cookie === 'string' ? JSON.parse(cookie) : undefined;

  if (config.headers) {
    config.headers.Authorization = (parsedCookie && parsedCookie.token) || '';
  }
  return config;
});

client.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest.iamretry
    ) {
      originalRequest.iamretry = true;
      const response = await refreshAccessToken();

      axios.defaults.headers.common.Authorization = response?.accessToken;

      Cookies.set(GLOBAL_COOKIE_NAME, {
        token: response?.accessToken,
      });

      client(originalRequest);
    } else if (
      error.response &&
      error.response.status === 401 &&
      originalRequest.iamretry
    ) {
      Cookies.remove(GLOBAL_COOKIE_NAME);
      alert('Session expired!'); // Login Component does not have any error messaging?
      window.location.href = '/';
    } else if (error.response && error.response.status === 403) {
      Cookies.remove(GLOBAL_COOKIE_NAME);
      alert('You do not have enough access rights');
      window.location.href = '/';
    }

    return Promise.reject(error);
  },
);
