import { useForm, SubmitHandler } from 'react-hook-form';

import Drawer from 'features/common/components/Drawer';
import { DrawerTitle } from 'features/common/components/Drawer/styled';
import { ControlledTextInput } from 'features/common/components/ControlledTextInput';
import { PositionDTO } from 'features/positions/dto';
import { useEffect } from 'react';
import { Form } from '../CreatePositionDrawer/styled';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  data?: PositionDTO;
  onSuccess: (data: PositionDTO) => void;
}

export const EditPositionDrawer = (props: Props) => {
  const { handleSubmit, reset, control, setValue, register } =
    useForm<PositionDTO>();
  const { onSuccess, onClose, isOpen, data } = props;

  const onSubmit: SubmitHandler<PositionDTO> = requestObj => {
    onSuccess(requestObj);
    reset({ name: '' });
  };

  useEffect(() => {
    if (data) {
      setValue('name', data.name);
      setValue('id', data.id);
    }
  }, [data]);

  const dispatchForm = () => handleSubmit(onSubmit)();

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <Drawer
      title="Update Position"
      onClose={handleClose}
      onBack={handleClose}
      onContinue={dispatchForm}
      continueText="Save"
      isOpen={isOpen}
    >
      <DrawerTitle>Edit Position Details</DrawerTitle>

      <Form onSubmit={handleSubmit(onSubmit)} id="create-position-drawer">
        <input type="hidden" {...register('id')} />
        <ControlledTextInput
          control={control}
          name="name"
          label="Position name"
          placeholder="e.g ‘Bartender’"
        />
      </Form>
    </Drawer>
  );
};

export default EditPositionDrawer;
