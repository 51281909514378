import { Drawer, DrawerProps } from '@nowsta/tempo-ds';

import DrawerFooter from 'features/common/components/InvoiceDrawerFooter';

import FiltersBody from './components/FiltersBody';

interface FilterDrawerProps extends Omit<DrawerProps, 'body' | 'title'> {}

const FiltersDrawer = (props: FilterDrawerProps) => (
  <Drawer
    {...props}
    hasBackdrop={false}
    body={<FiltersBody />}
    footer={<DrawerFooter onSubmit={props.onClose} onCancel={props.onClose} />}
    title="Punchcards Filters"
    enableCloseOnOutsideClick
  />
);

export default FiltersDrawer;
