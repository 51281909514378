import { breakpoints } from 'features/common/constants';
import styled from 'styled-components/macro';

export const Contanier = styled.div`
  display: flex;
`;

export const Right = styled.div`
  display: block;
  flex: 1;
  overflow: hidden;
`;

export const ChildrenHolder = styled.div`
  padding: 0 81px;

  @media (max-width: ${breakpoints.medium}) {
    padding: 0 20px;
  }
`;

export const NavWrapper = styled.aside`
  nav {
    position: sticky;
    top: 0;
  }
`;
