export const MAX_ITEMS_PER_PAGE = 500;

export const PUNCHCARD_HEADERS = [
  {
    header: '',
    id: 'checkbox',
    accessorKey: 'checkbox',
  },
  {
    header: 'Punchcard id',
    id: 'external_punchcard_id',
    accessorKey: 'external_punchcard_id',
  },
  {
    header: 'Source',
    id: 'external_source',
    accessorKey: 'external_source',
  },
  {
    header: 'Product',
    id: 'product',
    accessorKey: 'product',
  },
  {
    header: 'Status',
    id: 'status',
    accessorKey: 'status',
  },
  {
    header: 'Date',
    id: 'date',
    accessorKey: 'date',
  },
  {
    header: 'Worker name',
    id: 'name',
    accessorKey: 'name',
  },
  {
    header: 'Event name',
    id: 'event',
    accessorKey: 'event',
  },
  {
    header: 'Event department',
    id: 'event_department_name',
    accessorKey: 'event_department_name',
  },
  {
    header: 'Position',
    id: 'position',
    accessorKey: 'position',
  },
  {
    header: 'Position department',
    id: 'position_department_name',
    accessorKey: 'position_department_name',
  },
  { header: 'Grand total', id: 'grand_total', accessorKey: 'grand_total' },
  {
    header: 'Clock in',
    id: 'clock_in',
    accessorKey: 'clock_in',
  },
  {
    header: 'Clock out',
    id: 'clock_out',
    accessorKey: 'clock_out',
  },
  {
    header: 'Regular hours',
    id: 'regular_hours',
    accessorKey: 'regular_hours',
  },
  {
    header: 'Regular rate',
    id: 'regular_rate',
    accessorKey: 'regular_rate',
  },
  {
    header: 'Overtime rate',
    id: 'overtime_rate',
    accessorKey: 'overtime_rate',
  },
  {
    header: 'Regular total',
    id: 'regular_total',
    accessorKey: 'regular_total',
  },
  {
    header: 'Overtime hours',
    id: 'overtime_hours',
    accessorKey: 'regular_hours',
  },
  {
    header: 'Double overtime rate',
    id: 'double_overtime_rate',
    accessorKey: 'regular_rate',
  },
  {
    header: 'Double overtime hours',
    id: 'double_overtime_hours',
    accessorKey: 'double_overtime_hours',
  },
  {
    header: 'Double overtime total',
    id: 'double_overtime_total',
    accessorKey: 'double_overtime_total',
  },
  {
    header: 'Overtime total',
    id: 'overtime_total',
    accessorKey: 'regular_total',
  },
  {
    header: 'Tip pay total',
    id: 'tip_pay_total',
    accessorKey: 'tip_pay_total',
  },
  {
    header: 'Reimbursement pay total',
    id: 'reimbursement_pay_total',
    accessorKey: 'reimbursement_pay_total',
  },
  {
    header: 'Travel pay total',
    id: 'travel_pay_total',
    accessorKey: 'travel_pay_total',
  },
  {
    header: 'Bonus',
    id: 'bonus_cents',
    accessorKey: 'bonus_cents',
  },
  {
    header: 'Drive Time Rate',
    id: 'drive_time_rate_cents',
    accessorKey: 'drive_time_rate_cents',
  },
  {
    header: 'Drive Time Minutes',
    id: 'drive_time_minutes',
    accessorKey: 'drive_time_minutes',
  },
  {
    header: 'Minimum Hours',
    id: 'minimum_hours',
    accessorKey: 'minimum_hours',
  },
];
