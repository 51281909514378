import { useForm, SubmitHandler } from 'react-hook-form';

import Drawer from 'features/common/components/Drawer';
import { DrawerTitle } from 'features/common/components/Drawer/styled';
import { ControlledTextInput } from 'features/common/components/ControlledTextInput';
import { CreatePositionDTO } from 'features/positions/dto';
import { Form } from './styled';

interface Props {
  onClose: () => void;
  onBack: () => void;
  isOpen: boolean;
  onSuccess: (data: CreatePositionDTO) => void;
}

export const CreatePositionDrawer = (props: Props) => {
  const { handleSubmit, reset, control } = useForm<CreatePositionDTO>();

  const { onSuccess, onClose, isOpen, onBack } = props;

  const onSubmit: SubmitHandler<CreatePositionDTO> = data => {
    onSuccess(data);
    reset({ name: '' });
  };

  const dispatchForm = () => handleSubmit(onSubmit)();

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleBack = () => {
    reset();
    onBack();
  };

  return (
    <Drawer
      title="Create a New Position"
      onClose={handleClose}
      onBack={handleBack}
      onContinue={dispatchForm}
      continueText="Create Position"
      isOpen={isOpen}
    >
      <DrawerTitle>New Position Details</DrawerTitle>

      <Form onSubmit={handleSubmit(onSubmit)} id="create-position-drawer">
        <ControlledTextInput
          control={control}
          name="name"
          label="Position name"
          placeholder="e.g ‘Bartender’"
        />
      </Form>
    </Drawer>
  );
};

export default CreatePositionDrawer;
