import { TextInputProps } from '@nowsta/ux-common-components';
import { Control, Controller } from 'react-hook-form';
import { TextInput } from '../Styled';

interface Props extends TextInputProps {
  control: Control<any, any>;
  name: string;
  label: string;
  placeholder: string;
  required?: boolean;
}

export const ControlledTextInput = ({
  control,
  name,
  label,
  placeholder,
  required,
  ...props
}: Props) => (
  <Controller
    control={control}
    name={name}
    // @ts-ignore
    rules={{ required: required === false ? false : true }}
    render={({
      field: { onChange, onBlur, value = '', ref },
      fieldState: { error },
    }) => (
      <TextInput
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        label={label}
        isRequired={Boolean(error)}
        message={error ? 'This field is required ' : undefined}
        placeholder={placeholder}
        ref={ref}
        {...props}
      />
    )}
  />
);
