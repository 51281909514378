export enum RatesListViewType {
  byCompany = 'By Company',
  byAgency = 'By Agency',
}

export interface DrawerStatus {
  position: boolean;
  agency: boolean;
  company: boolean;
  rateAfterCompany: boolean;
  editRateAfterCompany: boolean;
  rateAfterAgency: boolean;
}
