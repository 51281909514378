import { rem } from '@nowsta/ux-common-components';
import { H1 } from 'features/common/components/headings';
import { breakpoints, color } from 'features/common/constants';
import styled from 'styled-components';

export const ActionsHolder = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const ButtonsHolder = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: 23px 0;
  justify-content: space-between;

  @media (max-width: ${breakpoints.medium}) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const Header = styled(H1)`
  margin-top: 1.3rem;
  margin-bottom: 1.48rem;
`;

export const SortingArea = styled.div`
  display: flex;
  gap: 8px;
  cursor: pointer;
  align-items: center;

  svg.icon {
    fill: ${color.white};
  }
`;

export const Row = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: ${rem(32)} 0 0 0;

  svg.icon {
    fill: ${color.slateDark};
  }
`;
