import { useCallback } from 'react';

import { useURLQueryParams } from 'features/common/hooks/useURLQueryParams';
import { useFetchRatesByCompany } from 'features/rates/hooks/queries';
import { ApiPaginationMeta } from 'features/common/types';
import { Pagination } from 'features/common/components/Pagination';

import { RateByCompany } from 'services/rates';

import { ListItemSkeleton } from './skeleton';

import { Container, List, Item } from './styled';
import { IdAndName } from '../../interfaces';
import { RatesEmptyResult } from '../RatesEmptyResult';
import { RatesListViewType } from '../RatesListView/types';
import CompanyRatesTable from '../CompanyRatesTable';

export interface RatesListByCompanyProps {
  handleOpenDrawer: (companyId: number, name: string) => void;
  onEditRate: (companyData: IdAndName, agencyData: IdAndName | null) => void;
  handleNewCompany: () => void;
}

export const RatesListByCompany = ({
  handleOpenDrawer,
  onEditRate,
  handleNewCompany,
}: RatesListByCompanyProps) => {
  const { urlParams, setURLParams } = useURLQueryParams();
  const page = urlParams.get('page') ?? '1';
  const limit = urlParams.get('limit') ?? '10';
  const searchByName = urlParams.get('search') || '';
  const startDate = urlParams.get('startDate');
  const isArchived = urlParams.get('isArchived') === 'true';

  const handlePageLimitChange = useCallback(
    (newLimit: number) => {
      setURLParams({ limit: String(newLimit) });
    },
    [setURLParams],
  );

  const handlePageNumberChange = useCallback(
    (newPage: number) => {
      setURLParams({ page: String(newPage) });
    },
    [setURLParams],
  );

  const { isLoading, data } = useFetchRatesByCompany(
    page,
    limit,
    searchByName,
    isArchived,
    startDate,
  );

  if (isLoading) {
    return (
      <List>
        <Item>
          <ListItemSkeleton />
        </Item>
      </List>
    );
  }

  const handleEditRate = (
    agencyData: { id: number; name: string } | null,
    companyData: { id: number; name: string },
  ) => {
    onEditRate(companyData, agencyData);
  };

  const renderTablePagination = (tableMetadata?: ApiPaginationMeta) => {
    if (tableMetadata && tableMetadata.itemCount) {
      const { itemCount, currentPage, totalPages } = tableMetadata;

      return (
        <Pagination
          pageNumber={currentPage}
          totalPages={Number(totalPages)}
          limit={itemCount}
          onLimitChange={handlePageLimitChange}
          onPageNumberChange={handlePageNumberChange}
        />
      );
    }

    return undefined;
  };

  return (
    <Container>
      <List>
        {data?.data.items.map((company: RateByCompany) => (
          <Item key={company.companyId}>
            <CompanyRatesTable
              data={company}
              handleOpenDrawer={handleOpenDrawer}
              onSelectRate={handleEditRate}
            />
          </Item>
        ))}
        {(!data?.data.items || !data?.data.items.length) && (
          <RatesEmptyResult
            handleOpenDrawer={handleNewCompany}
            ratesBy={RatesListViewType.byCompany}
          />
        )}
        {renderTablePagination(data?.data?.meta)}
      </List>
    </Container>
  );
};
