import { Skeleton } from '@nowsta/tempo-ds';
import * as Presenter from './Presenter';

const LoadingState = () => (
  <Presenter.SkeletonContainer>
    <Skeleton spaceUI={{ mB: 1 }} sizeUI={{ w: '100%', h: 48 }} />
    <Skeleton spaceUI={{ mB: 1 }} sizeUI={{ w: '100%', h: 42 }} />
    <Skeleton spaceUI={{ mB: 1 }} sizeUI={{ w: '100%', h: 42 }} />
    <Skeleton spaceUI={{ mB: 1 }} sizeUI={{ w: '100%', h: 42 }} />
    <Skeleton spaceUI={{ mB: 1 }} sizeUI={{ w: '100%', h: 42 }} />
    <Skeleton spaceUI={{ mB: 1 }} sizeUI={{ w: '100%', h: 42 }} />
    <Skeleton spaceUI={{ mB: 1 }} sizeUI={{ w: '100%', h: 42 }} />
    <Skeleton spaceUI={{ mB: 1 }} sizeUI={{ w: '100%', h: 42 }} />
    <Skeleton spaceUI={{ mB: 1 }} sizeUI={{ w: '100%', h: 42 }} />
    <Skeleton spaceUI={{ mB: 1 }} sizeUI={{ w: '100%', h: 40 }} />
  </Presenter.SkeletonContainer>
);

export default LoadingState;
