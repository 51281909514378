/* eslint-disable @typescript-eslint/return-await */
import { AxiosResponse } from 'axios';

import { ApiPaginationMeta } from 'features/common/types';
import {
  CreateRateSetDTO,
  EditRateSetDto,
  RatesByCompanyAndAgency,
} from 'features/rates/interfaces';
import { endpoints } from './endpoints';
import { client } from './api';

// Interfaces copied from API
// rate-data-post-processor.ts helper.
//
// Company
export interface RateByCompanyRate {
  agencyId: number | null;
  agencyName: string | null;
  agencyAbbr: string | null;
  apr: number | null;
  cbr: number | null;
}

// Company
export interface RateByCompanyPosition {
  positionId: number | null;
  positionName: string | null;
  rates: RateByCompanyRate[];
}

// Company
export interface RateByCompany {
  companyId: number;
  companyName: string;
  timeZone: string;
  customCompanyId: string;
  positions: RateByCompanyPosition[];
}

// Agency
export interface RateByAgencyRate {
  companyId: number | null;
  companyName: string | null;
  companyCompanyId?: string | null;
  apr: number | null;
  cbr: number | null;
}

// Agency
export interface RateByAgencyPosition {
  positionId: number | null;
  positionName: string | null;
  rates: RateByAgencyRate[];
}

// Agency
export interface RateByAgency {
  agencyId: number;
  agencyName: string;
  agencyAbbr?: string;
  positions: RateByAgencyPosition[];
}

export const createRate = async (data: CreateRateSetDTO) =>
  await client.post(endpoints.rates, data);

export const findRatesByCompany = async (
  limit: number = 10,
  page: number = 1,
  name?: string,
  isArchived?: boolean,
  startDate?: string,
): Promise<
  AxiosResponse<{ items: RateByCompany[]; meta: ApiPaginationMeta }>
> =>
  await client.get(endpoints.findRatesByCompany, {
    params: {
      limit,
      page,
      name,
      is_archived: isArchived,
      start_date: startDate,
    },
  });

export const findRatesByAgency = async (
  limit: number = 10,
  page: number = 1,
  name?: string,
  isArchived?: boolean,
  startDate?: string,
): Promise<AxiosResponse<{ items: RateByAgency[]; meta: ApiPaginationMeta }>> =>
  await client.get(endpoints.findRatesByAgency, {
    params: {
      limit,
      page,
      name,
      is_archived: isArchived,
      start_date: startDate,
    },
  });

export const findRatesByCompanyAndAgency = async (
  companyId?: number,
  agencyId?: number,
): Promise<AxiosResponse<RatesByCompanyAndAgency>> =>
  await client.get(
    agencyId
      ? `${endpoints.findRatesByCompanyAndAgency}/${companyId}/${agencyId}`
      : `${endpoints.findCompanyDefaultRates}/${companyId}`,
  );

export const updateRate = async (
  requestObj: EditRateSetDto,
): Promise<AxiosResponse<RatesByCompanyAndAgency>> =>
  await client.patch(endpoints.rates, requestObj);
