import axios from 'axios';

export const authClient = axios.create({
  baseURL: process.env.REACT_APP_AUTH_API_URL,
  timeout: 1000,
});

export const requestOTP = async (data: { login: string }) =>
  authClient.post('otp', data);

export const requestSignin = async (data: { login: string }) => {
  const response = await fetch(`${process.env.REACT_APP_AUTH_API_URL}signin`, {
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const parsedResponse = await response.json();
  return parsedResponse;
};

export const refreshAccessToken = async () => {
  const response = await fetch(`${process.env.REACT_APP_AUTH_API_URL}refresh`, {
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({}),
  });

  const parsedResponse = await response.json();
  return parsedResponse;
};
