import { QueryKeys } from 'features/common/constants';
import {
  useMutation,
  useQuery,
  useQueryClient,
  QueryFunctionContext,
} from 'react-query';
import {
  createInvoice,
  fetchInvoiceErrors,
  fetchInvoices,
} from 'services/invoices';
import { useToast } from 'features/common/contexts/ToastContext';
import { fetchDeparmentsByCompany } from 'services/departments';

export const callFetchInvoices = async (context: QueryFunctionContext) => {
  const { queryKey } = context;
  const limit = queryKey[1] as Number;
  const page = queryKey[2] as Number;
  const companyId = queryKey[3] as number | undefined;
  const monthYear = queryKey[4] as Date | null;
  const terms = queryKey[5] as string;
  const createdBy = queryKey[6] as string;

  const response = await fetchInvoices(
    monthYear,
    terms,
    createdBy,
    +limit,
    +page,
    companyId,
  );

  return response;
};

interface UseCreateInvoiceOptions {
  onSuccess?: () => void;
}

export const useFetchInvoices = (
  page: string,
  limit: string,
  monthYear: Date | null,
  terms: string | null,
  createdBy: string | null,
  companyId?: number,
) =>
  useQuery(
    [
      QueryKeys.invoicesHistory,
      +limit,
      +page,
      companyId,
      monthYear,
      terms,
      createdBy,
    ],
    callFetchInvoices,
    {
      refetchOnWindowFocus: false,
    },
  );

export const useCreateInvoice = ({ onSuccess }: UseCreateInvoiceOptions) => {
  const { addToast } = useToast();
  const queryClient = useQueryClient();

  const mutation = useMutation(createInvoice, {
    onMutate: async () => {
      await queryClient.cancelQueries([QueryKeys.invoicesHistory]);
    },
    onSuccess: () => {
      if (onSuccess) {
        onSuccess();
      }
      addToast({ message: 'Report created successfully!', theme: 'active' });
    },

    onSettled: () => {
      queryClient.invalidateQueries([QueryKeys.invoicesHistory]);
    },
  });

  return mutation;
};

export const callFetchInvoiceErrors = (invoiceId: number | null) =>
  fetchInvoiceErrors(invoiceId);

export const useFetchInvoiceErrors = (invoiceId: number | null) =>
  useQuery(
    [QueryKeys.invoiceErrors, invoiceId],
    () => callFetchInvoiceErrors(invoiceId),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(invoiceId),
    },
  );

export const callFetchDepartmentsById = (invoiceId: string | null) =>
  fetchDeparmentsByCompany(invoiceId);

export const useFetchDeparmentsByCompany = (companyId: string | null) =>
  useQuery(
    [QueryKeys.departments, companyId],
    () => callFetchDepartmentsById(companyId),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(companyId),
    },
  );
