import { ExclaimationMarkTriangleFilled } from '@nowsta/tempo-ds';
import * as Presenter from './Presenter';

type Props = {
  error: any;
};

const ErrorState = ({ error }: Props) => (
  <Presenter.Wrapper>
    <Presenter.IconHolder>
      <Presenter.GradientCircle />
      <ExclaimationMarkTriangleFilled />
    </Presenter.IconHolder>

    <Presenter.Title>{error?.message}</Presenter.Title>
    <Presenter.ErrorWrapper>
      {JSON.stringify(error, null, 2)}
    </Presenter.ErrorWrapper>
  </Presenter.Wrapper>
);

export default ErrorState;
