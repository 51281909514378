import { useCallback, useState, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CheckboxInput, SelectInput } from '@nowsta/ux-common-components';

import { AutoCompleteInput } from 'features/common/components/AutoCompleteInput';
import Drawer from 'features/common/components/Drawer';
import { DrawerTitle } from 'features/common/components/Drawer/styled';
import { useDebounceState } from 'features/common/hooks/useDebounceState';
import { useFetchCompanies } from 'features/companies/hooks/queries';
import { useFetchPositions } from 'features/positions/hooks/queries';
import { PositionDTO } from 'features/positions/dto';
import { useFetchAgenciesThatHaveRateWithCompanyAndPosition } from 'features/agencies/hooks/queries';
import { AgencyListItem } from 'features/agencies/types';
import { useSetupOrderAllocation } from 'features/orderAllocation/hooks/mutations';
import { useFindOrderAllocation } from 'features/orderAllocation/hooks/queries';

import { Form, Title, Text, ErrorBlock } from './styled';
import { AgencyAllocationRow } from '../AgencyAllocationRow';
import { OrderAllocationItem } from '../../types';

interface Props {
  isOpen: boolean;
  companyId?: number;
  companyName: string;
  positionId?: number;
  remainingSlotsTo: number | null;
  onClose: () => void;
}

export const SetupOrderAllocationDrawer = ({
  isOpen,
  onClose,
  companyId,
  companyName,
  positionId,
  remainingSlotsTo,
}: Props) => {
  const [orderAllocationItems, setOrderAllocationItems] = useState<
    OrderAllocationItem[]
  >([]);
  const [companiesSearchValue, setCompaniesSearchValue] = useState('');
  const [allRemainingSlotsStatus, setAllRemainingSlotsStatus] = useState(false);

  const toggleAllRemainigSlots = () =>
    setAllRemainingSlotsStatus(prevState => !prevState);

  useEffect(() => {
    setCompaniesSearchValue(companyName);
  }, [companyName]);
  const { control, handleSubmit, register, watch, setValue } = useForm();

  const debouncedCompanySearch: string = useDebounceState(
    companiesSearchValue,
    300,
  );

  const { mutate } = useSetupOrderAllocation();

  useEffect(() => {
    if (companyId) {
      setValue('companyId', companyId);
    }

    setValue('positionId', positionId || 'not-selected');

    setAllRemainingSlotsStatus(Boolean(remainingSlotsTo));
    setValue('allRemainingSlots', remainingSlotsTo || 'not-selected');
  }, [companyId, positionId, remainingSlotsTo]);

  const { data: orderAllocationData } = useFindOrderAllocation(
    watch('companyId'),
    watch('positionId') === 'not-selected' ? undefined : watch('positionId'),
  );

  useEffect(() => {
    if (orderAllocationData) {
      setOrderAllocationItems(orderAllocationData.allocation);
    }
  }, [orderAllocationData]);

  useEffect(() => {
    if (!isOpen) {
      setOrderAllocationItems([]);
    } else if (positionId && companyId) {
      setOrderAllocationItems(orderAllocationData?.allocation || []);
    }
  }, [isOpen, positionId, companyId]);

  const { data: companies, isLoading: companiesLoading } = useFetchCompanies(
    1,
    10,
    debouncedCompanySearch,
  );

  const { data: agencies } = useFetchAgenciesThatHaveRateWithCompanyAndPosition(
    watch('companyId'),
    watch('positionId'),
  );
  const { data: { items: dataPositions } = {} } = useFetchPositions({
    page: 1,
    limit: 0,
  });

  const handleCompanyInputValueChange = (value: string) =>
    setCompaniesSearchValue(value);

  const handleAddAgency = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = +e.target.value;
    let found = false;

    if (Number.isNaN(value)) return;

    for (let i = 0; i < orderAllocationItems.length; i++) {
      if (orderAllocationItems[i].agencyId === value) {
        found = true;
      }
    }
    if (found) return false;

    const selectedAgency = agencies?.data.find(
      (item: AgencyListItem) => item.id === value,
    );
    if (!selectedAgency) return false;

    const agencyToAdd: OrderAllocationItem = {
      nowsta_company_id: selectedAgency?.companyId,
      agencyId: selectedAgency?.id,
      agencyName: selectedAgency?.name,
      fulfillment_quantity: 1,
    };
    setOrderAllocationItems(prevState => [...prevState, agencyToAdd]);
    return true;
  };

  const handleRemoveAgency = (agencyId: number) => () => {
    setOrderAllocationItems(prevState =>
      prevState.filter(item => agencyId !== item.agencyId),
    );
  };
  const handleMoveAgencyUp = (index: number) => () => {
    if (index === 0) return false;
    setOrderAllocationItems(prevState => {
      const newState = [...prevState];
      const itemToMove = newState.splice(index, 1);

      newState.splice(index - 1, 0, itemToMove[0]);

      return newState;
    });
  };
  const handleMoveAgencyDown = useCallback(
    (index: number) => () => {
      if (index === orderAllocationItems.length - 1) return false;
      setOrderAllocationItems(prevState => {
        const newState = [...prevState];
        const itemToMove = newState.splice(index, 1);

        newState.splice(index + 1, 0, itemToMove[0]);

        return newState;
      });
    },
    [orderAllocationItems],
  );

  const handleQuantityChange = (agencyId: number) => (value: number) =>
    setOrderAllocationItems(prevState =>
      prevState.map(item =>
        item.agencyId === agencyId
          ? {
              ...item,
              fulfillment_quantity: value > 0 ? value : 1,
            }
          : item,
      ),
    );

  const handleClose = () => {
    onClose();
  };

  const onSubmit: SubmitHandler<any> = data => {
    if (data.position === 'not-selected') {
      alert('You must select a position');
      return false;
    }
    if (orderAllocationItems.length < 1) {
      alert('There are no agencies added to this order allocation rule');
      return false;
    }
    if (allRemainingSlotsStatus && data.allRemainingSlots === 'not-selected') {
      alert('You must an agency to fill all remaining slots');
      return false;
    }

    const allRemainingSlots = allRemainingSlotsStatus
      ? agencies?.data.find(
          (item: AgencyListItem) => item.companyId === data.allRemainingSlots,
        )
      : null;

    const request = {
      ...data,
      allRemainingSlots,
      allocation: orderAllocationItems,
    };

    mutate(request);
  };

  const thereAreAenciesToSelect = agencies?.data && agencies?.data.length > 0;
  return (
    <Drawer
      title="Setup Order Allocation Rule"
      onClose={handleClose}
      onBack={handleClose}
      onContinue={handleSubmit(onSubmit)}
      continueText="Save"
      isOpen={isOpen}
    >
      <DrawerTitle>Choose a Company and Position to setup</DrawerTitle>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <AutoCompleteInput
          name="companyId"
          control={control}
          label="Company name"
          inputValue={companiesSearchValue}
          onInputValueChange={handleCompanyInputValueChange}
          items={
            companies?.items?.map((company: any) => ({
              id: company.id,
              value: company.name,
            })) ?? []
          }
          loading={companiesLoading}
        />
        <SelectInput label="Position" {...register('positionId')}>
          <option value="not-selected">Select a Position</option>
          {dataPositions &&
            dataPositions.map((position: PositionDTO) => (
              <option key={position.id} value={position.id}>
                {position.name}
              </option>
            ))}
        </SelectInput>

        {agencies?.data && agencies?.data.length > 0 && (
          <>
            <DrawerTitle>
              Setup Allocation Rule for Company name and Position name
            </DrawerTitle>

            <SelectInput
              label="Select an Agency To Add"
              onChange={handleAddAgency}
            >
              <option value="not-selected">Select one</option>
              {agencies?.data?.map((agency: AgencyListItem) => (
                <option key={agency.id} value={agency.id}>
                  {agency.name}
                </option>
              ))}
            </SelectInput>
          </>
        )}

        {!thereAreAenciesToSelect && (
          <ErrorBlock>
            <Title>¯\_(ツ)_/¯</Title>
            <Text>
              It looks there are no rates set for any agency for this company
              and position!
            </Text>
          </ErrorBlock>
        )}
        {thereAreAenciesToSelect &&
          orderAllocationItems.map((agency, index) => (
            <AgencyAllocationRow
              key={agency.agencyId}
              position={index + 1}
              agencyName={agency.agencyName}
              quantity={agency.fulfillment_quantity}
              onQuantityChange={handleQuantityChange(agency.agencyId)}
              onUp={handleMoveAgencyUp(index)}
              onDown={handleMoveAgencyDown(index)}
              onDelete={handleRemoveAgency(agency.agencyId)}
            />
          ))}
        {thereAreAenciesToSelect && (
          <CheckboxInput
            uiPresets={{ uiSize: 'default' }}
            type="checkbox"
            checked={allRemainingSlotsStatus}
            onChange={toggleAllRemainigSlots}
          >
            Send all remaining slots to agency
          </CheckboxInput>
        )}

        {allRemainingSlotsStatus && (
          <SelectInput
            label="Select Agency to fill all remaining slots"
            {...register('allRemainingSlots')}
          >
            <option
              value="not-selected"
              selected={watch('allRemainingSlots') === 'not-selected'}
            >
              Select one
            </option>
            {agencies?.data?.map((agency: AgencyListItem) => (
              <option
                key={agency.id}
                value={agency.companyId as string}
                selected={
                  Number(watch('allRemainingSlots')) ===
                  Number(agency.companyId)
                }
              >
                {agency.name}
              </option>
            ))}
          </SelectInput>
        )}
      </Form>
    </Drawer>
  );
};
