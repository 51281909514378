import { Button, XMark, ArrowLeft } from '@nowsta/ux-common-components';

import {
  Holder,
  DrawerHeader,
  DrawerContent,
  DrawerFooter,
  Title,
} from './styled';

export interface Props {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  onBack: () => void;
  onContinue: () => void;
  continueText: string;
  children: React.ReactNode;
}

export const Drawer = ({
  isOpen = false,
  title,
  onClose,
  onBack,
  onContinue,
  continueText,
  children,
}: Props) => (
  <Holder isOpen={isOpen}>
    <DrawerHeader>
      <Button actionType="ui" onClick={onBack}>
        <ArrowLeft />
        <span className="sr-only">go back</span>
      </Button>
      <Title>{title}</Title>
      <Button actionType="ui" onClick={onClose}>
        <XMark />
        <span className="sr-only">close</span>
      </Button>
    </DrawerHeader>
    <DrawerContent>{children}</DrawerContent>
    <DrawerFooter>
      <Button onClick={onBack}>Go back</Button>
      {continueText && (
        <Button actionType="primary" onClick={onContinue}>
          {continueText}
        </Button>
      )}
    </DrawerFooter>
  </Holder>
);

export default Drawer;
