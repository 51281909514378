import { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Plus,
  MagnifyingGlass,
  TextInput,
} from '@nowsta/ux-common-components';
import { useMutation, useQueryClient } from 'react-query';
import { createAgency } from 'services';
import { AxiosResponse } from 'axios';
import {
  createTable,
  useTableInstance,
  getCoreRowModelSync,
} from '@tanstack/react-table';

import { H2 } from 'features/common/components/headings';
import Layout from 'features/common/components/Layout';
import { useToast } from 'features/common/contexts/ToastContext';
import {
  FETCH_AGENCIES_QUERY,
  useFetchAgencies,
} from 'features/agencies/hooks/queries';
import { useURLQueryParams } from 'features/common/hooks/useURLQueryParams';
import { Table } from 'features/invoices-history/components/InvoicesListView/styled';
import { Pagination } from 'features/common/components/Pagination';
import { ApiPaginationMeta } from 'features/common/types';
import NoImage from '../../../../assets/noImage.png';

import { AgencyListEmptyState } from '../AgencyListEmptyState';
import CreateAgencyDrawer from '../CreateAgencyDrawer';
import { CreateAgencyDTO } from '../../dto';
import { AgencyListItem } from '../../types';

import {
  ActionsHolder,
  ButtonsHolder,
  Header,
  Row,
  Image,
  ColorHolder,
  ColorBlock,
} from './styled';

import { EditAgencyDrawer } from '../EditFullAgencyDrawer';

const ColorComponent = ({ value }: { value: string }) => (
  <ColorHolder>
    <ColorBlock color={value} />
    <div>{value}</div>
  </ColorHolder>
);

interface DrawersState {
  create: boolean;
  edit: boolean;
}

export const AgencyListView = () => {
  const { urlParams, setURLParams } = useURLQueryParams();
  const page = urlParams.get('page') ?? '1';
  const limit = urlParams.get('limit') ?? '10';

  const queryClient = useQueryClient();

  const [isOpenDrawer, setIsOpenDrawer] = useState<DrawersState>({
    create: false,
    edit: false,
  });
  const [selectedAgency, setSelectedAgency] = useState<AgencyListItem>();
  const [searchValue, setSearchValue] = useState('');
  const { addToast } = useToast();

  const handleCloseDrawers = () =>
    setIsOpenDrawer({ create: false, edit: false });
  const handleOpenCreateDrawer = () =>
    setIsOpenDrawer({ create: true, edit: false });
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const { data: agencyList } = useFetchAgencies(
    Number(page),
    Number(limit),
    searchValue,
  );

  const handlePageLimitChange = useCallback(
    (newLimit: number) => {
      setURLParams({ limit: String(newLimit) });
    },
    [setURLParams],
  );

  const handlePageNumberChange = useCallback(
    (newPage: number) => {
      setURLParams({ page: String(newPage) });
    },
    [setURLParams],
  );

  const handleEditAgency = (agencyItem: AgencyListItem) => () => {
    setIsOpenDrawer({ create: false, edit: true });
    setSelectedAgency(agencyItem);
  };

  const { mutate } = useMutation(createAgency, {
    onSettled: () => {
      handleCloseDrawers();
      addToast({ message: 'Agency added successfully!', theme: 'active' });
    },
    onError: (error: AxiosResponse) => {
      addToast({ message: error.data.message, theme: 'critical' });
    },
  });
  const handleAgencyCreate = (data: CreateAgencyDTO) =>
    mutate(data, {
      onSuccess: () => queryClient.invalidateQueries([FETCH_AGENCIES_QUERY]),
    });

  const table = createTable<{ Row: { [value: string]: any } }>();

  const columns = useMemo(() => {
    const defaultColumns = [
      table.createDataColumn('id', {
        id: 'id',
        cell: info => info.value,
        header: () => 'Id',
      }),
      table.createDataColumn('companyId', {
        id: 'companyId',
        cell: info => info.value,
        header: () => 'WFM Company ID',
      }),
      table.createDataColumn('name', {
        id: 'name',
        cell: info => info.value,
        header: () => 'Name',
      }),
      table.createDataColumn('foregroundColor', {
        id: 'foregroundColor',
        cell: info => info.value,
        header: () => 'Foreground Color',
      }),
      table.createDataColumn('backgroundColor', {
        id: 'backgroundColor',
        cell: info => info.value,
        header: () => 'Background Color',
      }),
      table.createDataColumn('notificationEmail', {
        id: 'notifcationEmail',
        cell: info => info.value,
        header: () => 'Notification Email',
      }),
      table.createDataColumn('logoURL', {
        id: 'logoURL',
        cell: info => info.value,
        header: () => 'Logo',
      }),
      table.createDataColumn('actions', {
        id: 'actions',
        cell: info => info.value,
        header: () => ' ',
      }),
    ];
    return table.createColumns(defaultColumns);
  }, [agencyList]);

  const resolveAgencyLogo = (logoUrl: string) => {
    if (process.env.REACT_APP_API_URL?.indexOf('localhost')) {
      return logoUrl.replace('s3_bucket', 'localhost');
    }
    return logoUrl;
  };

  const tableData = useMemo(() => {
    const toReturn = agencyList?.data.items?.map(
      (agencyItem: AgencyListItem) => ({
        id: agencyItem.id,
        companyId: agencyItem.companyId,
        name: `${agencyItem.name} (${agencyItem.abbreviation})`,
        foregroundColor: agencyItem.foregroundColor && (
          <ColorComponent value={agencyItem.foregroundColor} />
        ),
        backgroundColor: agencyItem.backgroundColor && (
          <ColorComponent value={agencyItem.backgroundColor} />
        ),
        notificationEmail: agencyItem.notificationEmail,
        logoURL: (
          <Image
            src={
              agencyItem.logo ? resolveAgencyLogo(agencyItem.logo.url) : NoImage
            }
            alt="logo"
          />
        ),

        actions: <Button onClick={handleEditAgency(agencyItem)}>Edit</Button>,
      }),
    );
    return toReturn;
  }, [agencyList]);

  const instance = useTableInstance(table, {
    data: tableData || [],
    columns,
    getCoreRowModel: getCoreRowModelSync(),
  });

  const showTable = agencyList && agencyList?.data?.items.length > 0;

  const renderTablePagination = (tableMetadata?: ApiPaginationMeta) => {
    if (tableMetadata && tableMetadata.itemCount) {
      const { itemCount, currentPage, totalPages } = tableMetadata;

      return (
        <Pagination
          pageNumber={currentPage}
          totalPages={Number(totalPages)}
          limit={itemCount}
          onLimitChange={handlePageLimitChange}
          onPageNumberChange={handlePageNumberChange}
        />
      );
    }

    return undefined;
  };

  return (
    <Layout>
      <Header>Agencies</Header>
      <ButtonsHolder>
        <ActionsHolder>
          <Button
            sizeUI={{ minW: 150 }}
            iconLeft={<Plus />}
            actionType="primary"
            onClick={handleOpenCreateDrawer}
          >
            New Agency{' '}
          </Button>
        </ActionsHolder>
      </ButtonsHolder>

      <Row>
        <H2>
          {showTable
            ? `Showing ${agencyList?.data?.meta.itemCount} - ${agencyList.data?.meta.totalItems} Agencies`
            : 'No Agencies'}
        </H2>
        <TextInput
          inputStyle="uiAlt"
          placeholder="Search"
          iconLeft={<MagnifyingGlass />}
          value={searchValue}
          onChange={handleSearchChange}
        />
      </Row>

      {showTable ? (
        <Table {...instance.getTableProps()}>
          <thead>
            {instance.getHeaderGroups().map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(header => (
                  <th {...header.getHeaderProps()}>
                    {header.isPlaceholder ? null : header.renderHeader()}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...instance.getTableBodyProps()}>
            {instance.getRowModel().rows.map(row => (
              <tr {...row.getRowProps()}>
                {row.getVisibleCells().map(cell => (
                  <td role="presentation" {...cell.getCellProps()}>
                    {cell.renderCell()}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <AgencyListEmptyState handleOpenDrawer={handleOpenCreateDrawer} />
      )}

      {showTable && renderTablePagination(agencyList?.data?.meta)}

      <CreateAgencyDrawer
        onClose={handleCloseDrawers}
        onBack={handleCloseDrawers}
        isOpen={isOpenDrawer.create}
        onSuccess={handleAgencyCreate}
      />
      <EditAgencyDrawer
        agency={selectedAgency}
        onClose={handleCloseDrawers}
        isOpen={isOpenDrawer.edit}
      />
    </Layout>
  );
};
