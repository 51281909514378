import styled from 'styled-components';
import { rem, textSize, textWeight } from '@nowsta/tempo-ds';

export const Wrapper = styled.section`
  display: grid;
  gap: ${rem(16)};
`;

export const StatusSection = styled.section`
  display: grid;
  gap: ${rem(8)};

  label {
    ${textSize.f13Readout}
  }

  > div > div:first-child {
    padding: 0;
  }
`;

export const StatusLabel = styled.span`
  ${textSize.f13Readout}
  font-weight: ${textWeight.medium}
`;
