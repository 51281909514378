import { useMemo, useState } from 'react';
import {
  createTable,
  getCoreRowModelSync,
  useTableInstance,
} from '@tanstack/react-table';
import { RateByAgency } from 'services';
import {
  Button,
  DotsVertical,
  FloatingMenu,
  FloatingMenuItem,
  Plus,
} from '@nowsta/ux-common-components';

import { EditAgencyDrawer } from 'features/agencies/components/EditAgencyDrawer';
import { IdAndName } from 'features/rates/interfaces';
import { TableRow } from 'features/common/types';

import { Holder, Header } from './styled';

interface Props {
  data: RateByAgency;
  handleOpenDrawer: (
    companyId: number,
    name: string,
    abbreviation: string,
  ) => void;
  onSelectRate: (agencyData: IdAndName, companyData: IdAndName) => void;
}
const table = createTable<{ Row: TableRow }>();

export const AgencyRatesTable = ({
  data,
  handleOpenDrawer,
  onSelectRate,
}: Props) => {
  const [floatingMenuVisible, setFloatingMenuVisible] = useState(false);
  const [isEditAgencyDrawer, setIsEditAgencyDrawer] = useState(false);

  const handleEditAgencyDrawerVisibility = (toggle: boolean) => () =>
    setIsEditAgencyDrawer(toggle);

  const handleToggleMenu = () => {
    setFloatingMenuVisible(prevVisible => !prevVisible);
  };

  const handleMenuClose = () => setFloatingMenuVisible(false);

  const columns = useMemo(() => {
    const { positions } = data;
    const processedCompanyIds: number[] = [];

    const defaultColumns = [
      table.createGroup({
        header: 'Position',
        // eslint-disable-next-line react/prop-types
        footer: props => props.column.id,
        columns: [
          table.createDataColumn('position', {
            cell: info => info.value,
            // eslint-disable-next-line react/prop-types
            footer: props => props.column.id,
            header: 'Position',
          }),
        ],
      }),
    ];

    for (
      let positionIndex = 0;
      positions.length > positionIndex;
      ++positionIndex
    ) {
      const currentPosition = positions[positionIndex];

      const { rates } = currentPosition;
      for (let rateIndex = 0; rates.length > rateIndex; ++rateIndex) {
        const currentRate = rates[rateIndex];
        const { companyId, companyName } = currentRate;

        if (
          companyId &&
          companyName &&
          !processedCompanyIds.includes(companyId)
        ) {
          processedCompanyIds.push(companyId);

          const newHeader = table.createGroup({
            header: companyName,
            // eslint-disable-next-line react/prop-types
            footer: props => props.column.id,
            columns: [
              table.createDataColumn(`cbr${companyId}`, {
                cell: info => info.value,
                header: 'CBR',
              }),
              table.createDataColumn(`apr${companyId}`, {
                cell: info => info.value,
                header: 'APR',
              }),
            ],
          });

          defaultColumns.push(newHeader);
        }
      }
    }

    if (defaultColumns.length < 2) {
      const newGroup = table.createGroup({
        header: 'No Companies Found',
        // eslint-disable-next-line react/prop-types
        footer: props => props.column.id,
        columns: [
          table.createDataColumn(`cbrnull`, {
            cell: info => info.value,
            header: 'CBR',
          }),
          table.createDataColumn(`aprnull`, {
            cell: info => info.value,
            header: 'APR',
          }),
        ],
      });
      defaultColumns.push(newGroup);
    }

    return table.createColumns(defaultColumns);
  }, [data]);

  const tableData = useMemo(() => {
    const toReturn = data.positions.map(position => {
      const positionObj: Record<string, any> = {
        position: position.positionName,
      };

      position.rates.forEach(rate => {
        if (rate.companyId) {
          positionObj[`apr${rate.companyId}`] = rate.apr
            ? `$${rate.apr}`
            : '--';
          positionObj[`cbr${rate.companyId}`] = rate.cbr
            ? `$${rate.cbr}`
            : '--';
        }
      });
      return positionObj;
    });

    if (toReturn.length < 2) {
      toReturn[0].aprnull = '--';
      toReturn[0].cbrnull = '--';
    }

    return toReturn;
  }, [data]);

  const instance = useTableInstance(table, {
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModelSync(),
  });

  const handleAddNewRateButton =
    (companyId: number, name: string, abbreviation: string) => () => {
      setFloatingMenuVisible(false);
      handleOpenDrawer(companyId, name, abbreviation);
    };

  const getCompanyNameAfterId = (id: number) =>
    data.positions[0].rates.find(rate => rate.companyId === id)?.companyName;

  const removeAprOrCbr = (value: string) =>
    Number(value.replace('apr', '').replace('cbr', ''));
  const handleEditRate = (companyId: number) => () => {
    onSelectRate(
      { id: data.agencyId, name: data.agencyName },
      { id: companyId, name: getCompanyNameAfterId(companyId) || '' },
    );
  };

  return (
    <Holder>
      <Header>
        <h1>{data.agencyName}</h1>
        <div style={{ display: 'flex' }}>
          <Button
            uiPresets={{ uiSize: 'small' }}
            iconLeft={<Plus />}
            onClick={handleAddNewRateButton(
              Number(data.agencyId),
              data.agencyName,
              data.agencyAbbr || '',
            )}
            spaceUI={{ pR: 8 }}
          >
            Add Rate
          </Button>
          <div style={{ position: 'relative' }}>
            <Button
              uiPresets={{ uiSize: 'small' }}
              iconLeft={<DotsVertical />}
              onClick={handleToggleMenu}
            />
            <FloatingMenu
              onClose={handleMenuClose}
              visible={floatingMenuVisible}
            >
              <FloatingMenuItem
                label="Edit Agency"
                onClick={handleEditAgencyDrawerVisibility(true)}
                type="button"
              />
            </FloatingMenu>
          </div>
        </div>
      </Header>
      <table {...instance.getTableProps()}>
        <thead>
          {instance.getHeaderGroups().map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(header => (
                <th {...header.getHeaderProps()}>
                  {header.isPlaceholder ? null : header.renderHeader()}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...instance.getTableBodyProps()}>
          {instance.getRowModel().rows.map(row => (
            <tr {...row.getRowProps()}>
              {row.getVisibleCells().map(cell => (
                <td
                  role="presentation"
                  {...cell.getCellProps()}
                  onClick={handleEditRate(removeAprOrCbr(cell.column.id))}
                >
                  {cell.renderCell()}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <EditAgencyDrawer
        agency={{
          id: data?.agencyId!,
          name: data?.agencyName!,
          abbreviation: data?.agencyAbbr,
        }}
        onClose={handleEditAgencyDrawerVisibility(false)}
        isOpen={isEditAgencyDrawer}
      />
    </Holder>
  );
};

export default AgencyRatesTable;
