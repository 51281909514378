import { rem } from '@nowsta/ux-common-components';
import styled from 'styled-components/macro';

export const FieldRow = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 16px;
`;

export const Form = styled.form`
  margin-top: ${rem(24)};
`;
