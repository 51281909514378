import { QueryFunctionContext, useQuery } from 'react-query';

import { findRatesByCompany, findRatesByAgency } from 'services';

export const FETCH_RATES_BY_COMPANY_QUERY = 'fetch-rates-by-company-query';
export const FETCH_RATES_BY_AGENCY_QUERY = 'fetch-rates-by-agency-query';

export const fetchRatesByCompany = async (context: QueryFunctionContext) => {
  const { queryKey } = context;
  const limit = queryKey[1] as Number;
  const page = queryKey[2] as Number;
  const name = queryKey[3] as string;
  const isArchived = queryKey[4] as boolean;
  const startDate = queryKey[5] as string;

  const response = await findRatesByCompany(
    +limit,
    +page,
    name,
    isArchived,
    startDate,
  );

  if (response.status === 200) {
    return response;
  }

  throw new Error('Error while fetching Rates by Company.');
};

export const useFetchRatesByCompany = (
  page: string,
  limit: string,
  name: string,
  isArchived?: boolean,
  startDate?: string,
) =>
  useQuery(
    [FETCH_RATES_BY_COMPANY_QUERY, +limit, +page, name, isArchived, startDate],
    fetchRatesByCompany,
    {
      refetchOnWindowFocus: false,
    },
  );

export const useDataRatesByCompany = (
  page: string,
  limit: string,
  name?: string,
  isArchived?: boolean,
  startDate?: string,
) =>
  useQuery(
    [FETCH_RATES_BY_COMPANY_QUERY, +limit, +page, name, isArchived, startDate],
    fetchRatesByCompany,
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  );

export const fetchRatesByAgency = async (context: QueryFunctionContext) => {
  const { queryKey } = context;
  const limit = queryKey[1] as Number;
  const page = queryKey[2] as Number;
  const name = queryKey[3] as string;
  const isArchived = queryKey[4] as boolean;
  const startDate = queryKey[5] as string;

  const response = await findRatesByAgency(
    +limit,
    +page,
    name,
    isArchived,
    startDate,
  );

  if (response.status === 200) {
    return response;
  }

  throw new Error('Error while fetching Rates by Agency.');
};

export const useFetchRatesByAgency = (
  limit: number = 10,
  page: number = 1,
  name: string = '',
  isArchived?: boolean,
  startDate?: string,
) =>
  useQuery(
    [FETCH_RATES_BY_AGENCY_QUERY, limit, page, name, isArchived, startDate],
    fetchRatesByAgency,
    {
      refetchOnWindowFocus: false,
    },
  );

export const useDataRatesByAgency = (
  limit: number = 10,
  page: number = 1,
  name: string = '',
  isArchived?: boolean,
  startDate?: string,
) =>
  useQuery(
    [FETCH_RATES_BY_AGENCY_QUERY, limit, page, name, isArchived, startDate],
    fetchRatesByAgency,
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  );
