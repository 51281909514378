import * as Yup from 'yup';

const REQUIRED_STRING = Yup.string().required('This field is required.');

export const PunchcardFiltersSchema = Yup.object({
  companyId: REQUIRED_STRING,
  startDate: REQUIRED_STRING,
  endDate: REQUIRED_STRING,
});

export const CreateInvoiceSchema = Yup.object({
  companyId: REQUIRED_STRING,
  status: REQUIRED_STRING,
  contractTerms: REQUIRED_STRING,
  invoiceNumber: REQUIRED_STRING,
});
