import { Route, Routes, Navigate } from 'react-router-dom';

import { routes } from 'features/common/constants';
import NotFound from 'features/common/components/NotFound';
import InvoicesListView from 'features/invoices-history/components/InvoicesListView';
import RatesListView from 'features/rates/components/RatesListView';
import { Login } from 'features/auth/components/Login';

import { isAuthenticated } from 'features/auth/utils';
import { PositionsListView } from 'features/positions/components/PositionsListView';
import { PaymentsListView } from 'features/payments/components/PaymentsListView';
import { AgencyListView } from 'features/agencies/components/AgencyListView';
import { OrderAllocationListView } from 'features/orderAllocation/components/OrderAllocationListView';

import { useFlags } from 'launchdarkly-react-client-sdk';
import InvoicesPage from 'features/invoices';

interface ProtectedProps {
  children: JSX.Element;
}
const Protected = ({ children }: ProtectedProps) => {
  if (isAuthenticated()) {
    return children;
  }
  return <Navigate to={routes.login} />;
};

const Router = () => {
  const { useNewInvoice } = useFlags();
  return (
    <Routes>
      <Route
        path={routes.home}
        element={
          <Protected>
            <Navigate to={routes.rates} />
          </Protected>
        }
      />

      {process.env.REACT_APP_ORDER_ALLOCATION_FEATURE &&
        process.env.REACT_APP_ORDER_ALLOCATION_FEATURE === 'on' && (
          <Route
            path={routes.orderAllocation}
            element={
              <Protected>
                <OrderAllocationListView />
              </Protected>
            }
          />
        )}
      <Route
        path={routes.agencies}
        element={
          <Protected>
            <AgencyListView />
          </Protected>
        }
      />
      {process.env.REACT_APP_PAYABLES_FEATURE &&
        process.env.REACT_APP_PAYABLES_FEATURE === 'on' && (
          <Route
            path={routes.payments}
            element={
              <Protected>
                <PaymentsListView />
              </Protected>
            }
          />
        )}
      <Route
        path={routes.rates}
        element={
          <Protected>
            <RatesListView />
          </Protected>
        }
      />
      {useNewInvoice && (
        <Route
          path={routes.invoices}
          element={
            <Protected>
              <InvoicesPage />
            </Protected>
          }
        />
      )}
      <Route
        path={routes.invoicesHistory}
        element={
          <Protected>
            <InvoicesListView />
          </Protected>
        }
      />

      <Route
        path={routes.positions}
        element={
          <Protected>
            <PositionsListView />
          </Protected>
        }
      />

      <Route path="*" element={<NotFound />} />
      <Route path={routes.login} element={<Login />} />
    </Routes>
  );
};

export default Router;
