import { Cookies } from 'react-cookie-consent';
import { GLOBAL_COOKIE_NAME } from 'features/common/constants';

export const isAuthenticated = () => {
  const cookie = Cookies.get(GLOBAL_COOKIE_NAME);
  const parsedCookie =
    cookie && typeof cookie === 'string' ? JSON.parse(cookie) : undefined;
  if (parsedCookie && parsedCookie.token) {
    return true;
  }
  return false;
};
